import { Tr, Td, Text, Menu, MenuButton, MenuList, MenuItem, Button, Box, useBreakpointValue } from "@chakra-ui/react"
import Chip from "@components/ui/badge"
import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"
import { isProcessingState } from "@screens/dashboard/transactions/helper"
import { formatShortDate } from "@util/dateFormating"
import { memo, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FiMoreVertical } from "react-icons/fi"

interface TransactionRowProps {
    transaction: {
        date: string | Date
        description: string
        status?: string
        secAmount?: string
        mainCurrency?: string
        secCurrency?: string
        mainAmount: string
    }
    isReward?: boolean
    handleViewMore: () => void
    isLast: boolean
}

const TransactionRow: React.FC<TransactionRowProps> = ({ transaction, handleViewMore, isLast, isReward }) => {
    const { t } = useTranslation("common")
    const borderBottom = useMemo(() => {
        return isLast ? "none !important" : "1px solid"
    }, [isLast])

    const processing = isProcessingState(transaction?.status || "")

    const chipVariant = useMemo(() => {
        if (isReward) {
            return ENUM_CHIP_VARIANTS.PURPLE
        }

        if (processing) {
            return ENUM_CHIP_VARIANTS.YELLOW
        }

        return undefined
    }, [processing, isReward])

    const displayedStatus = useMemo(() => {
        if (processing) {
            return "Processing"
        }
        if (isReward) {
            return "Rewards"
        }
    }, [processing, isReward])

    const formattedDate = formatShortDate(new Date(transaction.date))

    const rowVariant = useBreakpointValue({
        base: "extra-small",
        sm: "small",
        md: "medium",
        lg: "large",
    })

    const renderAmountsCol = useCallback(
        () => (
            <>
                <Box display="inline-block" verticalAlign="middle">
                    {transaction.mainAmount && (
                        <Text textStyle={"ManropeSemiboldBodySmall"}>
                            {transaction.mainAmount} {transaction.mainCurrency}
                        </Text>
                    )}
                    {transaction.secAmount && (
                        <Text textStyle={"ManropeMediumXSmall"} color="grey.dark.60">
                            {transaction.secAmount} {transaction.secCurrency}
                        </Text>
                    )}
                </Box>
                <Box display="inline-block" verticalAlign="middle" ml={2}>
                    <Menu>
                        <MenuButton
                            as={Button}
                            variant="ghost"
                            iconSpacing={0}
                            rightIcon={<FiMoreVertical size={15} />}
                            p={11}
                        />
                        <MenuList padding={0} borderRadius={8}>
                            <MenuItem onClick={handleViewMore} borderRadius={8}>
                                {t("viewMore")}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            </>
        ),
        [
            transaction.mainAmount,
            transaction.mainCurrency,
            transaction.secAmount,
            transaction.secCurrency,
            handleViewMore,
        ]
    )

    const renderLargeRow = useCallback(
        () => (
            <Tr
                w="full"
                cursor="pointer"
                _hover={{
                    bg: "grey.dark.10",
                }}
            >
                <Td
                    py={{ base: "1rem" }}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                    display={{ base: "none", md: "table-cell", lg: "table-cell" }}
                >
                    <Text color="grey.dark.60" textStyle={"ManropeSemiboldXSmall"}>
                        {formattedDate}
                    </Text>
                </Td>
                <Td py={{ base: "1rem" }} borderBottom={borderBottom} borderColor={"grey.dark.20"}>
                    <Box>
                        <Box display="inline-block" w={{ base: "70%" }}>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{transaction.description}</Text>
                        </Box>
                        <Box display="inline-block">
                            {displayedStatus && chipVariant && (
                                <Box mt={2}>
                                    {displayedStatus && <Chip text={displayedStatus} variant={chipVariant} />}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Td>
                <Td
                    py={{ base: "1rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [borderBottom, chipVariant, renderAmountsCol, formattedDate, transaction.description, displayedStatus]
    )

    const renderMediumRow = useCallback(
        () => (
            <Tr
                w="full"
                cursor="pointer"
                _hover={{
                    bg: "grey.dark.10",
                }}
            >
                <Td
                    py={{ base: "1rem" }}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                    display={{ base: "none", md: "table-cell", lg: "table-cell" }}
                >
                    <Text color="grey.dark.60" textStyle={"ManropeSemiboldXSmall"}>
                        {formattedDate}
                    </Text>
                </Td>
                <Td py={{ base: "1rem" }} borderBottom={borderBottom} borderColor={"grey.dark.20"}>
                    <Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{transaction.description}</Text>
                        </Box>
                        {displayedStatus && chipVariant && (
                            <Box mt={2}>{displayedStatus && <Chip text={displayedStatus} variant={chipVariant} />}</Box>
                        )}
                    </Box>
                </Td>
                <Td
                    py={{ base: "1rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [borderBottom, chipVariant, renderAmountsCol, formattedDate, transaction.description, displayedStatus]
    )

    const renderSmallRow = useCallback(
        () => (
            <Tr
                w="full"
                cursor="pointer"
                _hover={{
                    bg: "grey.dark.10",
                }}
            >
                <Td
                    py={{ base: "0.5rem" }}
                    px={{ base: "0rem" }}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                    whiteSpace={"normal"}
                >
                    <Box>
                        <Box>
                            <Text color="grey.dark.60" textStyle={"ManropeSemiboldXSmall"}>
                                {formattedDate}
                            </Text>
                        </Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{transaction.description}</Text>
                        </Box>
                        {displayedStatus && chipVariant && (
                            <Box mt={2}>{displayedStatus && <Chip text={displayedStatus} variant={chipVariant} />}</Box>
                        )}
                    </Box>
                </Td>
                <Td
                    py={{ base: "0.5rem" }}
                    px={{ base: "0rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [borderBottom, chipVariant, renderAmountsCol, formattedDate, transaction.description, displayedStatus]
    )

    const renderExtraSmallRow = useCallback(
        () => (
            <Tr
                w="full"
                cursor="pointer"
                _hover={{
                    bg: "grey.dark.10",
                }}
            >
                <Td
                    py={{ base: "0.25rem" }}
                    px={{ base: "0rem" }}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                    whiteSpace={"normal"}
                >
                    <Box>
                        <Box>
                            <Text color="grey.dark.60" textStyle={"ManropeSemiboldXSmall"}>
                                {formattedDate}
                            </Text>
                        </Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{transaction.description}</Text>
                        </Box>
                        {displayedStatus && chipVariant && (
                            <Box mt={2}>{displayedStatus && <Chip text={displayedStatus} variant={chipVariant} />}</Box>
                        )}
                    </Box>
                </Td>
                <Td
                    py={{ base: "0.25rem" }}
                    px={{ base: "0rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [borderBottom, chipVariant, renderAmountsCol, formattedDate, transaction.description, displayedStatus]
    )

    if (rowVariant === "extra-small") {
        return renderExtraSmallRow()
    } else if (rowVariant === "small") {
        return renderSmallRow()
    } else if (rowVariant === "medium") {
        return renderMediumRow()
    } else if (rowVariant === "large") {
        return renderLargeRow()
    }

    return null
}

export default memo(TransactionRow)
