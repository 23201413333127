import { API_PATH, BALANCES_TAG_TYPES } from "./const"
import { BalancesV3Response } from "./types"
import { splitApi } from "@redux/splitApi"

export const balancesApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getBalances: builder.query<BalancesV3Response, undefined>({
            query: () => {
                return API_PATH.BALANCES
            },
            providesTags: [{ type: BALANCES_TAG_TYPES.BALANCES }],
        }),
    }),
})

export const { useGetBalancesQuery } = balancesApi
