import CheckCircle from "@assets/svgs/common/check-circle"
import { Box, Flex, Text } from "@chakra-ui/react"
import { ReceiptLineItem } from "@components/ui/lineItems/receiptLineItem"
import useRegion from "@hooks/useRegion"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { useTranslation } from "react-i18next"

export const WithdrawWireCompleted: React.FC = () => {
    const { t } = useTranslation("transfer")
    const { wireResponse } = DrawerWithdrawContext.useContainer()
    const { isCAUser } = useRegion()
    return (
        <Flex flex={1} flexDir="column">
            <Flex align="center" justifyContent={"space-between"} h="56px" w="full" my="1.75rem">
                <Text textStyle="ManropeSemiboldXLarge" color="black">
                    {t("withdraw.wire.submittedTitle")}
                </Text>
                <CheckCircle />
            </Flex>
            <Text textStyle="ManropeSemiboldBody" mb="1.5rem">
                {t("withdraw.wire.submittedMsgTop")}
            </Text>
            <Text textStyle="InterRegularBody">
                {t("withdraw.wire.submittedMsgBtm", {
                    institution: isCAUser ? t("withdraw.wire.caStatementInst") : t("withdraw.wire.usStatementInst"),
                })}
            </Text>
            {!isCAUser && (
                <>
                    <Box borderTop={"1px"} borderColor={"grey.light.10"} my={6} />
                    <ReceiptLineItem
                        size="md"
                        color="black"
                        leftText={t("withdraw.wire.referenceNumber")}
                        rightText={wireResponse?.reference}
                    />
                </>
            )}
        </Flex>
    )
}
