import { LimitOrderParams } from "@redux/orders/types"
import { API_PATH, TRANSACTIONS_TAG_TYPES } from "./const"
import { BALANCES_TAG_TYPES } from "@redux/balances/const"
import { CancelOrderParams, TransactionParams, TransactionsResponse } from "./types"
import { splitApi } from "@redux/splitApi"

export const transactionsApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getTransactions: builder.query<TransactionsResponse, TransactionParams>({
            query: ({
                types = "",
                page = 1,
                limit = 10,
                sort = "desc",
                statuses = "",
                assets = "",
            }: TransactionParams) => {
                const params = new URLSearchParams()

                params.append("page", page.toString())
                params.append("limit", limit.toString())

                return `${API_PATH.TRANSACTIONS}?sort=created_at,${sort}&limit=${limit}&page=${page}&types=${types}&statuses=${statuses}&assets=${assets}`
            },
            providesTags: (result) => (result ? [{ type: TRANSACTIONS_TAG_TYPES.TRANSACTIONS }] : []),
        }),
        deleteOpenOrder: builder.mutation<LimitOrderParams, CancelOrderParams>({
            query: (params) => ({
                url: `${API_PATH.ORDERS}/${params.order_id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: TRANSACTIONS_TAG_TYPES.TRANSACTIONS }, { type: BALANCES_TAG_TYPES.BALANCES }],
        }),
    }),
})

export const { useGetTransactionsQuery, useDeleteOpenOrderMutation } = transactionsApi
