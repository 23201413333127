import { BALANCES_TAG_TYPES } from "@redux/balances/const"
import { API_PATH, ORDERS_TAG_TYPES } from "./const"
import {
    InstantOrderParams,
    InstantOrderResponse,
    LimitOrderParams,
    LimitOrderResponse,
    LimitQuoteParams,
    LimitQuoteResponse,
    QuoteParams,
    QuoteResponse,
} from "./types"
import { splitApi } from "@redux/splitApi"
import { TRANSACTIONS_TAG_TYPES } from "@redux/transactions/const"

export const ordersApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getInstantQuote: builder.query<QuoteResponse, QuoteParams>({
            query: (params) => ({
                url: API_PATH.QUOTE,
                method: "POST",
                body: params,
            }),
            providesTags: [ORDERS_TAG_TYPES.QUOTE],
        }),
        getLimitQuote: builder.query<LimitQuoteResponse & { fee: string }, LimitQuoteParams>({
            query: (params) => ({
                url: API_PATH.LIMIT_QUOTE,
                method: "POST",
                body: params,
            }),
            transformResponse: (response: LimitQuoteResponse) => {
                return {
                    ...response,
                    fee: response.netcoins_fee,
                }
            },
            providesTags: [ORDERS_TAG_TYPES.LIMIT_QUOTE],
        }),
        postLimitOrder: builder.mutation<LimitOrderResponse, LimitOrderParams>({
            query: (params) => ({
                url: API_PATH.CREATE_LIMIT_ORDER,
                method: "POST",
                body: params,
            }),
            invalidatesTags: [
                { type: ORDERS_TAG_TYPES.LIMIT_QUOTE },
                { type: BALANCES_TAG_TYPES.BALANCES },
                { type: TRANSACTIONS_TAG_TYPES.TRANSACTIONS },
            ],
        }),
        postInstantOrder: builder.mutation<InstantOrderResponse, InstantOrderParams>({
            query: (params) => ({
                url: API_PATH.CREATE_INSTANT_ORDER,
                method: "POST",
                body: params,
            }),
            invalidatesTags: [
                { type: ORDERS_TAG_TYPES.QUOTE },
                { type: BALANCES_TAG_TYPES.BALANCES },
                { type: TRANSACTIONS_TAG_TYPES.TRANSACTIONS },
            ],
        }),
    }),
})

export const {
    useLazyGetInstantQuoteQuery,
    useLazyGetLimitQuoteQuery,
    usePostLimitOrderMutation,
    usePostInstantOrderMutation,
} = ordersApi
