import { appendVersion } from "@util/RTKApi"

export const SLICE_NAME = "balances"

export const BALANCES_TAG_TYPES = {
    BALANCES: "balances",
} as const

export const API_PATH = {
    BALANCES: appendVersion("balances", "v3"),
} as const
