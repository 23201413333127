import { Flex, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuOptionGroup, Text } from "@chakra-ui/react"
import IconButton from "@components/ui/buttons/icon"
import { useTranslation } from "react-i18next"
import { MdChevronRight, MdOutlineSettings, MdPayment, MdSettings } from "react-icons/md"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"

export default function MenuSettings() {
    const { t } = useTranslation(["app", "common"])
    const { navigate } = useRestrictedNavigation()

    return (
        <Menu gutter={6}>
            <MenuButton
                as={IconButton}
                key={"Settings"}
                name={"Settings"}
                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_SOLID}
                size="lg"
                icon={MdSettings}
                iconOutline={MdOutlineSettings}
            />
            <MenuList>
                <MenuItem justifyContent={"flex-start !important"} onClick={() => navigate(ROUTES.DASHBOARD_SETTINGS)}>
                    <Flex w="full" align="center" justifyContent="space-between">
                        <Text textStyle="ManropeMediumBody">{t("nav.viewAll")}</Text>
                        <Icon as={MdChevronRight} color="black" />
                    </Flex>
                </MenuItem>
                <MenuDivider />
                <MenuOptionGroup
                    color="grey.light.80"
                    textStyle="ManropeSemiboldBodySmall"
                    title={t("nav.savedGroup")}
                />
                <MenuItem onClick={() => navigate(ROUTES.SAVED_ADDRESSES_CRYPTO)}>
                    <Text textStyle="ManropeMediumBody">{t("crypto", { ns: "common" })}</Text>
                </MenuItem>
                <MenuItem onClick={() => navigate(ROUTES.SAVED_ADDRESSES_FIAT)}>
                    <Text textStyle="ManropeMediumBody">{t("fiat", { ns: "common" })}</Text>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => navigate(ROUTES.BUY_WITH_CREDIT)}>
                    <Flex align="center" justifyContent="space-between">
                        <Icon as={MdPayment} color="black" mr="10px" />
                        <Text textStyle="ManropeMediumBody">{t("nav.buyWithCredit")}</Text>
                    </Flex>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}
