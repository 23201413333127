import { Flex, Text } from "@chakra-ui/react"
import Select from "@components/ui/select"
import TextInput from "@components/ui/textInput"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { REGEX_NUMERICAL } from "@util/regexes"
import { useTranslation } from "react-i18next"

export default function WithdrawAddBankAdditional() {
    const { controlAddBankExtra: control } = DrawerWithdrawContext.useContainer()
    const { t } = useTranslation("transfer")

    return (
        <Flex flexDir="column">
            <Flex align="center" h="56px" w="full" my="1.75rem">
                <Text textStyle="ManropeSemiboldXLarge" color="black">
                    {t("withdraw.wire.addNew")}
                </Text>
            </Flex>
            <Text textStyle="ManropeSemiboldBody" color="black" mb="0.5rem">
                {t("withdraw.wire.extraDetails")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="1.5rem">
                {t("withdraw.wire.extraDetailsDisclaimer")}
            </Text>
            <Flex w="full">
                <form style={{ width: "100%", display: "flex", flexDirection: "column", rowGap: "1.5rem" }}>
                    <Select
                        name="extra_type"
                        label={t("withdraw.wire.extraBankType")}
                        control={control}
                        rules={{ required: t("error.required", { ns: "common" }) }}
                        options={[
                            { value: "intermediary", label: t("withdraw.wire.intermediary") },
                            { value: "correspondent", label: t("withdraw.wire.correspondent") },
                        ]}
                    />
                    <TextInput
                        name="extra_name"
                        control={control}
                        label={t("withdraw.wire.bankName")}
                        placeholder="Ex. BigBank"
                        rules={{ required: t("error.required", { ns: "common" }) }}
                    />
                    <TextInput
                        key="extra-account"
                        name="extra_account"
                        control={control}
                        label={t("withdraw.wire.accountNum")}
                        placeholder="Ex. 122105155"
                        maxLength={12}
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            pattern: { value: REGEX_NUMERICAL, message: t("common:error.numbersOnly") },
                            minLength: { value: 7, message: t("common:error.minLength", { len: 7 }) },
                            maxLength: { value: 12, message: t("common:error.maxLength", { len: 12 }) },
                        }}
                    />
                    <TextInput
                        name={"extra_swift"}
                        control={control}
                        label={t("withdraw.wire.bankCode")}
                        placeholder="Ex. AAAA"
                        rules={{ required: t("error.required", { ns: "common" }) }}
                    />
                </form>
            </Flex>
        </Flex>
    )
}
