import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Box, Flex, Text } from "@chakra-ui/react"
import Link from "@components/ui/link"
import PinInput from "@components/ui/pinInput"
import { useTranslation } from "react-i18next"
import { MdOutlineVerifiedUser } from "react-icons/md"

export type TwoFACodeProps = {
    title?: string
    description?: string
    error?: boolean
    showTFARecovery?: boolean
    value: string
    onChange: (value: string) => void
    onComplete?: (val: string) => void
}

export default function TwoFACode({
    title,
    description,
    error,
    showTFARecovery,
    value,
    onChange,
    onComplete,
}: TwoFACodeProps) {
    const { t } = useTranslation(["app", "common"])

    return (
        <Box w="full">
            {title && (
                <Text textStyle={"ManropeSemiboldXLarge"} mb={7}>
                    {title}
                </Text>
            )}
            {description && (
                <Text my={6} textStyle={"InterRegularBody"}>
                    {description}
                </Text>
            )}
            <PinInput
                name="input"
                numOfFields={6}
                baseColor="white"
                value={value}
                onChange={onChange}
                error={error}
                isAlphaNumeric={true}
                onComplete={onComplete}
            />
            {showTFARecovery && (
                <Flex w="full" borderRadius="md" gap={3} p={4} mt={6} borderColor={"grey.light.10"} borderWidth={1}>
                    <Box
                        p={2}
                        bg={"green.light.10"}
                        borderRadius="md"
                        display="flex"
                        alignItems="center"
                        alignSelf="stretch"
                    >
                        <ReactIcon icon={MdOutlineVerifiedUser} size={20} color={"green.light.100"} />
                    </Box>
                    <Box display={"flex"} flexDir={"column"} justifyContent={"center"}>
                        <Text color="black" textStyle="ManropeSemiboldBodySmall">
                            {t("twoFASetup.lostTwoFAAccess", { ns: "twoFA" })}
                        </Text>
                        <Link
                            href="https://netcoins.zendesk.com/hc/en-us/articles/360056421992-I-lost-my-2FA-what-can-I-do"
                            text={t("twoFASetup.clickHereToRecover", { ns: "twoFA" })}
                            hasIcon
                        />
                    </Box>
                </Flex>
            )}
        </Box>
    )
}
