import { Box, Image, Text, VStack } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import CopyComponent from "@components/ui/copyField"
import Link from "@components/ui/link"
import Checkbox from "@components/ui/checkbox"
import { DrawerTwoFASetupContext } from "@screens/twoFASetup/drawerTwoFASetup/DrawerTwoFAContext"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { MdOpenInNew } from "react-icons/md"
import useSupport from "@hooks/useSupport"

export default function TwoFASetupKey() {
    const { t } = useTranslation(["app", "common"])

    const { accept2FARisk, setAccept2FARisk, secret, qr } = DrawerTwoFASetupContext.useContainer()

    const { tfaInfoLink } = useSupport()

    const onButtonPress = () => {
        window.open(tfaInfoLink, "_blank")
    }

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"} mb={7}>
                {t("twoFASetup.setupTwoFA", { ns: "twoFA" })}
            </Text>
            <VStack spacing={6} w="full">
                <Text textStyle={"ManropeSemiboldBody"}>{t("twoFASetup.toRegister", { ns: "twoFA" })}</Text>
                <Text mt={1} textStyle={"InterRegularBody"}>
                    {t("twoFASetup.haveOneAlready", { ns: "twoFA" })}
                </Text>
                <Box h="fit-content" display={"flex"} flexDir={"column"} alignItems={"center"}>
                    <Image src={qr} objectFit={"contain"} boxSize="196px" />
                </Box>
                <Box w="full">
                    <Text textStyle={"ManropeSemiboldBodySmall"} mb={1}>
                        {t("key", { ns: "common" })}
                    </Text>
                    <CopyComponent text={secret || ""} />
                </Box>
                <StandardButton
                    children={t("twoFASetup.howToSetup", { ns: "twoFA" })}
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_LINK}
                    rightIcon={MdOpenInNew}
                    onClick={onButtonPress}
                    p={0}
                    alignSelf={"flex-start"}
                />
                <Text textStyle={"ManropeSemiboldBody"} w="full">
                    {t("twoFASetup.writeOffline", { ns: "twoFA" })}
                </Text>
                <Text textStyle={"InterRegularBody"} w="full">
                    {t("twoFASetup.ifKeyLost", { ns: "twoFA" })}
                </Text>
                <Checkbox
                    text={t("twoFASetup.iUnderstandKeyLoss", { ns: "twoFA" })}
                    isChecked={accept2FARisk}
                    setValue={(isChecked) => {
                        setAccept2FARisk(isChecked)
                    }}
                />
            </VStack>
        </Box>
    )
}
