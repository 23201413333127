import { Box, Flex, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text } from "@chakra-ui/react"
import IconButton from "@components/ui/buttons/icon"
import { useTranslation } from "react-i18next"
import { MdAccountCircle, MdLogout, MdOutlineAccountCircle } from "react-icons/md"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import { ENUM_ICON_BUTTON_VARIANTS } from "@components/ui/buttons/icon/types"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"
import DetailsTypeCard from "@components/ui/badge/detailsType"
import useSupport from "@hooks/useSupport"
import { logout } from "@redux/auth/slice"

export default function MenuProfile() {
    const { t } = useTranslation(["app", "common"])
    const { navigate } = useRestrictedNavigation()
    const account = useAppSelector(selectAccountDetails)
    const { isBusinessUser } = useSupport()
    const dispatch = useAppDispatch()

    async function handleLogout() {
        await dispatch(logout())
    }

    return (
        <Menu gutter={6}>
            <MenuButton
                as={IconButton}
                key={"Profile"}
                name={"Profile"}
                variant={ENUM_ICON_BUTTON_VARIANTS.PRIMARY_SOLID}
                size="lg"
                icon={MdAccountCircle}
                iconOutline={MdOutlineAccountCircle}
            />
            <MenuList>
                <MenuItem pointerEvents={"none"} as={Box}>
                    <Flex w="full" flexDir="column">
                        <Text textStyle="ManropeMediumBody">
                            {isBusinessUser
                                ? `${account?.business.alt_name ? account.business.alt_name : account?.business.legal_name}`
                                : `${account?.identity.first_name} ${account?.identity.last_name}`}
                        </Text>
                        <Text textStyle="ManropeMediumBody">{account?.email}</Text>
                        {isBusinessUser && (
                            <DetailsTypeCard alignSelf={"flex-start"} mt="0.5rem" isPersonal={false} mb={0} />
                        )}
                    </Flex>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => navigate(ROUTES.BUY_WITH_CREDIT)}>
                    <Flex align="center" justifyContent="space-between">
                        <Icon as={MdOutlineAccountCircle} color="black" mr="10px" />
                        <Text textStyle="ManropeMediumBody">{t("nav.viewProfile")}</Text>
                    </Flex>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleLogout}>
                    <Flex align="center" justifyContent="space-between">
                        <Icon as={MdLogout} color="black" mr="10px" />
                        <Text textStyle="ManropeMediumBody">{t("logout", { ns: "common" })}</Text>
                    </Flex>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}
