import { Box, Text } from "@chakra-ui/react"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import { ErrorModal } from "@components/ui/modals/errorModal"
import useGenericToast from "@hooks/useGenericToast"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import TwoFAAuthyInfo from "../twoFASetupScreens/twoFAAuthyInfo"
import TwoFASetupKey from "../twoFASetupScreens/twoFASetupKey"
import TwoFASetupRoot from "../twoFASetupScreens/twoFASetupRoot"
import TwoFASetupSuccess from "../twoFASetupScreens/twoFASetupSuccess"
import TwoFASetupVerify from "../twoFASetupScreens/twoFASetupVerify"
import { DrawerTwoFASetupContext } from "./DrawerTwoFAContext"

export enum TWO_FA_SETUP_TABS {
    ROOT = 0,
    TWO_FACTOR_APP_DOWNLOAD = 1,
    TWO_FACTOR_KEY = 2,
    TWO_FACTOR_VERIFY = 3,
    TWO_FACTOR_SUCCESS = 4,
}

export interface DrawerTwoFASetupProps {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
}

const TwoFASetupDrawer: React.FC<DrawerTwoFASetupProps> = ({ isOpen, toggleSideDrawerOpen }: DrawerTwoFASetupProps) => {
    const { t } = useTranslation(["twoFASetup", "common"])
    const {
        view,
        setView,
        accept2FARisk,
        errorMessage,
        setErrorMessage,
        handleCodeVerification,
        loading,
        errorDialog,
        onErrorDialogClose,
        isErrModalOpen,
    } = DrawerTwoFASetupContext.useContainer()
    const { successToast } = useGenericToast()

    const ComponentMap: Record<string, JSX.Element> = {
        [TWO_FA_SETUP_TABS.ROOT]: <TwoFASetupRoot />,
        [TWO_FA_SETUP_TABS.TWO_FACTOR_APP_DOWNLOAD]: <TwoFAAuthyInfo />,
        [TWO_FA_SETUP_TABS.TWO_FACTOR_KEY]: <TwoFASetupKey />,
        [TWO_FA_SETUP_TABS.TWO_FACTOR_VERIFY]: <TwoFASetupVerify />,
        [TWO_FA_SETUP_TABS.TWO_FACTOR_SUCCESS]: <TwoFASetupSuccess />,
    }

    const toggleDrawerOpen = () => {
        toggleSideDrawerOpen()
    }

    const handleButtonClick = async () => {
        setErrorMessage("")

        if (view === TWO_FA_SETUP_TABS.TWO_FACTOR_KEY && !accept2FARisk) {
            setErrorMessage(t("common:error.acceptTerms"))
            return
        }

        if (view === TWO_FA_SETUP_TABS.TWO_FACTOR_VERIFY) {
            await handleCodeVerification()
            toggleDrawerOpen()
            successToast(t("tfaEnabled"))
            return
        }

        if (view === TWO_FA_SETUP_TABS.TWO_FACTOR_SUCCESS) {
            toggleDrawerOpen()
            return
        }

        setView((prev) => prev + 1)
    }

    const RenderView = () => {
        if (!view) {
            return ComponentMap[0]
        }

        return ComponentMap[view]
    }

    const ButtonText = useMemo(() => {
        if (view === TWO_FA_SETUP_TABS.TWO_FACTOR_SUCCESS) {
            return t("common:done")
        }
        if (view === TWO_FA_SETUP_TABS.TWO_FACTOR_VERIFY) {
            return t("enableTfa")
        }

        return t("common:continue")
    }, [view])

    const handleBackNavigation = () => {
        setView((prev) => prev - 1)
        setErrorMessage("")
    }

    return (
        <>
            <SideDrawer
                isOpen={isOpen}
                toggleSideDrawerOpen={toggleSideDrawerOpen}
                size="md"
                header={
                    <StandardDrawerHeader
                        onBack={
                            view === 0 || view === TWO_FA_SETUP_TABS.TWO_FACTOR_SUCCESS
                                ? undefined
                                : handleBackNavigation
                        }
                        onClose={toggleDrawerOpen}
                    />
                }
                footerButton={{
                    variant: ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT,
                    onClick: handleButtonClick,
                    children: ButtonText,
                    isLoading: loading,
                    type: "button",
                }}
                extraFooter={
                    <Box display={"flex"} justifyContent={"center"}>
                        {errorMessage && (
                            <Text color={"red.light.100"} textStyle={"InterRegularBody"} mb={3}>
                                {errorMessage}
                            </Text>
                        )}
                    </Box>
                }
            >
                {RenderView()}
            </SideDrawer>
            <ErrorModal description={errorDialog} isOpen={isErrModalOpen} onClose={onErrorDialogClose} />
        </>
    )
}

const TwoFASetupDrawerHOC: React.FC<DrawerTwoFASetupProps> = (props) => (
    <DrawerTwoFASetupContext.Provider initialState={undefined}>
        <TwoFASetupDrawer {...props} />
    </DrawerTwoFASetupContext.Provider>
)

export default TwoFASetupDrawerHOC
