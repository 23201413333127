import { balancesApi } from "@redux/balances/apiSlice"
import { configureStore } from "@reduxjs/toolkit"
import appReducer from "./reducer"
import { splitApi } from "@redux/splitApi"

export const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware().concat(splitApi.middleware)
        return middleware
    },
})
