import { useTranslation } from "react-i18next"
import TwoFACode from "@screens/twoFASetup/twoFACode"
import { DrawerTwoFASetupContext } from "@screens/twoFASetup/drawerTwoFASetup/DrawerTwoFAContext"
import { Box } from "@chakra-ui/layout"
import { clickSideDrawerButton } from "@components/sideDrawer"

export default function TwoFASetupVerify() {
    const { t } = useTranslation(["app", "common"])

    const { tfaCode, setTFACode, errorMessage, setErrorMessage } = DrawerTwoFASetupContext.useContainer()

    const handleTFACodeChange = (value: string) => {
        setTFACode(value)
        setErrorMessage("")
    }

    return (
        <Box mt={7}>
            <TwoFACode
                title={t("twoFASetup.setupTwoFA", { ns: "twoFA" })}
                description={t("twoFASetup.enterCode", { ns: "twoFA" })}
                error={errorMessage ? true : false}
                value={tfaCode}
                onChange={handleTFACodeChange}
                onComplete={clickSideDrawerButton}
            />
        </Box>
    )
}
