import { createContainer } from "@util/UnstatedContext"
import { FundType } from "."
import { useState, useMemo, useEffect } from "react"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { imageMap } from "@assets/svgs/coins"
import { RadioSelectOption } from "@components/ui/radio/radioSelect"
import useRegion from "@hooks/useRegion"

type InitialState = {
    fundType?: FundType
}

const useDrawerFundContext = ({ fundType }: InitialState) => {
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [currentFundType, setCurrentFundType] = useState<FundType | undefined>(fundType)
    const [selectedCoin, setSelectedCoin] = useState<RadioSelectOption | null>(null)

    const [cryptoDisclaimers, setCryptoDisclaimers] = useState({
        disclaimer1: false,
        disclaimer2: false,
        disclaimer3: false,
    })

    const [interacDisclaimers, setInteracDisclaimers] = useState({
        disclaimer1: false,
        disclaimer2: false,
        disclaimer3: false,
    })

    const [caWireDisclaimers, setCAWireDisclaimers] = useState({
        disclaimer1: false,
        disclaimer2: false,
        disclaimer3: false,
        disclaimer4: false,
    })

    const [usWireDisclaimers, setUSWireDisclaimers] = useState({
        disclaimer1: false,
        disclaimer2: false,
        disclaimer3: false,
    })

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const assetDetailsAsArray = useMemo(() => Object.values(assetDetailsData || {}), [assetDetailsData])

    const filteredAssets = useMemo(
        () => assetDetailsAsArray.filter((asset) => asset.restrictions?.deposit === true),
        [assetDetailsAsArray]
    )

    const coinRadioOptions = useMemo(
        () =>
            filteredAssets.map((asset) => ({
                name: asset.name,
                symbol: asset.symbol,
                imageUrl: imageMap[asset.symbol.toLowerCase() as keyof typeof imageMap] || imageMap["btc"],
            })),
        [filteredAssets]
    )

    const selectedCoinData = useMemo(() => {
        if (!selectedCoin) return null
        return assetDetailsAsArray.find((asset) => asset.symbol === selectedCoin?.symbol)
    }, [selectedCoin])

    useEffect(() => {
        if (!selectedCoin) {
            const btcOption = coinRadioOptions.find((option) => option.symbol === "BTC")
            setSelectedCoin(btcOption || null)
        }
    }, [coinRadioOptions])

    return {
        coinRadioOptions,
        currentFundType,
        setCurrentFundType,
        cryptoDisclaimers,
        setCryptoDisclaimers,
        selectedCoin,
        setSelectedCoin,

        interacDisclaimers,
        setInteracDisclaimers,

        caWireDisclaimers,
        setCAWireDisclaimers,

        errorMessage,
        setErrorMessage,
        selectedCoinData,

        usWireDisclaimers,
        setUSWireDisclaimers,
    }
}

export const DrawerFundContext = createContainer(useDrawerFundContext)
