import AAVE from "./AAVE.json"
import ADA from "./ADA.json"
import ALGO from "./ALGO.json"
import APE from "./APE.json"
import ATOM from "./ATOM.json"
import AVAX from "./AVAX.json"
import AXS from "./AXS.json"
import BAT from "./BAT.json"
import BCH from "./BCH.json"
import BTC from "./BTC.json"
import CHZ from "./CHZ.json"
import CRV from "./CRV.json"
import DOGE from "./DOGE.json"
import DOT from "./DOT.json"
import ENJ from "./ENJ.json"
import EOS from "./EOS.json"
import ETH from "./ETH.json"
import FTM from "./FTM.json"
import GALA from "./GALA.json"
import GRT from "./GRT.json"
import LINK from "./LINK.json"
import LTC from "./LTC.json"
import MANA from "./MANA.json"
import POL from "./POL.json"
import MKR from "./MKR.json"
import NEAR from "./NEAR.json"
import QNT from "./QNT.json"
import SAND from "./SAND.json"
import SHIB from "./SHIB.json"
import SOL from "./SOL.json"
import SUSHI from "./SUSHI.json"
import UNI from "./UNI.json"
import USDC from "./USDC.json"
import XLM from "./XLM.json"
import XRP from "./XRP.json"
import XTZ from "./XTZ.json"
import YFI from "./YFI.json"
import OneInch from "./1INCH.json"
import DYDX from "./DYDX.json"
import LRC from "./LRC.json"
import PAXG from "./PAXG.json"
import RENDER from "./RENDER.json"
import ZEC from "./ZEC.json"
import USDT from "./USDT.json"
import PEPE from "./PEPE.json"
import BONK from "./BONK.json"
import INJ from "./INJ.json"
import TIA from "./TIA.json"
import WIF from "./WIF.json"
import ARB from "./ARB.json"
import WLD from "./WLD.json"
import ZRX from "./ZRX.json"
import COMP from "./COMP.json"
import HBAR from "./HBAR.json"
import KNC from "./KNC.json"
import WBTC from "./WBTC.json"
import DAI from "./DAI.json"

export default <const>{
    AAVE,
    ADA,
    ALGO,
    APE,
    ATOM,
    AVAX,
    AXS,
    BAT,
    BCH,
    BTC,
    CHZ,
    CRV,
    DOGE,
    DOT,
    ENJ,
    EOS,
    ETH,
    FTM,
    GALA,
    GRT,
    LINK,
    LTC,
    MANA,
    POL,
    MKR,
    NEAR,
    QNT,
    SAND,
    SHIB,
    SOL,
    SUSHI,
    UNI,
    USDC,
    USDT,
    XLM,
    XRP,
    XTZ,
    YFI,
    "1INCH": OneInch,
    DYDX,
    LRC,
    PAXG,
    RENDER,
    ZEC,
    PEPE,
    BONK,
    INJ,
    TIA,
    WIF,
    ARB,
    WLD,
    DAI,
    ZRX,
    COMP,
    KNC,
    WBTC,
    HBAR,
}
