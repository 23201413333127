import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { Box, Flex, Table, TableContainer, Tbody, Text, Th, Thead, Tr, useDisclosure, useToast } from "@chakra-ui/react"
import DashboardFooter from "@components/footer/dashboard"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { ErrorModal } from "@components/ui/modals/errorModal"
import useRestrictedNavigation, { RESTRICTED_FLOWS } from "@hooks/useRestrictNavigation"
import useSupport from "@hooks/useSupport"
import { selectAccountDetails } from "@redux/account/selectors"
import { TUserState } from "@redux/account/types"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { AssetDetails, AssetDetailsFull } from "@redux/assetsDetails/types"
import { buildRouteWithParams } from "@routing/route-utils"
import { RouteParams, ROUTES } from "@routing/routes"
import useColorFormatConverter from "@theme/useColorFormatConverter"
import CoinTradingRow from "components/coins/tradingRow"
import Searchbar from "components/ui/searchbar"
import Tabs from "components/ui/tabs"
import ThSorting from "components/ui/thSorting"
import Echo from "laravel-echo"
import { debounce } from "lodash"
import * as pusher from "pusher-js"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdAdd, MdMenu, MdRemove, MdOutlineSearch } from "react-icons/md"
import DrawerCoinTrade, { BUYSELL_TAB } from "./drawerCoinTrade"
import { COIN_SORT, PriceChangeData } from "./types"
import { AiOutlineStar } from "react-icons/ai"
import { useLazyGetAssetFavouritesQuery } from "@redux/asset/apiSlice"

window.Pusher = pusher

declare global {
    interface Window {
        Pusher: typeof pusher
        Echo: Echo
    }
}

const ECHO_OPTIONS = {
    broadcaster: "reverb",
    key: process.env.REACT_APP_REVERB_APP_KEY,
    wsHost: process.env.REACT_APP_REVERB_HOST,
    wsPort: process.env.REACT_APP_REVERB_PORT ?? 80,
    wssPort: process.env.REACT_APP_REVERB_PORT ?? 443,
    forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
}

export default function Trade() {
    const { t } = useTranslation(["app", "common"])
    const toast = useToast()
    const colorConvertor = useColorFormatConverter()
    const dispatch = useAppDispatch()
    const { navigate, evaluateRestriction, isEvaluationState, userState } = useRestrictedNavigation()
    const { isOpen: isModalOpen, onOpen, onClose: onModalClose } = useDisclosure()
    const { onbSupportEmail } = useSupport()

    const EchoClient = useRef<Echo>()

    const dataHashmap = useRef<{ [key: string]: AssetDetails }>() // hashmap representation of coin data. used to minimize state updates
    const dataHashmapPending = useRef<{ [key: string]: AssetDetails }>() // pending hashmap to batch updates

    const dataRef = useRef<Set<string>>(new Set()) // Reference for all coin data
    const favouritesDataRef = useRef<Set<string>>(new Set()) // Reference for all favourite coin data
    const [displayedData, setDisplayedData] = useState<Set<string>>(new Set()) // sorted and filtered coin data
    const [displayedFavouritesData, setDisplayedFavouritesData] = useState<Set<string>>(new Set()) // sorted and filtered favourite coin data

    const [tab, setTab] = useState(BUYSELL_TAB.BUY)
    const [tabIndex, setTabIndex] = useState(0)
    const [search, setSearch] = useState("")
    const [dialogError, setDialogError] = useState<{ title: string; description: string } | undefined>(undefined)

    const [getFavourites, { data: favouritesData }] = useLazyGetAssetFavouritesQuery(undefined)
    const isInitialLoad = useRef(true)

    const [coinSort, setCoinSort] = useState<COIN_SORT>(COIN_SORT.MARKETCAP_DESC)
    const [favCoinSort, setFavCoinSort] = useState<COIN_SORT>(COIN_SORT.MARKETCAP_DESC)
    const [selectedCoin, setSelectedCoin] = useState<AssetDetailsFull>()
    const [isDrawerOpen, toggleDrawerOpen] = useState(false)
    const accountDetails = useAppSelector(selectAccountDetails)
    const accountCurrency = accountDetails?.currency
    const { data: assetDetailsData, error } = useGetAssetsDetailsQuery({ currency: accountCurrency })

    useEffect(() => {
        if (assetDetailsData) {
            setSelectedCoin(assetDetailsData["BTC"])
            dataHashmap.current = assetDetailsData
            dataHashmapPending.current = assetDetailsData
            const set = new Set(
                Object.entries(assetDetailsData).map(([key, value]) => {
                    return `${value.name}::${key}`
                })
            )
            const sortedSet = sortData(coinSort, set)
            dataRef.current = sortedSet
            setDisplayedData(sortedSet)
            // establishWebsocketConnection() // TODO uncomment this when you want to re-enable the websocket
        }

        if (error) {
            toast({
                title: t("error.server", { ns: "common" }),
                description: t("error.fetch", { ns: "common" }),
                status: "error",
                duration: 50000,
            })
        }
    }, [assetDetailsData, error])

    // get favourites on screen load
    useEffect(() => {
        getFavourites(undefined)
    }, [])

    // used on initial load to sort the favourites data. All favourites changes are handled client-side after this step
    useEffect(() => {
        if (favouritesData && dataHashmap.current && isInitialLoad.current) {
            isInitialLoad.current = false
            const set = new Set(
                favouritesData.data.map((item) => {
                    const assetDetails = dataHashmap?.current?.[item.asset.symbol] as AssetDetails
                    return `${assetDetails.name}::${item.asset.symbol}`
                })
            )
            const sortedArr = sortData(favCoinSort, set)
            favouritesDataRef.current = sortedArr
            setDisplayedFavouritesData(sortedArr)
        }
    }, [favouritesData, dataHashmap.current])

    // only runs if the EchoClient is initialized
    useEffect(() => {
        // batch up the changes into a single update every 5s
        const interval = setInterval(() => {
            if (dataHashmap.current) {
                dataHashmap.current = { ...dataHashmapPending.current }
            }
        }, 5000)

        // Cleanup socket
        return () => {
            clearInterval(interval)
            if (EchoClient.current) {
                EchoClient.current.disconnect()
                EchoClient.current = undefined
            }
        }
        // TODO would be better to start this based off of an event instead of a useEffect. Problem here is EchoClient could init before dataHashmap is set
        // and that would break this
    }, [EchoClient.current])

    useEffect(() => {
        return () => {
            debounceResults.cancel()
        }
    }, [])

    const displayedSet = useMemo(() => {
        return tabIndex === 0 ? displayedData : displayedFavouritesData
    }, [tabIndex, displayedData, displayedFavouritesData])

    const filterData = (search: string, arr: Set<string>, favArr: Set<string>) => {
        if (search === "") {
            setDisplayedData(arr)
            setDisplayedFavouritesData(favArr)
        } else {
            ;[arr, favArr].forEach((set, i) => {
                let filteredSet = new Set<string>()
                for (let val of set) {
                    const [name, symbol] = val.split("::")
                    if (
                        name.toLowerCase().includes(search.toLowerCase()) ||
                        symbol.toLowerCase().includes(search.toLowerCase())
                    ) {
                        filteredSet.add(val)
                    }
                }
                if (i === 0) setDisplayedData(filteredSet)
                else setDisplayedFavouritesData(filteredSet)
            })
        }
    }

    const debounceResults = useMemo(() => {
        return debounce((search: string, arr: Set<string>, favArr: Set<string>) => filterData(search, arr, favArr), 300)
    }, [])

    const handleSearchChange = (text: string) => {
        setSearch(text)
        debounceResults(text, dataRef.current, favouritesDataRef.current)
    }

    function establishWebsocketConnection() {
        if (EchoClient.current) return

        EchoClient.current = new Echo(ECHO_OPTIONS)
        for (const key in dataRef.current) {
            const channel = EchoClient.current.channel("instrument." + key + accountCurrency)
            channel.listen(".pricechange", (data: any) => {
                const { buyPrice, sellPrice } = data as PriceChangeData

                if (dataHashmap?.current?.[key] && dataHashmapPending?.current?.[key]) {
                    const midMarketPrice = ((Number(buyPrice) + Number(sellPrice)) / 2).toFixed(2)
                    if (dataHashmapPending.current) {
                        dataHashmapPending.current[key].price = midMarketPrice.toString()
                    }
                }
            })
        }
    }

    // this is a bit of a pain point. if performance issues arise, this could be a good place to start
    // consider making the refs that are Set<string> into Map<string, AssetDetails>
    function sortData(type: COIN_SORT, data: Set<string>): Set<string> {
        const sortedArr = Array.from(data, (str) => {
            const symbol = str.split("::")[1]
            return dataHashmap?.current?.[symbol] as AssetDetails
        }).sort((a: AssetDetails, b: AssetDetails) => {
            switch (type) {
                case COIN_SORT.PRICE_ASC:
                    return (Number(a.price) ?? 0) - (Number(b.price) ?? 0)
                case COIN_SORT.PRICE_DESC:
                    return (Number(b.price) ?? 0) - (Number(a.price) ?? 0)
                case COIN_SORT.HR24PERCENT_ASC:
                    return (Number(a["24h_percent"]) ?? 0) - (Number(b["24h_percent"]) ?? 0)
                case COIN_SORT.HR24PERCENT_DESC:
                    return (Number(b["24h_percent"]) ?? 0) - (Number(a["24h_percent"]) ?? 0)
                case COIN_SORT.MARKETCAP_ASC:
                    return (a.statistics?.market_cap_cad ?? 0) - (b.statistics?.market_cap_cad ?? 0)
                case COIN_SORT.MARKETCAP_DESC:
                    return (b.statistics?.market_cap_cad ?? 0) - (a.statistics?.market_cap_cad ?? 0)
            }
        })

        return new Set(
            sortedArr.map((coin) => {
                return `${coin.name}::${coin.symbol}`
            })
        )
    }

    function sortDisplayData(type: COIN_SORT): void {
        const set = tabIndex === 0 ? displayedData : displayedFavouritesData
        const sortedData = sortData(type, set)
        if (tabIndex === 0) {
            setDisplayedData(sortedData)
        } else {
            setDisplayedFavouritesData(sortedData)
        }
    }

    function handleViewMore(symbol: string) {
        const route = buildRouteWithParams(ROUTES.MARKET_DETAILS, { [RouteParams.ASSET]: symbol })
        navigate(route)
    }

    const handleDrawerOpen = (tab: BUYSELL_TAB) => {
        setTab(tab)
        toggleDrawerOpen(true)
    }

    const openDrawer = (tab: BUYSELL_TAB) => {
        evaluateRestriction(RESTRICTED_FLOWS.TRADE, () => handleDrawerOpen(tab), { blockWithModal: true })
    }

    const closeDrawer = () => {
        toggleDrawerOpen(false)
    }

    const handleSortClick = (sortType: "PRICE" | "MARKETCAP" | "HR24PERCENT") => {
        const isFavourite = tabIndex === 1
        const sort = isFavourite ? favCoinSort : coinSort
        const sortOrder = {
            PRICE: [COIN_SORT.PRICE_ASC, COIN_SORT.PRICE_DESC],
            MARKETCAP: [COIN_SORT.MARKETCAP_ASC, COIN_SORT.MARKETCAP_DESC],
            HR24PERCENT: [COIN_SORT.HR24PERCENT_ASC, COIN_SORT.HR24PERCENT_DESC],
        }
        const type = sortOrder[sortType][0] === sort ? sortOrder[sortType][1] : sortOrder[sortType][0]
        sortDisplayData(type)
        if (tabIndex === 0) setCoinSort(type)
        else setFavCoinSort(type)
    }

    const closePopup = () => {
        setDialogError(undefined)
        onModalClose()
    }

    return (
        <Box display={"flex"} flexDir={"column"} h="full">
            <Box display="flex" flexDir={{ base: "column", md: "row" }} rowGap="1rem" columnGap="1.5rem" mb="1.5rem">
                <Box
                    display="flex"
                    flex={1}
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems="center"
                    justifyContent={"space-between"}
                    rowGap={"1rem"}
                >
                    <Box color="black">
                        <Text textStyle="ManropeMedium4xLarge">{t("trade.trade")}</Text>
                        <Text textStyle="InterRegularBodySmall">{t("trade.subtitle")}.</Text>
                    </Box>
                </Box>
                <Box display="flex" flex={1} alignItems={"center"} justifyContent={"flex-end"} columnGap="0.5rem">
                    <StandardButton
                        flex={1}
                        type="button"
                        leftIcon={MdAdd}
                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                        onClick={() => {
                            openDrawer(BUYSELL_TAB.BUY)
                        }}
                        alignSelf="flex-end"
                        size="xl"
                    >
                        {t("buy", { ns: "common" })}
                    </StandardButton>
                    <StandardButton
                        flex={1}
                        type="button"
                        leftIcon={MdRemove}
                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY_FAT}
                        onClick={() => {
                            openDrawer(BUYSELL_TAB.SELL)
                        }}
                        alignSelf="flex-end"
                        size="xl"
                    >
                        {t("sell", { ns: "common" })}
                    </StandardButton>
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                justifyContent={"space-between"}
                marginBottom="1.5rem"
                columnGap={"1.5rem"}
                rowGap={"1rem"}
            >
                <Tabs
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabs={[t("allCoins", { ns: "common" }), t("trade.favourites")]}
                    icons={[MdMenu, AiOutlineStar]}
                    flex={1}
                />
                <Searchbar flex={1} onChange={handleSearchChange} value={search} />
            </Box>
            <Flex flexDir="column" flexGrow={1}>
                <TableContainer flexGrow={1} overflowY="auto" pos="relative" minHeight="300px">
                    <Table layout="fixed" sx={{ borderCollapse: "collapse" }} w="100%">
                        <Thead w="100%">
                            <Tr
                                sx={{
                                    "& th": {
                                        textTransform: "none",
                                    },
                                }}
                                borderBottom="1px solid #636366"
                            >
                                <Th width={{ base: undefined, md: "225px" }}>{t("trade.coinName")}</Th>
                                <ThSorting text={t("trade.marketPrice")} onClick={() => handleSortClick("PRICE")} />
                                <ThSorting
                                    display={{ base: "none", smmd: "table-cell" }}
                                    text={t("trade.change24")}
                                    onClick={() => handleSortClick("HR24PERCENT")}
                                />
                                <ThSorting
                                    display={{ base: "none", md: "table-cell" }}
                                    text={t("marketDetails.marketCap")}
                                    onClick={() => handleSortClick("MARKETCAP")}
                                />
                                <Th display={{ base: "none", md: "table-cell" }}></Th>
                            </Tr>
                        </Thead>
                        <Tbody w="100%">
                            {(() => {
                                const rows: JSX.Element[] = [] as JSX.Element[]
                                let indexCounter = 0
                                displayedSet.forEach((coin, _, set) => {
                                    const name = coin.split("::")[1]
                                    const assetDetails = dataHashmap?.current?.[name]
                                    if (!assetDetails) return

                                    rows.push(
                                        <CoinTradingRow
                                            key={`${coin}-${tabIndex == 0 ? "normal" : "favourite"}`}
                                            coin={assetDetails}
                                            handleViewMore={handleViewMore}
                                            setFavouritesData={setDisplayedFavouritesData}
                                            isFavourite={displayedFavouritesData.has(coin)}
                                            favouritesDataRef={favouritesDataRef}
                                            isLast={indexCounter === set.size - 1}
                                            coinSort={coinSort}
                                            sortData={sortData}
                                        />
                                    )
                                    indexCounter++
                                })
                                return rows
                            })()}
                        </Tbody>
                    </Table>
                    <Box
                        display="block"
                        w="full"
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%,-50%)"
                    >
                        {!dataRef.current.size ? (
                            <Text textStyle="ManropeMediumXLarge" color="grey.light.80" mt="5rem" textAlign="center">
                                {t("common:loading")}...
                            </Text>
                        ) : !displayedSet.size ? (
                            <Box textAlign={"center"} mt="5rem">
                                <Box
                                    display="inline-block"
                                    p="1rem"
                                    borderRadius={"0.5rem"}
                                    bgColor="grey.light.5"
                                    mb="0.5rem"
                                >
                                    <MdOutlineSearch size={24} color={colorConvertor("grey.light.80")} />
                                </Box>
                                <Text color="grey.light.80" textStyle="ManropeMediumXLarge">
                                    {!favouritesDataRef.current.size && tabIndex === 1
                                        ? t("marketDetails.noFavs")
                                        : t("common:noResults")}
                                </Text>
                            </Box>
                        ) : null}
                    </Box>
                </TableContainer>
                <DashboardFooter />
            </Flex>
            {selectedCoin && (
                <DrawerCoinTrade
                    initialAsset={{ ...selectedCoin }}
                    showCoinSelect
                    isOpen={isDrawerOpen}
                    toggleSideDrawerOpen={closeDrawer}
                    tab={tab}
                    setTab={setTab}
                    isOverlay
                />
            )}
            <ErrorModal
                title={dialogError?.title}
                description={dialogError?.description}
                isOpen={isModalOpen}
                onClose={closePopup}
            />
        </Box>
    )
}
