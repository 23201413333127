import { formatDollar } from "@util/stringFormatting"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { useMemo } from "react"
import OpenOrderRow from "@components/openOrders/openOrderRow"
import { formatShortDate } from "@util/dateFormating"
import { Transaction, TransactionAction } from "@redux/transactions/types"

export interface OpenOrdersCardProps {
    order: Transaction
    handleViewMore: () => void
    isLast: boolean
}

export const OpenOrdersCard: React.FC<OpenOrdersCardProps> = ({ order, handleViewMore, isLast }) => {
    const {
        created_at_pst,
        total,
        asset,
        rate: order_price,
        action,
        increase_amount,
        decrease_amount,
        increase_currency,
        decrease_currency,
    } = order || {}

    const formattedDate = formatShortDate(new Date(created_at_pst))

    const fiatValue = formatDollar(total)
    const price = formatDollar(order_price)

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const isBuy = action.toLocaleLowerCase() === TransactionAction.LimitBuy

    const quantity = isBuy ? increase_amount : decrease_amount

    const counterCurrency = isBuy ? decrease_currency : increase_currency

    const formattedCryptoAmount = formatNumberFixedPrecision(quantity, Number(data?.precision), true)

    return (
        <OpenOrderRow
            order={{
                date: formattedDate,
                description: isBuy ? `Bought ${asset} at` : `Sold ${asset} at`,
                price,
                secAmount: fiatValue,
                mainCurrency: asset,
                secCurrency: counterCurrency,
                mainAmount: formattedCryptoAmount,
            }}
            handleViewMore={handleViewMore}
            isLast={isLast}
        />
    )
}
