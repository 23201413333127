import { Box, Flex, Text } from "@chakra-ui/react"
import { CurrencySwitchFormField } from "@components/ui/formFields/currencySwitchFormField"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import { ReceiptLineItem } from "@components/ui/lineItems/receiptLineItem"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { formatStringWithCommas, removeCommasFromString } from "@util/numericalFormatting"
import Decimal from "decimal.js"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { MdInfoOutline } from "react-icons/md"

export const CryptoAmount: React.FC = () => {
    const { t } = useTranslation(["app", "common"])
    const {
        setWithdrawAmount,
        selectedAssetDetails,
        assetBalance,
        getNetWithdrawCryptoAmount,
        cryptoFees,
        errorMessage,
        setErrorMessage,
        withdrawAmount,
    } = DrawerWithdrawContext.useContainer()

    const [value, setValue] = useState({
        currencyOne: formatStringWithCommas(withdrawAmount, Number(selectedAssetDetails?.precision) ?? 8) || "",
    })

    const onTextChange = (values: { currencyOne: string; focus: "currencyOne" }) => {
        const fieldValue = values.currencyOne === "." ? "0." : values.currencyOne
        setWithdrawAmount(removeCommasFromString(fieldValue))
        setErrorMessage({ button: "", textField: "" })
        setValue({ currencyOne: fieldValue })
    }

    const withdrawWarning = selectedAssetDetails?.warnings.withdrawal

    return (
        <Box mt={7} flex={1} overflowY={"auto"}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("withdraw.crypto.title", { ns: "transfer" })}</Text>
            <Box mt={6} />
            <CurrencySwitchFormField
                label={t("withdraw.crypto.inputTitle", { ns: "transfer" })}
                mode="single"
                value={value}
                errorMessage={errorMessage.textField}
                onTextChange={onTextChange}
                currencies={[
                    {
                        symbol: selectedAssetDetails?.symbol ?? "BTC",
                        precision: selectedAssetDetails?.precision ?? "8",
                    },
                ]}
                balance={{
                    quantity: assetBalance,
                    focus: "currencyOne",
                }}
            />
            <Flex
                flexDirection={"column"}
                my={6}
                py={6}
                borderTopWidth="1px"
                borderBottomWidth={"1px"}
                borderColor="grey.light.10"
                gap={3}
            >
                <ReceiptLineItem
                    size="sm"
                    color="grey"
                    leftText={t("withdraw.crypto.availableToWithdraw", { ns: "transfer" })}
                    rightText={`${assetBalance} ${selectedAssetDetails?.symbol}`}
                />

                <ReceiptLineItem
                    size="sm"
                    color="grey"
                    leftText={t("withdraw.crypto.withdrawalFee", { ns: "transfer" })}
                    rightText={`${cryptoFees} ${selectedAssetDetails?.symbol}`}
                />
            </Flex>

            <ReceiptLineItem
                size="md"
                color="black"
                leftText={t("withdraw.crypto.youWillReceive", { ns: "transfer" })}
                rightText={`${getNetWithdrawCryptoAmount(value.currencyOne)} ${selectedAssetDetails?.symbol}`}
            />

            <Box mt={6}>
                {withdrawWarning && (
                    <InfoAlert
                        icon={{
                            icon: MdInfoOutline,
                        }}
                        variant={ENUM_INFO_ALERT_VARIANT.ORANGE}
                    >
                        {withdrawWarning}
                    </InfoAlert>
                )}
            </Box>
        </Box>
    )
}
