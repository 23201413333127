import { appendVersion } from "@util/RTKApi"

export const SLICE_NAME = "assetsDetails"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const ASSETS_DETAILS_TAG_TYPES = {
    ASSET_DETAILS: "assetsDetails",
} as const

export const API_PATH = {
    ASSETS_DETAILS: appendVersion("assets/details"),
} as const
