import { Flex, Text } from "@chakra-ui/react"
import useRegion from "@hooks/useRegion"
import {
    AddNewBankFormData,
    DrawerWithdrawContext,
} from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import AddAmericanBank from "./american"
import AddCanadianBank from "./canadian"

export type AddBankProps = {
    control: Control<AddNewBankFormData, any>
    watch: UseFormWatch<AddNewBankFormData>
    getValues: UseFormGetValues<AddNewBankFormData>
    setValues: UseFormSetValue<AddNewBankFormData>
}

export default function WithdrawWireAddBank() {
    const { t } = useTranslation("transfer")
    const { controlAddBank, watchAddBank, getAddBankFormValues, setAddBankFormValue } =
        DrawerWithdrawContext.useContainer()
    const { isCAUser } = useRegion()

    return (
        <Flex flexDir="column">
            <Flex align="center" h="56px" w="full" my="1.75rem">
                <Text textStyle="ManropeSemiboldXLarge" color="black">
                    {t("withdraw.wire.addNew")}
                </Text>
            </Flex>
            <form>
                {isCAUser ? (
                    <AddCanadianBank
                        control={controlAddBank}
                        watch={watchAddBank}
                        getValues={getAddBankFormValues}
                        setValues={setAddBankFormValue}
                    />
                ) : (
                    <AddAmericanBank
                        control={controlAddBank}
                        watch={watchAddBank}
                        getValues={getAddBankFormValues}
                        setValues={setAddBankFormValue}
                    />
                )}
            </form>
        </Flex>
    )
}
