import { useAppSelector } from "@/store/hooks"
import { Box, Flex, Text } from "@chakra-ui/react"
import { CurrencySwitchFormField } from "@components/ui/formFields/currencySwitchFormField"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import { ReceiptLineItem } from "@components/ui/lineItems/receiptLineItem"
import Link from "@components/ui/link"
import { selectAccountDetails } from "@redux/account/selectors"
import { useGetBalancesQuery } from "@redux/balances/apiSlice"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { formatDollar } from "@util/stringFormatting"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineNotifications } from "react-icons/md"
import { WithdrawType } from "../../drawerWithdraw"

export const MIN_WITHDRAW = 10
export const MAX_WITHDRAW = 25000
const LINK_HREF = "https://netcoins.zendesk.com/hc/en-us/articles/360057138492-Withdrawal-Dollars"

const InteracWithdrawRows: React.FC<{ withdrawableAmount: string }> = ({ withdrawableAmount }) => {
    const { t } = useTranslation(["transfer", "common"])
    const { isLoadingBalances } = DrawerWithdrawContext.useContainer()
    return (
        <Flex flexDirection={"column"}>
            <Flex
                flexDirection={"column"}
                gap={1}
                py="1rem"
                borderTopWidth="1px"
                borderBottomWidth="1px"
                borderColor="grey.light.10"
            >
                <ReceiptLineItem
                    color="black"
                    size="sm"
                    leftText={`${t("withdraw.interac.availWithdraw")}:`}
                    rightText={withdrawableAmount}
                    isLoadingRight={isLoadingBalances}
                />
                <ReceiptLineItem
                    color="black"
                    size="sm"
                    leftText={`${t("withdraw.interac.minWithdraw")}:`}
                    rightText={formatDollar(MIN_WITHDRAW)}
                />
            </Flex>
            <Box mt={6}>
                <InfoAlert
                    icon={{ icon: MdOutlineNotifications, color: "yellow.light.100", size: 24 }}
                    variant={ENUM_INFO_ALERT_VARIANT.ORANGE}
                    children={t("withdraw.interac.maxDisclaimer")}
                />
                <Box mt="0.75rem" ml="54px">
                    <Link text={t("learnMore", { ns: "common" })} href={LINK_HREF} size="lg" hasIcon />
                </Box>
            </Box>
        </Flex>
    )
}

const WireWithdrawRows: React.FC<{ withdrawableAmount: string }> = ({ withdrawableAmount }) => {
    const { t } = useTranslation(["transfer"])
    const { isLoadingFees, isLoadingBalances, limits } = DrawerWithdrawContext.useContainer()

    return (
        <Box py="1rem" borderTopWidth="1px" borderBottomWidth="1px" borderColor="grey.light.10">
            <ReceiptLineItem
                color="black"
                size="sm"
                leftText={`${t("withdraw.wire.availWithdraw")}:`}
                rightText={withdrawableAmount}
                isLoadingRight={isLoadingBalances}
                bottomBorder
            />

            <Flex flexDirection={"column"} gap={1} mt={4}>
                <ReceiptLineItem
                    color="black"
                    size="sm"
                    leftText={`${t("withdraw.wire.minWithdrawNoFee")}:`}
                    isLoadingRight={isLoadingFees}
                    rightText={formatDollar(limits?.wire.withdraw.fee_threshold ?? "0")}
                />
                <ReceiptLineItem
                    color="black"
                    size="sm"
                    leftText={t("withdraw.wire.fee")}
                    isLoadingRight={isLoadingFees}
                    rightText={formatDollar(limits?.wire.withdraw.fee ?? "0")}
                />
            </Flex>
        </Box>
    )
}

export default function WithdrawFiatAmount() {
    const { t } = useTranslation(["transfer", "common"])
    const { data: balances } = useGetBalancesQuery(undefined)
    const currency = useAppSelector(selectAccountDetails)?.currency ?? "CAD"
    const { withdrawAmount, setWithdrawAmount, currentWithdrawType, errorMessage, resetErrors } =
        DrawerWithdrawContext.useContainer()

    const currencyBalance = useMemo(
        () => formatDollar(parseFloat(balances?.withdrawable?.[currency] ?? "0")),
        [balances]
    )
    console.log({ currencyBalance })

    const onTextChange = (val: string) => {
        const fieldValue = val === "." ? "0." : val
        resetErrors()
        setWithdrawAmount(fieldValue)
    }

    const withdrawableAmount = useMemo(() => {
        const balance = parseFloat(balances?.withdrawable?.[currency] ?? "0")
        let amount = balance
        if (currentWithdrawType === WithdrawType.INTERAC) {
            if (balance > MAX_WITHDRAW) {
                amount = MAX_WITHDRAW
            } else {
                amount = balance
            }
        }
        return amount
    }, [balances])

    const title = useMemo(() => {
        switch (currentWithdrawType) {
            case WithdrawType.WIRE:
                return "withdraw.wire.title"
            case WithdrawType.INTERAC:
                return "withdraw.interac.title"
            default:
                return ""
        }
    }, [currentWithdrawType])

    return (
        <Flex flex={1} flexDir="column">
            <Flex align="center" h="56px" w="full" my="1.75rem">
                <Text textStyle="ManropeSemiboldXLarge" color="black">
                    {t(title)}
                </Text>
            </Flex>
            <Flex flexDir="column" rowGap="1.5rem">
                <Text textStyle="ManropeSemiboldBody" color="black">
                    {t("withdraw.fiatWithdraw.inputTitle")}
                </Text>
                <CurrencySwitchFormField
                    mode="single"
                    errorMessage={errorMessage?.textField}
                    value={{ currencyOne: withdrawAmount ?? "" }}
                    onTextChange={(val) => onTextChange(val.currencyOne)}
                    currencies={[{ symbol: currency, precision: "2", dollarSign: true }]}
                    balance={{ quantity: withdrawableAmount, focus: "currencyOne" }}
                />
                {currentWithdrawType === WithdrawType.INTERAC ? (
                    <InteracWithdrawRows withdrawableAmount={currencyBalance} />
                ) : (
                    <WireWithdrawRows withdrawableAmount={currencyBalance} />
                )}
            </Flex>
        </Flex>
    )
}
