import { Box, Checkbox as ChakraCheckbox, CheckboxProps, HStack, Text } from "@chakra-ui/react"
import Link from "../link"
import React from "react"

// Used to ensure that checkbox CSS styles are identical across slightly different implementations of the Checkbox component
export const getCheckboxStyles = () => ({
    iconColor: "blue.100",
    colorScheme: "white",
    borderColor: "grey.light.50",
    _checked: {
        borderColor: "blue.100",
    },
    _groupHover: {
        borderColor: "blue.70",
    },
    _hover: {
        borderColor: "blue.70",
    },
    _groupActive: {
        borderColor: "blue.70",
        "* svg": {
            color: "blue.70",
        },
    },
    sx: {
        ".chakra-checkbox__control": {
            margin: 0,
            padding: 0,
        },
    },
})

type Props = {
    text?: string
    children?: React.ReactNode
    defaultChecked?: boolean
    isChecked: boolean
    setValue?: (isChecked: boolean) => void
    disabled?: boolean
    isInvalid?: boolean
    link?: {
        label: string
        href: string
        hasIcon?: boolean
    }
} & CheckboxProps

// NOTE error state is not implemented in this component. There should always be a string above a CTA button somewhere else

export default function Checkbox({
    text,
    isChecked,
    setValue,
    defaultChecked,
    isInvalid,
    disabled = false,
    link,
    children,
    ...rest
}: Props) {
    const styles = getCheckboxStyles()

    const handlePress = () => {
        if (setValue) {
            setValue(!isChecked)
        }
    }

    return (
        <HStack
            spacing={"10px"}
            align="flex-start"
            flex="1"
            minW="0"
            wrap="wrap"
            onClick={handlePress}
            _hover={{
                cursor: "pointer",
            }}
            role="group"
        >
            <ChakraCheckbox
                iconSize={18}
                pointerEvents="none"
                marginTop={1}
                {...styles}
                {...rest}
                isChecked={isChecked}
            />
            <Box flex="1" minW="0">
                <HStack spacing={1} wrap="wrap">
                    {React.isValidElement(children) ? (
                        children
                    ) : (
                        <>
                            <Text textStyle="InterRegularBody">{text}</Text>
                            {link && <Link text={link.label} href={link.href} hasIcon={link.hasIcon} />}
                        </>
                    )}
                </HStack>
            </Box>
        </HStack>
    )
}
