import aave from "./aave.svg"
import ada from "./ada.svg"
import algo from "./algo.svg"
import arb from "./arb.svg"
import atom from "./atom.svg"
import avax from "./avax.svg"
import axs from "./axs.svg"
import bat from "./bat.svg"
import bnb from "./bnb.svg"
import bonk from "./bonk.svg"
import btc from "./btc.svg"
import chz from "./chz.svg"
import comp from "./comp.svg"
import crv from "./crv.svg"
import dai from "./dai.svg"
import doge from "./doge.svg"
import dot from "./dot.svg"
import dydx from "./dydx.svg"
import etc from "./etc.svg"
import eth from "./eth.svg"
import enj from "./enj.svg"
import eos from "./eos.svg"
import ftm from "./ftm.svg"
import gala from "./gala.svg"
import grt from "./grt.svg"
import hbar from "./hbar.svg"
import inj from "./inj.svg"
import knc from "./knc.svg"
import link from "./link.svg"
import lrc from "./lrc.svg"
import ltc from "./ltc.svg"
import mkr from "./mkr.svg"
import mana from "./mana.svg"
import near from "./near.svg"
import paxg from "./paxg.svg"
import qnt from "./qnt.svg"
import sand from "./sand.svg"
import shib from "./shib.svg"
import sol from "./sol.svg"
import sui from "./sui.svg"
import sushi from "./sushi.svg"
import tia from "./tia.svg"
import usdc from "./usdc.svg"
import usdt from "./usdt.svg"
import uni from "./uni.svg"
import wif from "./wif.svg"
import xlm from "./xlm.svg"
import xrp from "./xrp.svg"
import xtz from "./xtz.svg"
import yfi from "./yfi.svg"
import zrx from "./zrx.svg"
import pol from "./pol.svg"
import render from "./render.svg"
import bch from "./bch.svg"
import wld from "./wld.svg"
import ape from "./ape.svg"
import wbtc from "./wbtc.svg"
import oneInch from "./1inch.svg"
import pepe from "./pepe.svg"

export const imageMap = {
    aave,
    ada,
    algo,
    arb,
    atom,
    avax,
    axs,
    bat,
    bnb,
    bonk,
    btc,
    chz,
    comp,
    crv,
    dai,
    doge,
    dot,
    dydx,
    etc,
    eth,
    enj,
    eos,
    ftm,
    gala,
    grt,
    hbar,
    inj,
    knc,
    link,
    lrc,
    ltc,
    mkr,
    mana,
    near,
    paxg,
    qnt,
    sand,
    shib,
    sol,
    sui,
    sushi,
    tia,
    usdc,
    usdt,
    uni,
    wif,
    xlm,
    xrp,
    xtz,
    yfi,
    zrx,
    pol,
    render,
    bch,
    wld,
    ape,
    wbtc,
    pepe,
    "1inch": oneInch,
}
