import React from "react"
import { Box, Button, Text } from "@chakra-ui/react"
import TextInput from "@/components/ui/textInput"
import { useForm } from "react-hook-form"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import Checkbox from "@components/ui/checkbox/controlled"
import useFetchWrapper, { Method } from "@/hooks/useFetchWrapper"
import { SubScreenProps } from ".."
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import DetailsTypeCard from "@components/ui/badge/detailsType"
import {
    PRIVACY_POLICY_URL_CA,
    PRIVACY_POLICY_URL_US,
    TERMS_OF_SERVICE_URL_CA,
    TERMS_OF_SERVICE_URL_US,
} from "@util/config"
import { AccountType } from "@redux/account/types"

type FormData = {
    first_name: string
    last_name: string
    accept_terms: boolean
}

export default function YourName({ submitSubForm, userObj, setUserObj, isLoading }: SubScreenProps) {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            first_name: userObj?.identity.first_name,
            last_name: userObj?.identity.last_name,
        },
    })
    const { t } = useTranslation(["onboarding", "common"])
    const { fetchWrapper } = useFetchWrapper()

    function openWindowTerms() {
        if (userObj?.country === "CA") {
            window.open(TERMS_OF_SERVICE_URL_CA, "_blank")
        } else {
            window.open(TERMS_OF_SERVICE_URL_US, "_blank")
        }
    }

    function openWindowPrivacy() {
        if (userObj?.country === "CA") {
            window.open(PRIVACY_POLICY_URL_CA, "_blank")
        } else {
            window.open(PRIVACY_POLICY_URL_US, "_blank")
        }
    }

    async function onSubmit(values: FormData) {
        const payload = {
            first_name: values.first_name,
            last_name: values.last_name,
        }

        const suffix = userObj?.country === "CA" ? "ca" : "us"

        fetchWrapper("/api/v2/account/disclaimer", Method.POST, {
            content_key: `terms_of_service_en_${suffix},privacy_policy_en_${suffix}`,
        }).then(async () => {
            setUserObj((prev) =>
                prev
                    ? {
                          ...prev,
                          disclaimers: {
                              ...prev.disclaimers,
                              terms_of_service: {
                                  name: "terms_of_service",
                                  content_key: `terms_of_service_en_${suffix}`,
                                  accepted_at: new Date(Date.now()),
                              },
                              privacy_policy: {
                                  name: "privacy_policy",
                                  content_key: `privacy_policy_en_${suffix}`,
                                  accepted_at: new Date(Date.now()),
                              },
                          },
                      }
                    : prev
            )
            await submitSubForm("/api/v2/account/identity", payload, () => {
                setUserObj((prev) =>
                    prev
                        ? {
                              ...prev,
                              identity: {
                                  ...prev.identity,
                                  ...payload,
                              },
                          }
                        : prev
                )
                AmplitudeClient.logRegistrationEvent(REG_SCREENS.YourName, REGISTRATION_STEP.NAME)
            })
        })
    }

    return (
        <Box w="full">
            {userObj?.type === AccountType.BUSINESS && <DetailsTypeCard isPersonal />}
            <Text textStyle="ManropeMediumXLarge" mb="0.75rem">
                {t("yourName.header")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="1.5rem">
                {t("yourName.note")}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDir="column">
                    <TextInput
                        control={control}
                        maxLength={35}
                        name="first_name"
                        label={t("yourName.firstName")}
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            maxLength: { value: 35, message: t("yourName.errorLength") },
                        }}
                        size="lg"
                        mb="1.5rem"
                    />
                    <TextInput
                        control={control}
                        name="last_name"
                        maxLength={35}
                        label={t("yourName.lastName")}
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            maxLength: { value: 35, message: t("yourName.errorLength") },
                        }}
                        size="lg"
                        mb="1.5rem"
                    />
                    <Box w="full" display="flex" flexDir="column" alignItems="flex-start">
                        <Box
                            display="flex"
                            columnGap="0.5rem"
                            w="full"
                            alignItems="center"
                            justifyContent={"space-between"}
                        >
                            <Text textStyle="InterRegularBodySmall" mb="0.75rem">
                                {t("yourName.disclaimer")}&nbsp;
                                <Text as="span" color="blue.100" onClick={openWindowTerms} cursor="pointer">
                                    {t("terms", { ns: "common" })}&nbsp;
                                </Text>
                                <Text as="span">&&nbsp;</Text>
                                <Text as="span" color="blue.100" onClick={openWindowPrivacy} cursor="pointer">
                                    {t("privacyPolicy", { ns: "common" })}
                                </Text>
                            </Text>
                            <Checkbox
                                name="accept_terms"
                                control={control}
                                rules={{ required: t("zerohash.errorCheckbox") }}
                            />
                        </Box>
                        {errors.accept_terms && (
                            <Text textStyle="InterRegularBodySmall" color="alert.error" mt="0.5rem">
                                {t("zerohash.errorCheckbox")}
                            </Text>
                        )}
                    </Box>
                    <StandardButton
                        data-testid="continue-button"
                        type="submit"
                        variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        isLoading={isLoading}
                        mt="3rem"
                    >
                        {t("continue", { ns: "common" })}
                    </StandardButton>
                </Box>
            </form>
        </Box>
    )
}
