import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { Box, Flex, Text, useDisclosure, VStack } from "@chakra-ui/react"
import TooltipComp from "@components/tooltip"
import Checkbox from "@components/ui/checkbox"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import { RadioGenericItem, RadioGroup } from "@components/ui/radio"
import TextArea from "@components/ui/textArea"
import TextInput from "@components/ui/textInput"
import { BasicToggle } from "@components/ui/toggle/basicToggle"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MdInfoOutline, MdOutlineAdd, MdOutlineReport } from "react-icons/md"

export const CryptoWallet: React.FC = () => {
    const { t } = useTranslation(["transfer", "common"])
    const { isOpen: isSelectSaved, onToggle } = useDisclosure()

    const {
        walletForm,
        coinWallets,
        setSelectedWalletId,
        selectedWalletId,
        selectedAssetDetails,
        setShowSkipCheckAddr,
        setSkipCheckAddr,
        skipCheckAddr,
        showSkipCheckAddr,
    } = DrawerWithdrawContext.useContainer()

    const memoRegex = useMemo(() => {
        if (selectedAssetDetails?.memo.has_memo === 0 || !selectedAssetDetails?.memo.match) {
            return undefined
        }
        const memoMatch = selectedAssetDetails?.memo.match
        const regexValue = memoMatch?.slice(1, memoMatch?.length - 1)
        return new RegExp(regexValue)
    }, [selectedAssetDetails?.memo])

    useEffect(() => {
        //If any field of the form is changed, reset and hide the skip check address button
        const subscription = walletForm.watch(() => {
            if (showSkipCheckAddr || skipCheckAddr) {
                setShowSkipCheckAddr(false)
                setSkipCheckAddr(false)
            }
        })
        return () => subscription.unsubscribe()
    }, [walletForm])

    return (
        <Box mt={7} flex={1} overflowY={"auto"}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("withdraw.crypto.title")}</Text>
            <Box mt={6} />
            {coinWallets.length > 0 ? (
                <BasicToggle
                    options={[t("withdraw.crypto.savedAddresses"), t("withdraw.crypto.manualEntry")]}
                    isActive={isSelectSaved}
                    toggleActive={onToggle}
                />
            ) : (
                <Text textStyle={"ManropeSemiboldBody"}>{t("withdraw.crypto.addWalletDetails")}</Text>
            )}
            <Box mt={6}>
                {isSelectSaved ? (
                    <VStack flex={1}>
                        <RadioGroup
                            width={"100%"}
                            options={coinWallets.map((wallet) => ({
                                value: wallet.id,
                                label: wallet.address,
                                desc: wallet.label,
                            }))}
                            name="crypto"
                            onChangeOption={(walletId) => setSelectedWalletId(walletId)}
                            currentValue={selectedWalletId ?? 0}
                            isHorizontal={false}
                        />

                        <RadioGenericItem
                            option={{ value: 0, label: t("withdraw.crypto.addNewWallet") }}
                            hideOverflow={false}
                            isChecked={false}
                            onClick={() => onToggle()}
                            icon={MdOutlineAdd}
                        />
                    </VStack>
                ) : (
                    <Flex flexDirection={"column"} gap={6}>
                        <TextInput
                            key="address"
                            name="address"
                            control={walletForm.control}
                            label={t("withdraw.crypto.enterWalletAddress")}
                            placeholder={"Enter your wallet address"}
                            rules={{ required: t("error.required", { ns: "common" }) }}
                        />

                        {showSkipCheckAddr && (
                            <>
                                <InfoAlert
                                    icon={{
                                        icon: MdOutlineReport,
                                    }}
                                    variant={ENUM_INFO_ALERT_VARIANT.RED}
                                >
                                    {t("withdraw.crypto.unableVerifyAddress")}
                                </InfoAlert>
                                <Checkbox
                                    text={t("withdraw.crypto.confirmAddress")}
                                    name="skipAddr"
                                    isChecked={skipCheckAddr}
                                    setValue={(isChecked) => setSkipCheckAddr(isChecked)}
                                />
                            </>
                        )}

                        <TextInput
                            key="label"
                            name="label"
                            control={walletForm.control}
                            label={t("withdraw.crypto.newWalletLabel")}
                            placeholder={t("withdraw.crypto.walletLabelPlaceholder")}
                            rules={{ required: t("error.required", { ns: "common" }) }}
                        />
                    </Flex>
                )}
            </Box>
            {selectedAssetDetails?.memo.has_memo === 1 && memoRegex ? (
                <TextInput
                    mt={6}
                    key="memo"
                    name="memo"
                    control={walletForm.control}
                    label={selectedAssetDetails.memo.label}
                    placeholder={t("withdraw.crypto.memoPlaceholder")}
                    rules={{
                        pattern: {
                            value: memoRegex,
                            message: t("withdraw.errors.invalidMemo"),
                        },
                    }}
                    labelElement={
                        <TooltipComp
                            triggerComponent={<ReactIcon icon={MdInfoOutline} color="blue.100" size={14} />}
                            bodyText={t("withdraw.crypto.memoTooltip")}
                        />
                    }
                />
            ) : null}

            <TextArea
                maxLength={175}
                mt={6}
                key="note"
                name="note"
                currentLength={walletForm.watch("note")?.length ?? 0}
                control={walletForm.control}
                label={t("withdraw.crypto.withdrawNote")}
                placeholder={t("withdraw.crypto.withdrawNotePlaceholder")}
                rules={{ maxLength: { value: 175, message: t("error.maxLength", { ns: "common" }) } }}
            />

            <Box mt={6}>
                <InfoAlert
                    icon={{
                        icon: MdInfoOutline,
                    }}
                    variant={ENUM_INFO_ALERT_VARIANT.ORANGE}
                >
                    {t("withdraw.crypto.warningIrreversible", { asset: selectedAssetDetails?.symbol.toUpperCase() })}
                </InfoAlert>
            </Box>
        </Box>
    )
}
