import CheckCircle from "@assets/svgs/common/check-circle"
import { Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

type Props = {}

export default function WithdrawInteracCompleted({}: Props) {
    const { t } = useTranslation("transfer")

    return (
        <Flex flex={1} flexDir="column">
            <Flex align="center" justifyContent={"space-between"} h="56px" w="full" my="1.75rem">
                <Text textStyle="ManropeSemiboldXLarge" color="black">
                    {t("withdraw.interac.submittedTitle")}
                </Text>
                <CheckCircle />
            </Flex>
            <Text textStyle="ManropeSemiboldBody" mb="1.5rem">
                {t("withdraw.interac.submittedMsgTop")}
            </Text>
            <Text textStyle="InterRegularBody">{t("withdraw.interac.submittedMsgBtm")}</Text>
        </Flex>
    )
}
