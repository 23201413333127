import { Badge, Box, Flex, Text } from "@chakra-ui/react"
import { useMemo } from "react"
import CHIP_VARIANTS, { BADGE_HEIGHT, ENUM_CHIP_VARIANTS } from "./types"
import { IconType } from "react-icons/lib"

export interface ChipProps {
    text: string
    variant: ENUM_CHIP_VARIANTS
    size?: "sm" | "lg"
    leftIcon?: IconType
    rightIcon?: IconType
}

export type ChipOptionalVariant = Omit<ChipProps, "variant"> & {
    variant?: ENUM_CHIP_VARIANTS
}

export default function Chip({ text, variant, size = "sm", leftIcon: LeftIcon, rightIcon: RightIcon }: ChipProps) {
    const palette = useMemo(() => CHIP_VARIANTS[variant], [variant])

    const { normal } = palette

    const pr = useMemo(() => {
        return RightIcon ? 1.5 : 3
    }, [])

    const pl = useMemo(() => {
        return LeftIcon ? 1.5 : 3
    }, [])

    return (
        <Badge
            display="flex"
            alignItems="center"
            bgColor={normal.bg}
            borderRadius={6}
            w="fit-content"
            pr={pr}
            pl={pl}
            h={`${BADGE_HEIGHT[size] + (!!LeftIcon || !!RightIcon ? 3 : 0)}px`}
        >
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                {LeftIcon && (
                    <Flex justifyContent={"center"} align="center" mr={1.5} h="18px" w="18px">
                        <LeftIcon color={normal.color} size={12} />
                    </Flex>
                )}
                <Text
                    color={normal.color}
                    textStyle={size === "lg" ? "ManropeSemiboldBodySmall" : "ManropeSemiboldNano"}
                    textTransform={"none"}
                >
                    {text}
                </Text>
                {RightIcon && (
                    <Flex justifyContent={"center"} align="center" ml={1.5} h="18px" w="18px">
                        <RightIcon color={normal.color} size={12} />
                    </Flex>
                )}
            </Box>
        </Badge>
    )
}
