import { Checkbox, Flex, Text, CheckboxProps, Box } from "@chakra-ui/react"
import { getCheckboxStyles } from "@components/ui/checkbox"

interface Option<T extends number | string> {
    label: string
    value: T
    desc?: string
}

interface OptionCheckBoxProps<T extends number | string> extends CheckboxProps {
    option: Option<T>
    isChecked: boolean
    onToggle: (option: Option<T>) => void
}

const OptionCheckBox = <T extends number | string>({
    option,
    isChecked,
    onToggle,
    ...props
}: OptionCheckBoxProps<T>) => {
    const checkboxStyles = getCheckboxStyles()

    return (
        <Checkbox
            isChecked={isChecked}
            onChange={() => onToggle(option)}
            role="group"
            {...checkboxStyles}
            align={"center"}
            _hover={{
                ...checkboxStyles._hover,
                bgColor: "blue.100",
                "* svg": {
                    color: "blue.70",
                },
            }}
            sx={{
                ...checkboxStyles.sx,
                px: "1rem",
                py: 2,
                ".chakra-checkbox__control": {
                    marginTop: 0,
                },
                m: "2px",
            }}
            {...props}
        >
            <Flex height={"20px"} align="center" width="100%">
                <Text _groupHover={{ color: "white" }} flexShrink={0} mr={1} textStyle="ManropeMediumBodySmall">
                    {option.label}
                </Text>
                {option.desc && (
                    <Text
                        fontSize="sm"
                        color="grey.light.50"
                        _groupHover={{ color: "white" }}
                        flexShrink={0}
                        textStyle="ManropeMediumXSmall"
                    >
                        {option.desc}
                    </Text>
                )}
            </Flex>
        </Checkbox>
    )
}

export default OptionCheckBox
