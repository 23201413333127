import { Box, Text, Flex, VStack, useBreakpointValue, Skeleton, SkeletonText } from "@chakra-ui/react"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import Link from "@components/ui/link"
import useSupport from "@hooks/useSupport"
import { useGetCAWireDepositDetailsQuery } from "@redux/fund/apiSlice"
import { useTranslation } from "react-i18next"
import { MdOutlineMailOutline, MdOutlineReport } from "react-icons/md"

export const CAWireInstructions: React.FC = () => {
    const { t } = useTranslation(["app", "common"])

    const { data: fundBankDetails, isLoading } = useGetCAWireDepositDetailsQuery(undefined)

    const { beneficiary, bank, institution_number, transit_number, account_number, swift, memo } =
        fundBankDetails?.ca || {}

    const { supportEmail } = useSupport()

    const isMini = useBreakpointValue({ base: true, sm: false })

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("fund.fundWithWire", { ns: "transfer" })}</Text>
            <Text textStyle={"ManropeSemiboldBody"} mt={7}>
                {t("fund.useFollowingInfoTransfer", { ns: "transfer" })}
            </Text>
            <Text textStyle={"InterRegularBody"} mt={2}>
                {t("fund.currencyFundsOnly", { ns: "transfer", currency: "CAD" })}
            </Text>
            <Flex my={6} flexDirection={isMini ? "column" : "row"}>
                <SkeletonText
                    isLoaded={!isLoading}
                    padding={4}
                    borderWidth={1}
                    borderRadius={"md"}
                    borderColor={"grey.light.10"}
                    display={"flex"}
                    flex={1}
                    noOfLines={4}
                    spacing={2}
                    skeletonHeight={"16px"}
                    flexDirection={"column"}
                    mr={isMini ? 0 : 3}
                >
                    <Text textStyle={"ManropeSemiboldBody"}>{t("fund.beneficiaryBank", { ns: "transfer" })} :</Text>
                    <Text textStyle={"InterRegularBody"}>{bank}</Text>
                </SkeletonText>
                <SkeletonText
                    isLoaded={!isLoading}
                    padding={4}
                    borderWidth={1}
                    borderRadius={"md"}
                    borderColor={"grey.light.10"}
                    display={"flex"}
                    flex={1}
                    noOfLines={4}
                    spacing={2}
                    skeletonHeight={"16px"}
                    flexDirection={"column"}
                    mr={isMini ? 0 : 3}
                >
                    <Text textStyle={"ManropeSemiboldBody"} mb={2}>
                        {t("fund.beneficiary", { ns: "transfer" })} :
                    </Text>
                    <Text textStyle={"InterRegularBody"}>{beneficiary}</Text>
                </SkeletonText>
            </Flex>
            <Box pb={4} borderBottomWidth={1} borderBottomColor={"light.grey.10"}>
                <Flex justifyContent={"space-between"}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("fund.institutionNo", { ns: "transfer" })} :</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{institution_number}</Text>
                </Flex>
                <Flex justifyContent={"space-between"} mt={2}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("fund.transitNo", { ns: "transfer" })} :</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{transit_number}</Text>
                </Flex>
                <Flex justifyContent={"space-between"} mt={2}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("fund.accountNo", { ns: "transfer" })} :</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{account_number}</Text>
                </Flex>
            </Box>
            <Box my={4}>
                <Flex justifyContent={"space-between"}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("fund.swiftBic", { ns: "transfer" })} :</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{swift}</Text>
                </Flex>
                <Flex justifyContent={"space-between"} mt={2}>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{t("fund.wireMemo", { ns: "transfer" })} :</Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"}>{memo}</Text>
                </Flex>
            </Box>
            <VStack mt={6} spacing={3}>
                <InfoAlert
                    variant={ENUM_INFO_ALERT_VARIANT.BLUE}
                    icon={{
                        icon: MdOutlineMailOutline,
                    }}
                >
                    <Text textStyle="InterRegularBodySmall">
                        {t("fund.uponCompletion", { ns: "transfer" })}{" "}
                        <Link
                            href={`mailto:${supportEmail}`}
                            isExternal
                            embeddedLink
                            textStyle="InterRegularBodySmall"
                            text={supportEmail}
                        />
                    </Text>
                </InfoAlert>
                <InfoAlert
                    variant={ENUM_INFO_ALERT_VARIANT.RED}
                    icon={{
                        icon: MdOutlineReport,
                    }}
                >
                    {t("fund.noRefunds", { ns: "transfer" })}
                </InfoAlert>
            </VStack>
        </Box>
    )
}
