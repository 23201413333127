export enum ENUM_UNIVERSAL_MODAL_TYPES {
    EVALUATION_STATE = "EVALUATION_STATE",
    UNDER_REVIEW = "UNDER_REVIEW",
    VIEW_ONLY = "VIEW_ONLY",
}

export type UniversalModalProps = {
    isOpen: boolean
    onClose: () => void
    cancelRef: React.RefObject<HTMLButtonElement>
}
