import { Tr, Td, Text, Menu, MenuButton, MenuList, MenuItem, Button, Box, useBreakpointValue } from "@chakra-ui/react"
import { memo, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FiMoreVertical } from "react-icons/fi"

export interface OpenOrdersRowProps {
    order: {
        date: string
        description: string
        price?: string
        secAmount?: string
        mainCurrency?: string
        secCurrency?: string
        mainAmount: string
    }
    handleViewMore: () => void
    isLast: boolean
}

const OpenOrdersRow: React.FC<OpenOrdersRowProps> = ({ order, handleViewMore, isLast }) => {
    const { t } = useTranslation("common")

    const borderBottom = useMemo(() => {
        return isLast ? "none !important" : "1px solid"
    }, [isLast])

    const rowVariant = useBreakpointValue({
        base: "extra-small",
        sm: "small",
        md: "medium",
        lg: "large",
    })

    const renderAmountsCol = useCallback(
        () => (
            <>
                <Box display="inline-block" verticalAlign="middle">
                    {order.mainAmount && (
                        <Text textStyle={"ManropeSemiboldBodySmall"}>
                            {order.mainAmount} {order.mainCurrency}
                        </Text>
                    )}
                    {order.secAmount && (
                        <Text textStyle={"ManropeMediumXSmall"} color="grey.dark.60">
                            {order.secAmount} {order.secCurrency}
                        </Text>
                    )}
                </Box>
                <Box display="inline-block" verticalAlign="middle" ml={2}>
                    <Menu>
                        <MenuButton
                            as={Button}
                            variant="ghost"
                            iconSpacing={0}
                            rightIcon={<FiMoreVertical size={15} />}
                            p={11}
                        />
                        <MenuList padding={0} borderRadius={8}>
                            <MenuItem onClick={handleViewMore} borderRadius={8}>
                                {t("viewMore")}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            </>
        ),
        [order.mainAmount, order.mainCurrency, order.secAmount, order.secCurrency, handleViewMore]
    )

    const renderLargeRow = useCallback(
        () => (
            <Tr
                w="full"
                cursor="pointer"
                _hover={{
                    bg: "grey.dark.10",
                }}
            >
                <Td
                    py={{ base: "1rem" }}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                    display={{ base: "none", md: "table-cell", lg: "table-cell" }}
                >
                    <Text color="grey.dark.60" textStyle={"ManropeSemiboldXSmall"}>
                        {order.date}
                    </Text>
                </Td>
                <Td py={{ base: "1rem" }} borderBottom={borderBottom} borderColor={"grey.dark.20"}>
                    <Box>
                        <Box display="inline-block" w={{ base: "70%" }}>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{order.description}</Text>
                        </Box>
                    </Box>
                </Td>
                <Td py={{ base: "1rem" }} borderBottom={borderBottom} borderColor={"grey.dark.20"}>
                    <Box>
                        <Box display="inline-block" w={{ base: "70%" }}>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{order.price}</Text>
                        </Box>
                    </Box>
                </Td>
                <Td
                    py={{ base: "1rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [order.date, order.description, order.price, renderAmountsCol, borderBottom]
    )

    const renderMediumRow = useCallback(
        () => (
            <Tr
                w="full"
                cursor="pointer"
                _hover={{
                    bg: "grey.dark.10",
                }}
            >
                <Td
                    py={{ base: "1rem" }}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                    display={{ base: "none", md: "table-cell", lg: "table-cell" }}
                >
                    <Text color="grey.dark.60" textStyle={"ManropeSemiboldXSmall"}>
                        {order.date}
                    </Text>
                </Td>
                <Td py={{ base: "1rem" }} borderBottom={borderBottom} borderColor={"grey.dark.20"}>
                    <Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{order.description}</Text>
                        </Box>
                    </Box>
                </Td>
                <Td py={{ base: "1rem" }} borderBottom={borderBottom} borderColor={"grey.dark.20"}>
                    <Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{order.price}</Text>
                        </Box>
                    </Box>
                </Td>
                <Td
                    py={{ base: "1rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [order.date, order.description, order.price, renderAmountsCol, borderBottom]
    )

    const renderSmallRow = useCallback(
        () => (
            <Tr
                w="full"
                cursor="pointer"
                _hover={{
                    bg: "grey.dark.10",
                }}
            >
                <Td
                    py={{ base: "0.5rem" }}
                    px={{ base: "0rem" }}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                    whiteSpace={"normal"}
                >
                    <Box>
                        <Box>
                            <Text color="grey.dark.60" textStyle={"ManropeSemiboldXSmall"}>
                                {order.date}
                            </Text>
                        </Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{order.description}</Text>
                        </Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{order.price}</Text>
                        </Box>
                    </Box>
                </Td>
                <Td
                    py={{ base: "0.5rem" }}
                    px={{ base: "0rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [order.date, order.description, order.price, renderAmountsCol, borderBottom]
    )

    const renderExtraSmallRow = useCallback(
        () => (
            <Tr
                w="full"
                cursor="pointer"
                _hover={{
                    bg: "grey.dark.10",
                }}
            >
                <Td
                    py={{ base: "0.25rem" }}
                    px={{ base: "0rem" }}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                    whiteSpace={"normal"}
                >
                    <Box>
                        <Box>
                            <Text color="grey.dark.60" textStyle={"ManropeSemiboldXSmall"}>
                                {order.date}
                            </Text>
                        </Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{order.description}</Text>
                        </Box>
                        <Box>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>{order.price}</Text>
                        </Box>
                    </Box>
                </Td>
                <Td
                    py={{ base: "0.25rem" }}
                    px={{ base: "0rem" }}
                    textAlign="right"
                    pr={0}
                    borderBottom={borderBottom}
                    borderColor={"grey.dark.20"}
                >
                    {renderAmountsCol()}
                </Td>
            </Tr>
        ),
        [order.date, order.description, order.price, renderAmountsCol, borderBottom]
    )

    if (rowVariant === "extra-small") {
        return renderExtraSmallRow()
    } else if (rowVariant === "small") {
        return renderSmallRow()
    } else if (rowVariant === "medium") {
        return renderMediumRow()
    } else if (rowVariant === "large") {
        return renderLargeRow()
    }

    return null
}

export default memo(OpenOrdersRow)
