export const TextStyles = {
    InterBoldBody: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
    },
    InterBoldBodySmall: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
    },
    InterBoldXSmall: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
    },
    InterSemiboldXSmall: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
    },
    InterSemiboldBodySmall: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.15px",
    },
    InterSemiboldBody: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
    },
    InterRegularNano: {
        fontFamily: "Inter",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
    },
    InterRegularBody: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
    },
    InterRegularBodySmall: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
    },
    InterRegularXSmall: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
    },
    ManropeRegular7xLarge: {
        fontFamily: "Manrope",
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "120%",
        letterSpacing: "0.5px",
    },
    ManropeRegular6xLarge: {
        fontFamily: "Manrope",
        fontSize: "48px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "130%",
        letterSpacing: "0.25px",
    },
    ManropeRegularLarge: {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.25px",
    },
    ManropeRegularBody: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.25px",
    },
    ManropeRegularBodySmall: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
        letterSpacing: "0.25px",
    },
    ManropeMedium5xLarge: {
        fontFamily: "Manrope",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeMedium4xLarge: {
        fontFamily: "Manrope",
        fontSize: "36px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
    },
    ManropeMedium3xLarge: {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
    },
    ManropeMediumXLarge: {
        fontFamily: "Manrope",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.15px",
    },
    ManropeMediumLarge: {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.15px",
    },
    ManropeMediumBody: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeMediumBodySmall: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.25px",
    },
    ManropeMediumXSmall: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeSemibold2xLarge: {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeSemiboldXLarge: {
        fontFamily: "Manrope",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeSemiboldLarge: {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeSemiboldBody: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeSemiboldBodySmall: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeSemiboldXSmall: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeSemiboldNano: {
        fontFamily: "Manrope",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeBold7xLarge: {
        fontFamily: "Manrope",
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "120%",
        letterSpacing: "0.5px",
    },
    ManropeBoldBody: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
    ManropeBoldBodySmall: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
        letterSpacing: "0.5px",
    },
}
