import { ROUTES } from "@/routing/routes"
import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails, selectIsAccountEvaluationState } from "@redux/account/selectors"
import { AccountType, TUserState } from "@redux/account/types"
import { useUniversalModal } from "@screens/modals/modalContext"
import { ENUM_UNIVERSAL_MODAL_TYPES } from "@screens/modals/types"
import { NavigateFunction, useNavigate } from "react-router-dom"

export enum RESTRICTED_FLOWS {
    WITHDRAW = "withdraw",
    DEPOSIT = "deposit",
    TRADE = "trade",
    UNSTAKE = "unstake",
    STAKE_MORE = "stakeMore",
    CHANGE_PASSWORD = "changePassword",
    SAVED_ADDRESSES = "savedAddresses",
    AUTHORIZED_DEVICES = "authorizedDevices",
    DASHBOARD_ITEMS = "dashboardItems",
    CLOSE_ACCOUNT = "closeAccount",
    VIEW_NC_PAY_CARD = "viewNCPayCard",
    ADD_NC_PAY_CARD = "addNCPayCard",
    TFA_CHANGES = "tfaChanges",
}

type RestrictedFlowsMapping = {
    [key in TUserState]: RESTRICTED_FLOWS[]
}

type RestrictedRoutesMapping = {
    [K in keyof typeof ROUTES]: RESTRICTED_FLOWS[]
}

type RestrictFlowOptions = {
    returnBoolean?: boolean
    skipRegRestrictions?: boolean
    skipUserStateCheck?: boolean
    blockWithModal?: boolean
}

const BLOCKED_ROUTES_FLOWS: RestrictedRoutesMapping = {
    //TODO: Fill these in with the appropriate RESTRICTED_FLOWS
    DASHBOARD: [],
    LANDING: [],
    TWO_FA: [],
    ONBOARDING: [],
    DASHBOARD_TRADE: [],
    DASHBOARD_TRANSFER: [],
    DASHBOARD_NETCOINS_PAY: [],
    DASHBOARD_SETTINGS: [],
    MARKET_DETAILS: [],
    ASSET_TRANSACTIONS: [],
    ASSET_OPEN_ORDERS: [],
    PROFILE: [],
    NOTIFICATIONS: [],
    DASHBOARD_FAQ: [],
    ACCOUNT_ACTIVITY: [],
    AUTHORIZED_DEVICES: [],
    SAVED_ADDRESSES_CRYPTO: [],
    SAVED_ADDRESSES_FIAT: [],
    DOCUMENTS: [],
    BUY_WITH_CREDIT: [],
}

const BLOCKED_STATUS_FLOWS: RestrictedFlowsMapping = {
    [TUserState.PENDING_BUSINESS_REVIEW]: [
        RESTRICTED_FLOWS.WITHDRAW,
        RESTRICTED_FLOWS.DEPOSIT,
        RESTRICTED_FLOWS.TRADE,
        RESTRICTED_FLOWS.UNSTAKE,
        RESTRICTED_FLOWS.STAKE_MORE,
        RESTRICTED_FLOWS.DASHBOARD_ITEMS,
        RESTRICTED_FLOWS.VIEW_NC_PAY_CARD,
        RESTRICTED_FLOWS.ADD_NC_PAY_CARD,
    ],
    [TUserState.PENDING_COMPLIANCE_REVIEW]: [
        RESTRICTED_FLOWS.WITHDRAW,
        RESTRICTED_FLOWS.DEPOSIT,
        RESTRICTED_FLOWS.TRADE,
        RESTRICTED_FLOWS.UNSTAKE,
        RESTRICTED_FLOWS.STAKE_MORE,
        RESTRICTED_FLOWS.DASHBOARD_ITEMS,
        RESTRICTED_FLOWS.VIEW_NC_PAY_CARD,
        RESTRICTED_FLOWS.ADD_NC_PAY_CARD,
    ],
    [TUserState.PRE_APPROVED]: [
        RESTRICTED_FLOWS.WITHDRAW,
        RESTRICTED_FLOWS.DEPOSIT,
        RESTRICTED_FLOWS.TRADE,
        RESTRICTED_FLOWS.UNSTAKE,
        RESTRICTED_FLOWS.STAKE_MORE,
        RESTRICTED_FLOWS.DASHBOARD_ITEMS,
        RESTRICTED_FLOWS.VIEW_NC_PAY_CARD,
        RESTRICTED_FLOWS.ADD_NC_PAY_CARD,
    ],
    [TUserState.UNDER_REVIEW]: [
        RESTRICTED_FLOWS.WITHDRAW,
        RESTRICTED_FLOWS.CLOSE_ACCOUNT,
        RESTRICTED_FLOWS.TFA_CHANGES,
        RESTRICTED_FLOWS.SAVED_ADDRESSES,
        RESTRICTED_FLOWS.AUTHORIZED_DEVICES,
        RESTRICTED_FLOWS.CHANGE_PASSWORD,
    ],
    [TUserState.VIEW_ONLY]: [
        RESTRICTED_FLOWS.WITHDRAW,
        RESTRICTED_FLOWS.DEPOSIT,
        RESTRICTED_FLOWS.TRADE,
        RESTRICTED_FLOWS.UNSTAKE,
        RESTRICTED_FLOWS.STAKE_MORE,
        RESTRICTED_FLOWS.CHANGE_PASSWORD,
        RESTRICTED_FLOWS.SAVED_ADDRESSES,
        RESTRICTED_FLOWS.AUTHORIZED_DEVICES,
        RESTRICTED_FLOWS.DASHBOARD_ITEMS,
        RESTRICTED_FLOWS.CLOSE_ACCOUNT,
        RESTRICTED_FLOWS.VIEW_NC_PAY_CARD,
        RESTRICTED_FLOWS.ADD_NC_PAY_CARD,
        RESTRICTED_FLOWS.TFA_CHANGES,
    ],
    [TUserState.REJECTED]: [],
    [TUserState.CLOSED]: [],
    [TUserState.OPEN]: [],
    [TUserState.PENDING_EVALUATION]: [],
    [TUserState.APPROVED]: [],
}

const useRestrictedNavigation = () => {
    const accountDetails = useAppSelector(selectAccountDetails)
    const isEvaluationState = useAppSelector(selectIsAccountEvaluationState)
    const verificationDetails = accountDetails?.verification
    const userState = accountDetails?.userState
    const isBusinessUser = accountDetails?.type == AccountType.BUSINESS
    const isCAUser = accountDetails?.country === "CA"

    const navigateReactRouter = useNavigate()
    const { openModal } = useUniversalModal()

    const userStateNavigation = (blockWithModal = false) => {
        if (isEvaluationState) {
            if (blockWithModal) {
                openModal(ENUM_UNIVERSAL_MODAL_TYPES.EVALUATION_STATE)
            }
            // TODO: Redirect to registration when built
            // return navigation.navigate(MainScreens.RegisterStack, {
            //   screen: RegisterScreens.PendingEvaluation,
            // });
        }

        if (userState === TUserState.UNDER_REVIEW) {
            if (blockWithModal) {
                openModal(ENUM_UNIVERSAL_MODAL_TYPES.UNDER_REVIEW)
            }
            // TODO: Redirect to registration when built
            // return navigation.navigate(MainScreens.RegisterStack, {
            //   screen: RegisterScreens.AccountUnderReview,
            // });
        }

        if (userState === TUserState.VIEW_ONLY) {
            if (blockWithModal) {
                openModal(ENUM_UNIVERSAL_MODAL_TYPES.VIEW_ONLY)
            }
            // TODO: Redirect to registration when built
            // return navigation.navigate(MainScreens.RegisterStack, {
            //   screen: RegisterScreens.AccountRestricted,
            // });
        }
    }

    const evaluateRestriction = (
        flow: RESTRICTED_FLOWS,
        callBack?: Function,
        options?: RestrictFlowOptions
    ): boolean | void => {
        if (!userState) {
            return true
        }

        // Check if the flow is blocked for the current user state
        if (!options?.skipUserStateCheck && BLOCKED_STATUS_FLOWS[userState]?.includes(flow)) {
            if (options?.returnBoolean) {
                return true
            }
            return userStateNavigation(options?.blockWithModal)
        }

        // Check if the user is verified
        if (!options?.skipRegRestrictions && !verificationDetails?.verified) {
            if (options?.returnBoolean) {
                return true
            }
            //TODO: Redirect to registration when built
            // return navigation.navigate(MainScreens.RegisterStack, {
            //   screen: RegisterScreens.RegisterContinue,
            // });
        }

        // Check if the user is either a business user or a CA user, and if their status is not completed
        if (!options?.skipRegRestrictions && !isBusinessUser && !isCAUser && !!verificationDetails) {
            if (options?.returnBoolean) {
                return true
            }
            //TODO: Redirect to registration when built
            // return navigation.navigate(MainScreens.RegisterStack, {
            //   screen: RegisterScreens.RegisterContinue,
            // });
        }

        // If there's a callback, execute it
        if (callBack) {
            if (options?.returnBoolean) {
                return false
            }
            return callBack()
        }

        // Default return for boolean mode
        return false
    }

    const parseRouteKey = (key: string): keyof typeof ROUTES | undefined => {
        if (key in ROUTES) {
            return key as keyof typeof ROUTES
        }
        return undefined
    }

    const navigate: NavigateFunction = (params) => {
        if (typeof params === "number") {
            return navigateReactRouter(params)
        }
        if (typeof params === "string") {
            const routeKey = parseRouteKey(params)
            if (!routeKey) {
                return navigateReactRouter(params)
            }
            return evaluateRestriction(BLOCKED_ROUTES_FLOWS[routeKey][0], () => navigateReactRouter(params))
        }
        const { pathname } = params
        if (!pathname) {
            return
        }
        const routeKey = parseRouteKey(pathname)
        if (!routeKey) {
            return navigateReactRouter(params)
        }
        return evaluateRestriction(BLOCKED_ROUTES_FLOWS[routeKey][0], () => navigateReactRouter(params))
    }

    return {
        userState,
        isEvaluationState,
        evaluateRestriction,
        navigate,
    }
}

export default useRestrictedNavigation
