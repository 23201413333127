import { appendVersion } from "@util/RTKApi"

export const SLICE_NAME = "stake"

export const STAKES_TAG_TYPES = {
    STAKING_INFO: "stakingInfo",
    STAKES: "stakes",
} as const

export const API_PATH = {
    STAKES: appendVersion("stake"),
} as const
