import { Box, Text, VStack } from "@chakra-ui/react"
import CardCheckbox from "@components/ui/checkbox/cardCheckbox"
import useRegion from "@hooks/useRegion"
import { useTranslation } from "react-i18next"
import { WithdrawType } from "../drawerWithdraw"
import { DrawerWithdrawContext } from "../drawerWithdraw/DrawerWithdrawContext"

export default function Withdraw() {
    const { t } = useTranslation(["transfer", "common", "app"])

    const { currentWithdrawType, setCurrentWithdrawType, resetErrors } = DrawerWithdrawContext.useContainer()

    const { isCAUser } = useRegion()

    const updateWithdrawType = (withdrawType: WithdrawType) => {
        setCurrentWithdrawType(withdrawType)
        resetErrors()
    }

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("withdraw.withdrawMethod")}</Text>
            <Box pt={7}>
                <Text textStyle={"ManropeSemiboldBody"}>{t("dollars", { ns: "common" })}</Text>
                <Text textStyle={"InterRegularBodySmall"}>{t("withdraw.withdrawAccountWithDollars")}</Text>
            </Box>
            <VStack spacing={3} mt={3}>
                {isCAUser && (
                    <CardCheckbox
                        checked={currentWithdrawType === WithdrawType.INTERAC}
                        label={t("withdraw.interacCard.title")}
                        primaryText={t("withdraw.interacCard.description")}
                        chip={{
                            text: "Recommended",
                        }}
                        onPress={() => updateWithdrawType(WithdrawType.INTERAC)}
                    />
                )}
                <CardCheckbox
                    checked={currentWithdrawType === WithdrawType.WIRE}
                    label={t("withdraw.wireCard.title")}
                    primaryText={isCAUser ? t("withdraw.wireCard.description") : t("withdraw.wireCard.usDescription")}
                    onPress={() => updateWithdrawType(WithdrawType.WIRE)}
                />
            </VStack>
            <Box mt={6}>
                <Text textStyle={"ManropeSemiboldBody"}>{t("crypto", { ns: "common" })}</Text>
                <Text textStyle={"InterRegularBodySmall"}>{t("withdraw.withdrawAccountWithCrypto")}</Text>
                <Box mt={3}>
                    <CardCheckbox
                        checked={currentWithdrawType === WithdrawType.CRYPTO}
                        label={t("withdraw.cryptoCard.title")}
                        primaryText={isCAUser ? t("transfers.minimumsApply", { ns: "app" }) : t("transfers.noFees", { ns: "app" })}
                        onPress={() => updateWithdrawType(WithdrawType.CRYPTO)}
                    />
                </Box>
            </Box>
            <Text textStyle={"InterRegularBodySmall"} color={"grey.light.80"} mt={6}>
                {" "}
                {t("withdraw.withdrawDisclaimer")}{" "}
            </Text>
        </Box>
    )
}
