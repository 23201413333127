import common from "./en/common.json"
import login from "./en/login"
import onboarding from "./en/onboarding"
import legal from "./en/legal"
import app from "./en/app"
import transactions from "./en/transactions"
import orders from "./en/orders"
import transfer from "./en/transfer"
import twoFA from "./en/twoFA"
import assets from "./en/assets"
import settings from "./en/settings"

const resources = {
    en: {
        app,
        assets,
        common,
        legal,
        login,
        onboarding,
        transactions,
        orders,
        transfer,
        twoFA,
        settings,
    },
} as const

export default resources
