import { useDisclosure } from "@chakra-ui/react"
import { useGetTFAKeyQuery, useSaveTFAKeyMutation } from "@redux/account/apiSlice"
import { createContainer } from "@util/UnstatedContext"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const useTwoFASetupContext = () => {
    const [view, setView] = useState<number>(0)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [accept2FARisk, setAccept2FARisk] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorDialog, setErrorDialog] = useState<string>("")
    const [saveTFAKey, saveTFAKeyData] = useSaveTFAKeyMutation()

    const { isOpen: isErrModalOpen, onOpen: onErrModalOpen, onClose: onErrModalClose } = useDisclosure()

    const { t } = useTranslation(["app", "common"])

    const { data: keyData } = useGetTFAKeyQuery()
    const { secret, qr } = keyData || {}
    const [tfaCode, setTFACode] = useState("")

    const onErrorDialogClose = () => {
        setErrorDialog("")
        onErrModalClose()
    }

    const handleCodeVerification = async () => {
        if (!tfaCode || tfaCode.length < 6) {
            setErrorMessage(t("twoFASetup.enterValidTfa", { ns: "twoFA" }))
            return
        }

        setLoading(true)

        try {
            await saveTFAKey({ one_time_password: tfaCode, google2fa_secret_key: secret || "" }).unwrap()
        } catch (error) {
            setErrorDialog(t("common:error.message"))
            onErrModalOpen()
        } finally {
            setLoading(false)
        }
    }

    return {
        view,
        setView,

        errorMessage,
        setErrorMessage,

        accept2FARisk,
        setAccept2FARisk,

        qr,
        secret,

        tfaCode,
        setTFACode,

        handleCodeVerification,

        loading,
        setLoading,

        isErrModalOpen,
        onErrModalOpen,
        onErrorDialogClose,

        errorDialog,
        setErrorDialog,
    }
}

export const DrawerTwoFASetupContext = createContainer(useTwoFASetupContext)
