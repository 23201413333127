import { Box, Text, VStack, Button, Flex } from "@chakra-ui/react"
import Checkbox from "@components/ui/checkbox"
import { DrawerFundContext } from "@screens/dashboard/transfer/drawerFund/DrawerFundContext"
import { useTranslation } from "react-i18next"

export const CryptoDisclaimers: React.FC = () => {
    const { t } = useTranslation(["app", "common"])

    const { cryptoDisclaimers, setCryptoDisclaimers, setErrorMessage } = DrawerFundContext.useContainer()

    const handleCheckboxChange = (name: keyof typeof cryptoDisclaimers, isChecked: boolean) => {
        setErrorMessage("")
        setCryptoDisclaimers((prev) => ({ ...prev, [name]: isChecked }))
    }

    return (
        <Box mt={7}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("fund.fundWithCrypto", { ns: "transfer" })}</Text>
            <Box pt={7}>
                <Text textStyle={"InterRegularBody"}>
                    {t("fund.cryptoDisclaimers.description", { ns: "transfer" })}
                </Text>
            </Box>
            <Box my={6}>
                <Text textStyle={"ManropeSemiboldBody"}>{t("fund.acceptDisclaimers", { ns: "transfer" })}</Text>
            </Box>
            <VStack spacing={6} alignContent={"flex-start"}>
                <Checkbox
                    text={t("fund.cryptoDisclaimers.disclaimer1", { ns: "transfer" })}
                    name="disclaimer1"
                    isChecked={cryptoDisclaimers.disclaimer1}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer1", isChecked)}
                />
                <Checkbox
                    text={t("fund.cryptoDisclaimers.disclaimer2", { ns: "transfer" })}
                    name="disclaimer2"
                    isChecked={cryptoDisclaimers.disclaimer2}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer2", isChecked)}
                />
                <Checkbox
                    text={t("fund.cryptoDisclaimers.disclaimer3", { ns: "transfer" })}
                    name="disclaimer3"
                    isChecked={cryptoDisclaimers.disclaimer3}
                    setValue={(isChecked) => handleCheckboxChange("disclaimer3", isChecked)}
                />
            </VStack>
            <Flex
                mt={6}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"md"}
                backgroundColor={"blue.10"}
                p={4}
            >
                <Text textStyle={"InterRegularBody"} textAlign={"center"}>
                    {t("fund.cryptoDisclaimers.warning", { ns: "transfer" })}
                </Text>
            </Flex>
        </Box>
    )
}
