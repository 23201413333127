import { Box, Text } from "@chakra-ui/react"
import { REGISTRATION_STEP, REG_SCREENS } from "@screens/onboarding/types"
import DetailsTypeCard from "@components/ui/badge/detailsType"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import NumberInput from "components/ui/numberInput"
import Select from "components/ui/select"
import TextInput from "components/ui/textInput"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SubScreenProps } from "screens/onboarding"
import AmplitudeClient from "sdks/amplitude"

type Props = {} & SubScreenProps

type FormData = {
    government_id: string
    industry: string
    reg_type: { value: string; label: string }
    projected_amount: number
    currency: { value: "CAD" | "USD"; label: string }
    website: string
}

export default function AboutBusiness({ submitSubForm, isLoading, setUserObj, userObj }: Props) {
    const { t } = useTranslation(["onboarding", "common"])
    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            government_id: userObj?.business?.government_id || undefined,
            industry: userObj?.business?.industry || undefined,
            reg_type: userObj?.business?.reg_type
                ? { value: userObj.business.reg_type, label: userObj.business.reg_type }
                : undefined,
            projected_amount: userObj?.business?.projected_quarter?.amount || undefined,
            website: userObj?.business?.website || undefined,
            currency: userObj?.business?.projected_quarter?.currency
                ? {
                      value: userObj.business.projected_quarter.currency,
                      label: userObj.business.projected_quarter.currency,
                  }
                : { value: "USD", label: "USD" },
        },
    })

    async function onSubmitForm(values: FormData) {
        const {
            government_id: governmentId,
            industry,
            reg_type: regType,
            projected_amount: projectedAmount,
            currency,
            website,
        } = values
        const payload = {
            governmentId,
            industry,
            reg_type: regType.value,
            projected_quarter: {
                amount: projectedAmount,
                currency: currency.value,
            },
            website,
        }
        await submitSubForm("/api/v2/account/business", payload, () => {
            setUserObj((prev) => (prev ? { ...prev, business: { ...prev.business, ...payload } } : prev))
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.AboutBusiness, REGISTRATION_STEP.BUSINESS_ABOUT)
        })
    }

    return (
        <Box w="full">
            <DetailsTypeCard isPersonal={false} />
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("aboutBusiness.title")}
            </Text>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <Box display="flex" flexDir="column" rowGap="1.5rem">
                    <TextInput
                        name="government_id"
                        control={control}
                        rules={{ required: t("error.required", { ns: "common" }) }}
                        label={t("aboutBusiness.regNumber")}
                    />
                    <Box display="flex" columnGap="1.5rem">
                        <TextInput
                            name="industry"
                            control={control}
                            rules={{ required: t("error.required", { ns: "common" }) }}
                            label={t("aboutBusiness.industry")}
                        />
                        <Select
                            control={control}
                            label={t("aboutBusiness.type")}
                            options={[
                                { value: "Corporation", label: "Corporation" },
                                { value: "Sole proprietorship", label: "Sole proprietorship" },
                                { value: "LLP", label: "LLP" },
                                { value: "Trust", label: "Trust" },
                                { value: "Non-profit", label: "Non-profit" },
                                { value: "Unincorporated", label: "Unincorporated" },
                                { value: "Association", label: "Association" },
                                { value: "Partnership", label: "Partnership" },
                                { value: "Other", label: "Other" },
                            ]}
                            name="reg_type"
                            rules={{ required: t("error.requiredOption", { ns: "common" }) }}
                        />
                    </Box>
                    <Box>
                        <Text textStyle="InterRegularBodySmall">{t("aboutBusiness.projected")}</Text>
                        <Text textStyle="InterRegularBodySmall" color="grey.dark.60" mb="0.75rem">
                            {t("aboutBusiness.projectedDesc")}
                        </Text>
                        <Box display="flex" columnGap="1.5rem">
                            <NumberInput
                                name="projected_amount"
                                rules={{
                                    required: t("error.required", { ns: "common" }),
                                    pattern: {
                                        value: /^\d*(\.\d{2})?$/,
                                        message: t("error.price", { ns: "common" }),
                                    },
                                }}
                                control={control}
                                inputMode="numeric"
                                label={t("aboutBusiness.projectedAmount")}
                            />
                            <Select
                                control={control}
                                label={t("aboutBusiness.currency")}
                                options={[
                                    { value: "CAD", label: "CAD" },
                                    { value: "USD", label: "USD" },
                                ]}
                                name="currency"
                                rules={{
                                    required: t("error.requiredOption", { ns: "common" }),
                                }}
                            />
                        </Box>
                    </Box>
                    <TextInput name="website" control={control} label={t("aboutBusiness.website")} />
                </Box>
                <StandardButton
                    data-testid="continue-button"
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    mt="3rem"
                    isLoading={isLoading}
                    w="full"
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
