import CheckCircle from "@assets/svgs/common/check-circle"
import { Box, Flex, Text } from "@chakra-ui/react"
import { ReceiptLineItem } from "@components/ui/lineItems/receiptLineItem"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { useTranslation } from "react-i18next"

export const CryptoConfirmation: React.FC = () => {
    const { t } = useTranslation(["transfer", "common"])

    const {
        selectedAssetDetails,
        withdrawAmount,
        cryptoFees,
        getNetWithdrawCryptoAmount,
        walletForm,
        selectedWalletDetails,
    } = DrawerWithdrawContext.useContainer()

    const note = walletForm.watch("note")
    return (
        <Box mt={7} flex={1} overflowY={"auto"}>
            <Flex alignItems="center" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem">
                <Text textStyle="ManropeSemiboldXLarge">{t("withdraw.crypto.withdrawRequestSubmitted")}</Text>
                <CheckCircle />
            </Flex>
            <Text textStyle={"ManropeSemiboldBody"}>{t("withdraw.crypto.withdrawRequestPending")}</Text>
            <Box mt={6} />
            <Text color="grey.light.80" textStyle={"ManropeSemiboldBody"}>
                {t("withdraw.crypto.yourWithdrawalRequest")}
            </Text>
            <Flex
                flexDirection={"column"}
                my={6}
                py={6}
                borderTopWidth="1px"
                borderBottomWidth={"1px"}
                borderColor="grey.light.10"
                gap={3}
            >
                <ReceiptLineItem
                    size="sm"
                    color="grey"
                    leftText={t("withdraw.crypto.amount")}
                    rightText={`${withdrawAmount} ${selectedAssetDetails?.symbol}`}
                />

                <ReceiptLineItem
                    size="sm"
                    color="grey"
                    leftText={t("withdraw.crypto.withdrawalFee")}
                    rightText={`${cryptoFees} ${selectedAssetDetails?.symbol}`}
                />
            </Flex>

            <ReceiptLineItem
                size="md"
                color="black"
                leftText={t("withdraw.crypto.youWillReceive")}
                rightText={`${getNetWithdrawCryptoAmount(withdrawAmount || "")} ${selectedAssetDetails?.symbol}`}
            />

            <Flex mt={6} flexDirection={"column"} gap={1}>
                <ReceiptLineItem
                    size="md"
                    color="black"
                    leftText={t("withdraw.crypto.withdrawTo")}
                    rightText={selectedWalletDetails?.label}
                />
                <Flex flex={1} flexDirection={"row"} justifyContent={"flex-end"}>
                    <Text textStyle={"InterSemiboldXSmall"}> {selectedWalletDetails?.address}</Text>
                </Flex>
            </Flex>

            {note && (
                <Flex
                    flexDirection={"column"}
                    borderRadius="4px"
                    w="full"
                    p="1rem"
                    borderColor="grey.light.10"
                    borderWidth={1}
                    mt={6}
                    gap={2}
                >
                    <Text textStyle="ManropeSemiboldBody">{t("withdraw.crypto.notes")}</Text>
                    <Text textStyle="InterRegularBodySmall">{note}</Text>
                </Flex>
            )}
        </Box>
    )
}
