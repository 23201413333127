import { splitApi } from "@redux/splitApi"
import { ACCOUNT_TAG_TYPES, API_PATH } from "./const"
import {
    IAccountDetails,
    ICreateWalletParams,
    ICreateWalletResponse,
    IRestrictionsResponse,
    ISaveTFAKeyParams,
    ITfaKeyResponse,
    IWalletsResponse,
} from "./types"

export const accountApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getAccountDetails: builder.query<IAccountDetails, void>({
            query: () => API_PATH.accountDetails,
            transformResponse: (response: { data: IAccountDetails }) => response.data,
            providesTags: (result) => [{ type: ACCOUNT_TAG_TYPES.accountDetails }],
        }),
        getAccountBalanceRestrictions: builder.query<IRestrictionsResponse, void>({
            query: () => API_PATH.accountRestrictions,
        }),
        getTFAKey: builder.query<ITfaKeyResponse, void>({
            query: () => API_PATH.generateTFAKey,
        }),
        saveTFAKey: builder.mutation<void, ISaveTFAKeyParams>({
            query: (params) => ({
                url: API_PATH.saveTFAKey,
                method: "POST",
                params,
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.accountDetails }],
        }),
        getWallets: builder.query<IWalletsResponse, void>({
            query: () => API_PATH.wallets,
            providesTags: () => [{ type: ACCOUNT_TAG_TYPES.wallets }],
        }),
        createWallet: builder.mutation<ICreateWalletResponse, ICreateWalletParams>({
            query: (params) => ({
                url: API_PATH.wallets,
                method: "POST",
                body: params,
            }),
            invalidatesTags: [{ type: ACCOUNT_TAG_TYPES.wallets }],
        }),
    }),
})

export const {
    useGetAccountDetailsQuery,
    useGetAccountBalanceRestrictionsQuery,
    useGetTFAKeyQuery,
    useSaveTFAKeyMutation,
    useGetWalletsQuery,
    useCreateWalletMutation,
} = accountApi
export const { getAccountDetails } = accountApi.endpoints
