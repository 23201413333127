import { API_PATH } from "./const"
import {
    CAWireDepositDetailsResponse,
    DepositAddressParams,
    DepositAddressResponse,
    USWireDepositDetailsResponse,
} from "./types"
import { splitApi } from "@redux/splitApi"

export const fundApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getDepositAddress: builder.query<DepositAddressResponse, DepositAddressParams>({
            query: (params) => ({
                url: `${API_PATH.DEPOSIT_ADDRESS}/${params.currency}`,
                method: "GET",
            }),
        }),
        getCAWireDepositDetails: builder.query<CAWireDepositDetailsResponse, undefined>({
            query: () => ({
                url: `${API_PATH.CA_WIRE_DEPOSIT}`,
                method: "GET",
            }),
        }),
        getUSWireDepositDetails: builder.query<USWireDepositDetailsResponse, undefined>({
            query: () => ({
                url: `${API_PATH.US_WIRE_DEPOSIT}`,
                method: "GET",
            }),
        }),
    }),
})

export const { useGetDepositAddressQuery, useGetCAWireDepositDetailsQuery, useGetUSWireDepositDetailsQuery } = fundApi
