import { createSelector } from "@reduxjs/toolkit"
import { API_PATH } from "./const"
import { AssetDetailsFull, AssetDetailsParams } from "./types"
import { splitApi } from "@redux/splitApi"

export const assetDetailsApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getAssetsDetails: builder.query<Record<string, AssetDetailsFull>, AssetDetailsParams | undefined>({
            query: (params) => ({
                url: API_PATH.ASSETS_DETAILS,
                params,
            }),
        }),
    }),
})

export const { useGetAssetsDetailsQuery } = assetDetailsApi

const { getAssetsDetails } = assetDetailsApi.endpoints

// getAssetDetails in array form
export const selectAssetsDetailsAsArray = createSelector(getAssetsDetails.select(undefined), (assetsDetails) =>
    Object.values(assetsDetails)
)
