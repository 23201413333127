import { appendVersion } from "@util/RTKApi"

export const ACCOUNT_TAG_TYPES = {
    accountDetails: "accountDetails",
    wallets: "wallets",
} as const

export const API_PATH = {
    accountDetails: appendVersion("account/account"),
    accountRestrictions: appendVersion("account/restrictions"),
    generateTFAKey: appendVersion("account/two-step-verification/generate"),
    saveTFAKey: appendVersion("account/two-step-verification"),
    wallets: appendVersion("account/wallets"),
} as const
