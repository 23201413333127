import MarketDetails from "@screens/dashboard/marketDetails"
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom"
import Dashboard from "screens/dashboard"
import NetcoinsPay from "screens/dashboard/netcoinsPay"
import Trade from "screens/dashboard/trade"
import Transfer from "screens/dashboard/transfer"
import OnBoarding from "screens/onboarding"
import TwoFa from "screens/twoFa"
import Landing from "../screens/landing"
import AuthRedirect from "./AuthRedirect"
import PrivateRoutes from "./PrivateRoutes"
import { ROUTE_SEGMENTS, RouteParams, ROUTES } from "./routes"
import { Transactions } from "@screens/dashboard/transactions"
import { OpenOrders } from "@screens/dashboard/openOrders"
import { getRelativePathAfterSegment } from "./route-utils"
import Notifications from "@screens/dashboard/settings/notifications"
import Settings from "@screens/dashboard/settings"
import FAQ from "@screens/dashboard/FAQ"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import { useAppSelector } from "@/store/hooks"
import { DataGuard } from "./DataGuard"
import Profile from "@screens/dashboard/settings/profile"
import AccountActivity from "@screens/dashboard/settings/accountActivity"
import Documents from "@screens/dashboard/settings/documents"
import SavedAddressesCrypto from "@screens/dashboard/settings/savedAddressesCrypto"
import SavedAddressesFiat from "@screens/dashboard/settings/savedAddressesFiat"
import AuthorizedDevices from "@screens/dashboard/settings/authorizedDevices"
import BuyWithCredit from "@screens/dashboard/settings/buyWithCredit"

const CanadianRoute = ({ children }: { children: React.ReactElement }) => {
    const { data: userData, isLoading } = useGetAccountDetailsQuery()

    return (
        <DataGuard isLoading={isLoading} hasAccess={userData?.country === "CA"} fallbackRoute={ROUTES.DASHBOARD}>
            {children}
        </DataGuard>
    )
}

const InitialRoute = () => {
    const user = useAppSelector((state) => state.auth.user)
    let fromUrl = user?.url_state
    if (!fromUrl || fromUrl === ROUTE_SEGMENTS.ROOT) {
        fromUrl = ROUTE_SEGMENTS.DASHBOARD // Default route
    }
    return <Navigate to={fromUrl} replace />
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={ROUTE_SEGMENTS.ROOT}>
            <Route
                path={ROUTE_SEGMENTS.LANDING}
                element={
                    <AuthRedirect>
                        <Landing />
                    </AuthRedirect>
                }
            />
            <Route
                path={ROUTE_SEGMENTS.TWO_FA}
                element={
                    <AuthRedirect>
                        <TwoFa />
                    </AuthRedirect>
                }
            />
            <Route element={<PrivateRoutes />}>
                <Route index element={<InitialRoute />} />
                <Route path={ROUTE_SEGMENTS.DASHBOARD} element={<Dashboard />}>
                    <Route path={ROUTE_SEGMENTS.TRADE} element={<Trade />} />
                    <Route path={`${ROUTE_SEGMENTS.TRADE}/:${RouteParams.ASSET}`} element={<MarketDetails />} />
                    <Route
                        path={getRelativePathAfterSegment(ROUTES.ASSET_TRANSACTIONS, ROUTE_SEGMENTS.DASHBOARD)}
                        element={<Transactions />}
                    />
                    <Route
                        path={getRelativePathAfterSegment(ROUTES.ASSET_OPEN_ORDERS, ROUTE_SEGMENTS.DASHBOARD)}
                        element={<OpenOrders />}
                    />
                    <Route path={ROUTE_SEGMENTS.TRANSFER} element={<Transfer />} />
                    <Route path={ROUTE_SEGMENTS.SETTINGS} element={<Settings />} />
                    <Route path={ROUTES.PROFILE} element={<Profile />} />
                    <Route path={ROUTES.ACCOUNT_ACTIVITY} element={<AccountActivity />} />
                    <Route path={ROUTES.DOCUMENTS} element={<Documents />} />
                    <Route path={ROUTES.SAVED_ADDRESSES_CRYPTO} element={<SavedAddressesCrypto />} />
                    <Route path={ROUTES.SAVED_ADDRESSES_FIAT} element={<SavedAddressesFiat />} />
                    <Route path={ROUTES.AUTHORIZED_DEVICES} element={<AuthorizedDevices />} />
                    <Route path={ROUTES.BUY_WITH_CREDIT} element={<BuyWithCredit />} />
                    <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
                    <Route path={ROUTE_SEGMENTS.FAQ} element={<FAQ />} />
                    <Route
                        path={ROUTE_SEGMENTS.NETCOINS_PAY}
                        element={
                            <CanadianRoute>
                                <NetcoinsPay />
                            </CanadianRoute>
                        }
                    />
                </Route>
                <Route path={ROUTE_SEGMENTS.ONBOARDING} element={<OnBoarding />} />
                {/* TODO determine here if user should be redirected to dashboard or onboarding. base this off of user/validation data */}
                <Route path="*" element={<Navigate to={ROUTE_SEGMENTS.DASHBOARD} />} />{" "}
                {/* Redirect unknown routes to dashboard */}
            </Route>
        </Route>
    )
)

export default router
