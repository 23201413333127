import { Box, Text, Flex } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import SettingsSection, { SETTINGS_SECTION_TITLE_MAXW } from "@components/settings/settingsSection"
import SettingsRow from "@components/settings/settingsRow"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import SettingsRowAdv from "@components/settings/settingsRowAdv"
import ZenLedgerSVg from "@assets/svgs/brands/zenledger.svg"
import KoinlySVg from "@assets/svgs/brands/koinly.svg"
import DashboardFooter from "@components/footer/dashboard"
import { useMemo } from "react"
import { useGetAccountDetailsQuery } from "@redux/account/apiSlice"
import { ROUTES } from "@routing/routes"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"

export default function Settings(): JSX.Element {
    const { t } = useTranslation("settings")
    const { data: accountData } = useGetAccountDetailsQuery()
    const { navigate } = useRestrictedNavigation()

    const isTFASetup = useMemo(() => accountData?.tfa, [accountData])

    return (
        <Flex flexDir="column">
            <Box>
                <Text textStyle="ManropeMedium4xLarge">{t("settings.title")}</Text>
                <Box my={{ base: 8, md: 9 }} borderBottomWidth={1} />

                <Box mx={{ base: 0, xl: "105px" }}>
                    <Flex flexDir="column" gap={{ base: 8, md: 12 }}>
                        <SettingsSection
                            title={t("settings.account")}
                            text={t("settings.accountSub")}
                            children={
                                <Flex flexDir="column" gap={{ base: 3, md: 6 }}>
                                    <SettingsRow
                                        title={t("settings.myProfile")}
                                        text={t("settings.profileSub")}
                                        link={{
                                            href: ROUTES.PROFILE,
                                            label: t("settings.viewDetails"),
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("settings.myDocs")}
                                        text={t("settings.docsSub")}
                                        link={{
                                            href: ROUTES.DOCUMENTS,
                                            label: t("viewAll", { ns: "common" }),
                                        }}
                                    />
                                </Flex>
                            }
                        />

                        <Box borderBottomWidth={1} />

                        <SettingsSection
                            title={t("settings.security")}
                            text={t("settings.securitySub")}
                            children={
                                <Flex flexDir="column" gap={{ base: 3, md: 6 }}>
                                    <SettingsRow
                                        title={t("settings.2fa")}
                                        text={t("settings.2faSub")}
                                        button={{
                                            children: t(isTFASetup ? "settings.disable2fa" : "settings.setup2fa"),
                                            variant: ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE,
                                            size: "sm",
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("settings.authDev")}
                                        text={t("settings.authDevSub")}
                                        link={{
                                            href: ROUTES.AUTHORIZED_DEVICES,
                                            label: t("settings.viewAllDevices"),
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("settings.accActivity")}
                                        text={t("settings.accActivitySub")}
                                        link={{
                                            href: ROUTES.ACCOUNT_ACTIVITY,
                                            label: t("settings.viewActivity"),
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("settings.myPass")}
                                        text={t("settings.myPassSub")}
                                        button={{
                                            children: t("settings.changePass"),
                                            variant: ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE,
                                            size: "sm",
                                        }}
                                    />
                                </Flex>
                            }
                        />

                        <Box borderBottomWidth={1} />

                        <Flex
                            flexDir={{ base: "column", lg: "row" }}
                            justify={{ base: "flex-start", md: "space-between" }}
                            columnGap={"1.5rem"}
                            rowGap="1rem"
                        >
                            <Box w="full" maxW={SETTINGS_SECTION_TITLE_MAXW}>
                                <Text textStyle="ManropeSemiboldXLarge">{t("settings.notis")}</Text>
                            </Box>
                            <SettingsRow
                                title={t("settings.alerts")}
                                text={t("settings.alertsSub")}
                                button={{
                                    children: t("viewAll", { ns: "common" }),
                                    variant: ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE,
                                    size: "sm",
                                    onClick: () => navigate(ROUTES.NOTIFICATIONS),
                                }}
                            />
                        </Flex>

                        <Box borderBottomWidth={1} />

                        <SettingsSection
                            title={t("settings.savedAddresses")}
                            text={t("settings.savedAddressesSub")}
                            children={
                                <Flex flexDir="column" gap={{ base: 3, md: 6 }}>
                                    <SettingsRow
                                        title={t("crypto", { ns: "common" })}
                                        text={t("settings.cryptoSub")}
                                        link={{
                                            href: ROUTES.SAVED_ADDRESSES_CRYPTO,
                                            label: t("viewAll", { ns: "common" }),
                                        }}
                                    />
                                    <SettingsRow
                                        title={t("fiat", { ns: "common" })}
                                        text={t("settings.fiatSub")}
                                        link={{
                                            href: ROUTES.SAVED_ADDRESSES_FIAT,
                                            label: t("viewAll", { ns: "common" }),
                                        }}
                                    />
                                </Flex>
                            }
                        />

                        <Box borderBottomWidth={1} />

                        <SettingsSection
                            title={t("settings.tax")}
                            text={t("settings.taxSub")}
                            children={
                                <Flex flexDir="column" gap={{ base: 3, md: 6 }}>
                                    <SettingsRowAdv
                                        title="Koinly"
                                        text={t("settings.koinlyDesc")}
                                        button={{
                                            children: t("connect", { ns: "common" }),
                                            variant: ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE,
                                            size: "sm",
                                        }}
                                        icon={KoinlySVg}
                                        isRecommended={false}
                                        bg="#356AE6"
                                    />
                                    <SettingsRowAdv
                                        title="ZenLedger"
                                        text={t("settings.zenDesc")}
                                        button={{
                                            children: t("connect", { ns: "common" }),
                                            variant: ENUM_BUTTON_VARIANTS.PRIMARY_BLUE_OUTLINE,
                                            size: "sm",
                                        }}
                                        icon={ZenLedgerSVg}
                                        isRecommended={true}
                                        bg="#11D7A3"
                                    />
                                </Flex>
                            }
                        />

                        <Box borderBottomWidth={1} />

                        <Flex
                            flexDir={{ base: "column", lg: "row" }}
                            justify={{ base: "flex-start", md: "space-between" }}
                            columnGap={"1.5rem"}
                            rowGap="1rem"
                        >
                            <Box w="full" maxW={SETTINGS_SECTION_TITLE_MAXW}>
                                <Text textStyle="ManropeSemiboldXLarge">{t("settings.buyCredit")}</Text>
                            </Box>
                            <SettingsRow
                                text={t("settings.buyCreditSub")}
                                button={{
                                    children: t("settings.buyViaCredit"),
                                    variant: ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE,
                                    size: "sm",
                                    onClick: () => navigate(ROUTES.BUY_WITH_CREDIT),
                                }}
                            />
                        </Flex>
                        <Box />
                    </Flex>
                </Box>
            </Box>

            <DashboardFooter />
        </Flex>
    )
}
