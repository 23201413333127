import { API_PATH } from "./const"
import { APIStake, APIStakeParams, StakingInfo } from "./types"
import { splitApi } from "@redux/splitApi"

export const stakeApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getStakingInfo: builder.query<StakingInfo, string>({
            query: (asset) => {
                return `${API_PATH.STAKES}/assets/${asset.toLowerCase()}`
            },
        }),
        getStakes: builder.query<Array<APIStake>, APIStakeParams>({
            query: (params) => ({
                url: API_PATH.STAKES,
                method: "GET",
                params,
            }),
        }),
    }),
})

export const { useGetStakingInfoQuery, useGetStakesQuery } = stakeApi
