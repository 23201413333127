import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { MdCheck } from "react-icons/md"

const useGenericToast = () => {
    const { t } = useTranslation("common")
    const toast = useToast()

    function serverErrorToast() {
        toast({
            title: t("error.server"),
            description: t("error.serverDesc"),
            status: "error",
            duration: 5000,
            isClosable: false,
        })
    }

    const successToast = (title: string, description?: string) => {
        toast({
            title,
            description,
            status: "success",
            duration: 5000,
            isClosable: false,
            variant: "solidSuccess",
            icon: <ReactIcon icon={MdCheck} size={24} color={"white"} />,
            position: "bottom-right",
        })
    }

    return {
        serverErrorToast,
        successToast,
    }
}

export default useGenericToast
