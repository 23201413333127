import { defineStyleConfig } from "@chakra-ui/react"
import { SolidSuccessVariant, SolidVariant, SubtleVariant } from "./alerts"

const Skeleton = defineStyleConfig({
    baseStyle: {
        animationDuration: "10s",
        transitionDuration: "10s",
        //@ts-ignore
        fadeDuration: 10,
    },
})

export const Components = {
    Alert: {
        // Toasts are considered Alerts under the hood
        variants: {
            subtle: SubtleVariant,
            solid: SolidVariant,
            solidSuccess: SolidSuccessVariant,
        },
    },
    Button: {
        sizes: {
            xl: {
                h: "56px",
                fontSize: "lg",
                px: "32px",
            },
        },
    },
    Menu: {
        baseStyle: {
            item: {
                bgColor: "white",
                _hover: {
                    bg: "blue.100",
                },
                padding: "0.75rem 1rem",
                justifyContent: "flex-start",
                "&:hover": {
                    p: {
                        color: "white",
                    },
                    svg: {
                        fill: "white !important",
                    },
                },
                margin: "0 0.5rem",
                width: "calc(100% - 1rem)",
            },
        },
    },
    Input: {},
    Skeleton,
    Text: {
        baseStyle: {
            color: "black",
        },
    },
}
