import React, { createContext, useState, useContext, ReactNode } from "react"
import { ENUM_UNIVERSAL_MODAL_TYPES } from "../types"

interface ModalContextType {
    openModal: (modalName: ENUM_UNIVERSAL_MODAL_TYPES) => void
    closeModal: () => void
    modalState: ENUM_UNIVERSAL_MODAL_TYPES | null
}

interface ModalProviderProps {
    children: ReactNode
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [modalState, setModalState] = useState<ENUM_UNIVERSAL_MODAL_TYPES | null>(null)

    const openModal = (modalName: ENUM_UNIVERSAL_MODAL_TYPES) => {
        if (modalState) {
            return
        }

        setModalState(modalName)
    }

    const closeModal = () => {
        setModalState(null)
    }

    return <ModalContext.Provider value={{ openModal, closeModal, modalState }}>{children}</ModalContext.Provider>
}

export const useUniversalModal = (): ModalContextType => {
    const context = useContext(ModalContext)
    if (!context) {
        throw new Error("useModal must be used within a ModalProvider")
    }
    return context
}
