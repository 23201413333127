import { Text, Flex, Box } from "@chakra-ui/react"
import { ReactNode } from "react"

type Props = {
    title: string
    text: string
    children: ReactNode
}

export const SETTINGS_SECTION_TITLE_MAXW = { base: "100%", lg: "226px", xl: "291px" }

export default function SettingsSection({ title, text, children }: Props): JSX.Element {
    return (
        <Flex gap={4} flexDir="column">
            <Text textStyle="ManropeSemiboldXLarge">{title}</Text>

            <Flex gap={6} alignItems="flex-start" flexDir={{ base: "column", lg: "row" }}>
                <Text
                    w="full"
                    maxW={SETTINGS_SECTION_TITLE_MAXW}
                    color="grey.light.80"
                    textStyle="InterRegularBodySmall"
                >
                    {text}
                </Text>

                <Box flex={1}>{children}</Box>
            </Flex>
        </Flex>
    )
}
