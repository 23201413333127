import { Box, VStack } from "@chakra-ui/layout"
import Checkbox from "@components/ui/checkbox"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { useTranslation } from "react-i18next"

export const WithdrawWireUSDisclaimers: React.FC = () => {
    const { t } = useTranslation(["transfer"])

    const { usWireDisclaimers, setUSWireDisclaimers, resetErrors } = DrawerWithdrawContext.useContainer()

    const handleCheckboxChange = (name: keyof typeof usWireDisclaimers, isChecked: boolean) => {
        resetErrors()
        setUSWireDisclaimers((prev) => ({ ...prev, [name]: isChecked }))
    }

    return (
        <Box mt={6}>
            <VStack spacing={6} alignContent={"flex-start"}>
                {Object.keys(usWireDisclaimers).map((key) => {
                    const typedKey = key as keyof typeof usWireDisclaimers
                    return (
                        <Checkbox
                            key={key}
                            text={t(`withdraw.wire.disclaimers.us.${typedKey}.title`)}
                            name={key}
                            isChecked={!!usWireDisclaimers[typedKey]}
                            setValue={(isChecked) => handleCheckboxChange(typedKey, isChecked)}
                        />
                    )
                })}
            </VStack>
        </Box>
    )
}
