import { Box, Text, VStack } from "@chakra-ui/react"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import TwoFACode from "@screens/twoFASetup/twoFACode"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { WithdrawType } from "../../drawerWithdraw"

export const WithdrawTfa = () => {
    const { t } = useTranslation(["transfer", "twoFASetup"])
    const {
        errorMessage,
        setErrorMessage,
        tfaCode,
        setTFACode,
        currentWithdrawType,
        executeCryptoWithdraw,
        executeInteracWithdraw,
        executeWireWithdraw,
        setView,
    } = DrawerWithdrawContext.useContainer()

    const handleTFACodeChange = (value: string) => {
        setTFACode(value)
        setErrorMessage({ button: "", textField: "" })
    }
    const title = useMemo(() => {
        switch (currentWithdrawType) {
            case WithdrawType.CRYPTO:
                return "withdraw.crypto.title"
            case WithdrawType.WIRE:
                return "withdraw.wire.title"
            case WithdrawType.INTERAC:
                return "withdraw.interac.title"
            default:
                return ""
        }
    }, [currentWithdrawType])

    const onComplete = async (code: string) => {
        try {
            switch (currentWithdrawType) {
                case WithdrawType.CRYPTO:
                    await executeCryptoWithdraw(code)
                    break
                case WithdrawType.WIRE:
                    await executeWireWithdraw(code)
                    break
                case WithdrawType.INTERAC:
                    await executeInteracWithdraw(code)
                    break
                default:
                    break
            }
            setView((prev) => prev + 1)
        } catch (e) {}
    }

    return (
        <Box mt={7} flex={1} overflowY={"auto"}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t(title)}</Text>
            <Box mt={6} />
            <Text textStyle="ManropeSemiboldBody" color="black">
                {t("withdraw.crypto.TFADesc")}
            </Text>

            <VStack w="full" py="1.5rem">
                <TwoFACode
                    error={!!errorMessage.button}
                    value={tfaCode}
                    onChange={handleTFACodeChange}
                    showTFARecovery
                    onComplete={(val) => {
                        handleTFACodeChange(val)
                        onComplete(val)
                    }}
                />
            </VStack>
        </Box>
    )
}
