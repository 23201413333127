import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { addCommasToNumber, addThousandSeparator, formatDollar, removeTrailingZeros } from "@util/stringFormatting"
import { IOrder } from "@redux/orders/types"
import { Transaction, TransactionAction } from "@redux/transactions/types"
import { FiMoreVertical } from "react-icons/fi"
import _ from "lodash"
import { isFiatCurrency } from "@util/currencyHelpers"
import { formatNumberFixedPrecision, formatPrecision } from "@util/numericalFormatting"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { AssetDetailsFull } from "@redux/assetsDetails/types"
import Chip from "@components/ui/badge"
import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"

const getSideText = <T extends Transaction>(data: T) => {
    switch (data.action) {
        case "buy":
            return "Bought"
        case "sell":
            return "Sold"
        case "limit-buy":
            return "Buy at"
        case "limit-sell":
            return "Sell at"
        default:
            return data.action.charAt(0).toUpperCase() + data.action.slice(1)
    }
}

const establishTxQuantity = (tx: Transaction, assetDetailsData?: Record<string, AssetDetailsFull>): string => {
    // TODO price precision needs to be written better here. And frankly this whole thing should be a hook
    // because its used elsewhere (Transacations and Open Orders screens)
    const data = assetDetailsData?.[tx.asset]
    switch (tx.action) {
        case TransactionAction.LimitBuy:
            return tx.increase_amount
        case TransactionAction.LimitSell:
            return tx.decrease_amount
        case TransactionAction.Buy:
            return tx.increase_amount
        case TransactionAction.Sell:
            return tx.decrease_amount
        case TransactionAction.Deposit: {
            if (isFiatCurrency(tx.increase_currency)) {
                return formatDollar(tx.increase_amount)
            }
            const precisionToUse = data?.precisions.withdrawal || data?.precision || 2
            return formatNumberFixedPrecision(tx.increase_amount, Number(precisionToUse), true)
        }
        case TransactionAction.Withdraw: {
            if (isFiatCurrency(tx.decrease_currency)) {
                return formatDollar(tx.decrease_amount)
            }
            const precisionToUse = data?.precisions.withdrawal || data?.precision
            return formatNumberFixedPrecision(tx.decrease_amount, Number(precisionToUse), true)
        }
        case TransactionAction.KohoReward:
        case TransactionAction.Reward: {
            if (isFiatCurrency(tx.increase_currency)) {
                return formatDollar(tx.increase_amount)
            }
            const precisionToUse = data?.precision
            return formatNumberFixedPrecision(tx.increase_amount, Number(precisionToUse), true)
        }
        case TransactionAction.Stake:
            return formatNumberFixedPrecision(tx.decrease_amount, Number(data?.precision), true)

        case TransactionAction.Unstake:
            return formatNumberFixedPrecision(tx.increase_amount, Number(data?.precision), true)

        case TransactionAction.StakeReward: {
            if (isFiatCurrency(tx.increase_currency)) {
                return formatDollar(tx.increase_amount)
            }
            const precisionToUse = data?.precision
            return formatNumberFixedPrecision(tx.increase_amount, Number(precisionToUse), true)
        }
        default:
            return "0"
    }
}

const establishTxAmount = (tx: Transaction, assetDetailsData?: Record<string, AssetDetailsFull>): string => {
    // TOdo should be a hook with the above func included
    const data = assetDetailsData?.[tx.asset]
    switch (tx.action) {
        case TransactionAction.LimitBuy:
        case TransactionAction.LimitSell:
            return formatDollar(tx.total)
        case TransactionAction.Buy:
        case TransactionAction.Sell:
            return formatDollar(tx.total)
        case TransactionAction.Deposit:
            return ""
        case TransactionAction.Withdraw:
            return ""
        case TransactionAction.KohoReward:
        case TransactionAction.Reward:
            return formatDollar(String(Number(tx.rate) * Number(tx.increase_amount)))
        case TransactionAction.Stake:
            return formatDollar(String(Number(tx.rate) * Number(tx.decrease_amount)))
        case TransactionAction.Unstake:
            return formatDollar(String(Number(tx.rate) * Number(tx.increase_amount)))
        case TransactionAction.StakeReward:
            return tx.decrease_amount
        default:
            return ""
    }
}

export type AssetTableProps<T extends IOrder | Transaction> = {
    arr: T[]
    setSelectedArr: (_: T) => void
    isCurrencyOrderSwapped: boolean
}

export const TableHeader = ({ isOpenOrder }: { isOpenOrder: boolean }) => {
    const { t } = useTranslation(["app", "common"])

    return (
        <Flex backgroundColor={"grey.dark.10"} py={2} px="0.25rem">
            <Box display={{ base: "flex", md: "none" }} w="full">
                <Text as="span" textStyle="ManropeSemiboldXSmall" flex={1} color="grey.light.90">
                    {isOpenOrder ? t("marketDetails.orderType") : t("activity", { ns: "common" })}
                </Text>
                <Text as="span" textStyle="ManropeSemiboldXSmall" color="grey.light.90" flex={1} textAlign="end">
                    {t("amount", { ns: "common" })}
                </Text>
                <Box width="40px" />
            </Box>
            <Box display={{ base: "none", md: "flex" }} w="full">
                <Text w="100px" as="span" textStyle="ManropeSemiboldXSmall" fontWeight={600} color="grey.light.90">
                    {t("date", { ns: "common" })}
                </Text>
                <Text w="100px" as="span" textStyle="ManropeSemiboldXSmall" fontWeight={600} color="grey.light.90">
                    {isOpenOrder ? t("type", { ns: "common" }) : t("activity", { ns: "common" })}
                </Text>
                {isOpenOrder ? (
                    <Text as="span" textStyle="ManropeSemiboldXSmall" fontWeight={600} color="grey.light.90">
                        {t("price", { ns: "common" })}
                    </Text>
                ) : null}
                <Text
                    flex={1}
                    textAlign="end"
                    as="span"
                    textStyle="ManropeSemiboldXSmall"
                    fontWeight={600}
                    color="grey.light.90"
                >
                    {t("amount", { ns: "common" })}
                </Text>
                <Box width="40px" />
            </Box>
        </Flex>
    )
}

export const renderDesktopTableContent = <T extends Transaction>(
    data: T[],
    isOpenOrder: boolean,
    localizer: (key: string, options?: Record<string, string>) => string,
    handleViewMore: (_: T) => void,
    isCurrencyOrderSwapped: boolean,
    assetDetailsData?: Record<string, AssetDetailsFull>
) => (
    <Box width="100%">
        {data.map((tx, index) => {
            const quantity = establishTxQuantity(tx, assetDetailsData)
            const txAmount = establishTxAmount(tx, assetDetailsData)
            const date = new Date(tx.created_at * 1000)
            const sideText = getSideText(tx)
            const isOnlyOneTotal = !txAmount
            const isCancelling = tx.status === "Cancelling"

            return (
                <Flex
                    key={index}
                    py={{ base: 2, md: 3 }}
                    borderBottom="1px solid"
                    borderColor="grey.dark.20"
                    alignItems="center"
                    justify={{ base: "space-between", md: "flex-start" }}
                >
                    <Flex flexDir="column" rowGap={{ base: "0.5rem", md: 0 }}>
                        <Text color="grey.dark.60" textStyle="ManropeSemiboldXSmall" width="100px">
                            {date.toLocaleString("default", { month: "short" })} {date.getDate()}, {date.getFullYear()}
                        </Text>
                        <Box display={{ base: "inline-block", md: "none" }}>
                            <Text textStyle="ManropeSemiboldXSmall">{sideText}</Text>
                            <Text textStyle="ManropeSemiboldXSmall">
                                {"rate" in tx ? addCommasToNumber(formatDollar(tx.rate)) : ""}
                            </Text>
                        </Box>
                        {isCancelling && (
                            <Box display={{ base: "block", md: "none" }}>
                                <Chip variant={ENUM_CHIP_VARIANTS.RED} text={localizer("cancelling")} />
                            </Box>
                        )}
                    </Flex>
                    <Text display={{ base: "none", md: "inline-block" }} textStyle="ManropeSemiboldXSmall" w="100px">
                        {sideText}
                    </Text>
                    {isOpenOrder ? (
                        <Flex flexDir={"column"} rowGap="0.25rem">
                            <Text
                                textStyle="ManropeSemiboldXSmall"
                                w="100px"
                                display={{ base: "none", md: "inline-block" }}
                            >
                                {"rate" in tx ? addCommasToNumber(formatDollar(tx.rate)) : ""}
                            </Text>
                            {isCancelling && (
                                <Box display={{ base: "none", md: "block" }}>
                                    <Chip variant={ENUM_CHIP_VARIANTS.RED} text={localizer("cancelling")} />
                                </Box>
                            )}
                        </Flex>
                    ) : null}
                    <Box textAlign="end" flexGrow={1} alignItems={"center"}>
                        <Text color="black" textStyle="ManropeSemiboldXSmall">
                            {isCurrencyOrderSwapped
                                ? formatPrecision(quantity, assetDetailsData?.[tx.asset]?.precision ?? 2)
                                : `${txAmount}`}
                        </Text>
                        <Text
                            color={isOnlyOneTotal ? "black" : "grey.light.50"}
                            textStyle={isOnlyOneTotal ? "ManropeSemiboldXSmall" : "ManropeMediumBodySmall"}
                        >
                            {!isCurrencyOrderSwapped
                                ? formatPrecision(quantity, assetDetailsData?.[tx.asset]?.precision ?? 2)
                                : `${txAmount}`}
                        </Text>
                    </Box>
                    <Box display="inline-block" verticalAlign="middle" ml={2}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                variant="ghost"
                                iconSpacing={0}
                                rightIcon={<FiMoreVertical size={15} />}
                                p={11}
                            />
                            <MenuList padding={0} borderRadius={8}>
                                <MenuItem onClick={() => handleViewMore(tx)} borderRadius={8}>
                                    {localizer("viewMore")}
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Flex>
            )
        })}
    </Box>
)

export default function OpenOrdersTab({ arr, setSelectedArr, isCurrencyOrderSwapped }: AssetTableProps<Transaction>) {
    const { t } = useTranslation(["common", "app"])
    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    return (
        <Box minHeight="100px">
            <TableHeader isOpenOrder />
            {arr.length ? (
                renderDesktopTableContent(arr, true, t, setSelectedArr, isCurrencyOrderSwapped, assetDetailsData)
            ) : (
                <Flex
                    pos="absolute"
                    left="50%"
                    top="calc(50% + 34px)"
                    transform="translate(-50%, -50%)"
                    flex={1}
                    justifyContent={"center"}
                    alignItems="center"
                >
                    <Text color="grey.light.50" textStyle="ManropeSemiboldBody">
                        {t("marketDetails.emptyOrders", { ns: "app" })}
                    </Text>
                </Flex>
            )}
        </Box>
    )
}
