import { Box, Image, Text, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import { useMemo, useRef, useState } from "react"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { formatDollar } from "@util/stringFormatting"
import useImage from "@hooks/useImage"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import PlainModal from "@components/ui/modals/plain"
import { Transaction, TransactionAction } from "@redux/transactions/types"
import { useDeleteOpenOrderMutation } from "@redux/transactions/apiSlice"
import { imageMap } from "@assets/svgs/coins"

type Props = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    order: Transaction | null
}

export default function DrawerOpenOrder({ isOpen, toggleSideDrawerOpen, order }: Props) {
    const { t } = useTranslation(["common", "orders"])

    const {
        total,
        subtotal,
        asset,
        rate: order_price,
        action,
        increase_amount,
        decrease_amount,
        increase_currency,
        decrease_currency,
        uuid,
    } = order || {}

    const [isDeleting, setIsDeleting] = useState(false)
    const [err, setErr] = useState<string>()

    const [deleteOpenOrder] = useDeleteOpenOrderMutation()

    const isBuy = useMemo(() => action?.toLocaleLowerCase() === TransactionAction.LimitBuy, [action])

    const quantity = useMemo(
        () => (isBuy ? increase_amount : decrease_amount),
        [isBuy, increase_amount, decrease_amount]
    )
    const counterAsset = useMemo(
        () => (isBuy ? decrease_currency : increase_currency),
        [isBuy, decrease_currency, increase_currency]
    )

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const selectedAsset = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData, asset])

    const image = imageMap[asset?.toLowerCase() as keyof typeof imageMap]

    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
    const cancelRef = useRef<HTMLButtonElement>(null)

    const cancelOrder = async () => {
        setIsDeleting(true)
        try {
            await deleteOpenOrder({ order_id: `${uuid}` })
            onModalClose()
            toggleSideDrawerOpen()
        } catch (e) {
            setErr(t("orders:errorDeleting"))
        } finally {
            setIsDeleting(false)
        }
    }

    return (
        <>
            <SideDrawer
                isOpen={isOpen}
                toggleSideDrawerOpen={toggleSideDrawerOpen}
                header={<StandardDrawerHeader title={"Order Details"} onClose={toggleSideDrawerOpen} />}
                footerButton={{
                    variant: ENUM_BUTTON_VARIANTS.SECONDARY_SOLID,
                    type: "submit",
                    onClick: onModalOpen,
                    children: t("orders:openOrders.cancelOrder"),
                    isLoading: isDeleting,
                }}
            >
                <Box display="flex" flex={1} flexDirection={"column"}>
                    <Box py={14}>
                        {!order ? (
                            <Text>{t("orders:openOrders.missingData")}</Text>
                        ) : (
                            <Box display={"flex"} flexDirection={"column"}>
                                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                    {image && (
                                        <Image
                                            src={image}
                                            alt={"coin" + asset?.toLowerCase()}
                                            height={{ base: "62px" }}
                                            width={{ base: "62px" }}
                                        />
                                    )}
                                    <Box display={"flex"} flexDirection={"column"} flex={1} mx={3}>
                                        <Text textStyle={"ManropeMediumXLarge"}>{selectedAsset?.name}</Text>
                                        <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.dark.70"}>
                                            {asset}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    borderBottom={"1px solid"}
                                    borderColor={"grey.dark.10"}
                                    pt={3}
                                    pb={6}
                                >
                                    <Text textStyle={"ManropeMediumXLarge"} mb={2}>
                                        {t("orders:openOrders.openLimitOrder")}
                                    </Text>
                                    <Text textStyle={"InterRegularBodySmall"} textColor={"grey.dark.70"}>
                                        {t("orders:openOrders.notGuaranteeExec")}
                                    </Text>
                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    py={6}
                                    borderBottom={"1px solid"}
                                    borderColor={"grey.dark.10"}
                                >
                                    <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                                        <Text textStyle={"ManropeSemiboldBody"}>
                                            {isBuy ? t("orders:openOrders.willBuy") : t("orders:openOrders.willSell")}
                                        </Text>
                                        <Text textAlign={"right"} textStyle={"ManropeSemiboldBody"}>
                                            {`${formatNumberFixedPrecision(quantity || "", Number(selectedAsset?.precision), true)} ${asset}`}
                                        </Text>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        flexDirection={"row"}
                                        marginTop={3}
                                    >
                                        <Text
                                            textStyle={"ManropeSemiboldBodySmall"}
                                            textColor={"grey.light.70"}
                                        >{`When 1 ${asset} =`}</Text>
                                        <Text
                                            textAlign={"right"}
                                            textStyle={"ManropeSemiboldBodySmall"}
                                            textColor={"grey.light.70"}
                                        >
                                            {`${formatDollar(order_price || "")} ${counterAsset}`}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box display={"flex"} flexDirection={"column"} py={6}>
                                    <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                                        <Box display={"flex"} flexDirection={"column"} paddingBottom={6}>
                                            <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                                                {t("transactions:transactionDetails.subTotal")}
                                            </Text>
                                            <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} mt={3}>
                                                {t("transactions:transactionDetails.netcoinsFee")}
                                            </Text>
                                        </Box>
                                        <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            borderBottom={"1px solid"}
                                            borderColor={"grey.dark.10"}
                                            paddingBottom={6}
                                            textAlign={"right"}
                                        >
                                            <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                                                {`${formatDollar(subtotal || "")} ${counterAsset}`}
                                            </Text>
                                            <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} mt={3}>
                                                {`${formatDollar(order.fee)} ${counterAsset}`}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        flexDirection={"row"}
                                        paddingTop={6}
                                    >
                                        <Text textStyle={"ManropeSemiboldBody"}>
                                            {isBuy
                                                ? t("orders:openOrders.willPay")
                                                : t("orders:openOrders.willReceive")}
                                        </Text>
                                        <Text textAlign={"right"} textStyle={"ManropeSemiboldBody"}>
                                            {`${formatDollar(total || "")} ${counterAsset}`}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </SideDrawer>
            <PlainModal
                isOpen={isModalOpen}
                cancelRef={cancelRef}
                title={t("orders:openOrders.cancelOrderTitle")}
                err={err}
                onClose={onModalClose}
                buttons={[
                    {
                        children: t("common:no"),
                        onClick: onModalClose,
                        variant: ENUM_BUTTON_VARIANTS.SECONDARY_GHOST,
                        isNoPadding: true,
                    },
                    {
                        children: t("common:yes"),
                        onClick: cancelOrder,
                        variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                        isLoading: isDeleting,
                    },
                ]}
            >
                <Text textStyle={"InterRegularBody"}>{t("orders:openOrders.confirmDelete")}</Text>
            </PlainModal>
        </>
    )
}
