import { Text, Box, Flex, Icon, Image } from "@chakra-ui/react"
import Chip from "@components/ui/badge"
import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"
import StandardButton, { StandardButtonProps } from "@components/ui/buttons/standard"
import { MdOpenInNew } from "react-icons/md"
import { useTranslation } from "react-i18next"
import { SETTINGS_ROW_BUTTON_WIDTH } from "../settingsRow"

type Props = {
    title: string
    text: string
    button: StandardButtonProps
    isRecommended?: boolean
    icon: string
    bg: string
}

export default function SettingsRowAdv({ button, text, title, isRecommended, icon, bg }: Props): JSX.Element {
    const { t } = useTranslation(["settings", "common"])

    return (
        <Flex direction={{ base: "column", sm: "row" }} alignItems={{ base: "stretch" }} gap={{ lg: 4, md: 6 }}>
            {icon && (
                <Flex
                    width="94px"
                    bg={bg}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"6px"}
                    display={{ base: "none", md: "flex" }}
                >
                    <Image src={icon} alt={"icon-brand"} w={"72px"} h={"72px"} objectFit={"fill"} />
                </Flex>
            )}

            <Box w="100%">
                <Flex gap={{ base: 4, md: 0 }} mb={{ base: 4, md: 0 }} flexDir={{ md: "row" }}>
                    {icon && (
                        <Flex flexShrink={0} height={"100%"} display={{ md: "none" }}>
                            <Image
                                src={icon}
                                alt={"icon-brand"}
                                w={{ base: "50px" }}
                                h={{ base: "50px" }}
                                borderRadius={"6px"}
                                objectFit={"fill"}
                            />
                        </Flex>
                    )}

                    <Flex
                        gap={{ base: 1, md: 3 }}
                        flexDir={{ base: "column", md: "row" }}
                        p={{ base: "0px 10px", md: 0 }}
                        alignItems={{ base: "flex-start", md: "center" }}
                        justifyContent={"center"}
                    >
                        <Text textStyle="ManropeSemiboldBody">{title}</Text>

                        {isRecommended && (
                            <Chip
                                text={t("recommended", { ns: "common" })}
                                variant={ENUM_CHIP_VARIANTS.GREEN}
                                size="sm"
                            />
                        )}
                    </Flex>
                </Flex>

                <Flex
                    justifyContent={{
                        base: "flex-start",
                        sm: "space-between",
                    }}
                    alignItems="center"
                    flexWrap="wrap"
                    alignSelf="stretch"
                    mt={{ md: 1 }}
                >
                    <Box alignSelf="stretch" flex="1">
                        <Box mb={{ base: 2, md: "6px" }} alignSelf="stretch" flex="1">
                            <Text textStyle="InterRegularBodySmall" color="grey.light.80" whiteSpace="normal">
                                {text}
                            </Text>
                        </Box>

                        <Flex justifyContent={{ base: "space-between", md: "flex-start" }}>
                            <Flex
                                alignItems="center"
                                sx={{
                                    "&:hover": {
                                        textDecorationColor: "blue.100 !important",
                                        textDecoration: "underline",
                                    },
                                    "&:active": {
                                        textDecorationColor: "blue.70 !important",
                                        textDecoration: "underline",
                                    },
                                }}
                                gap={1}
                            >
                                <Text cursor="pointer" color={"blue.100"} textStyle={"ManropeSemiboldBodySmall"}>
                                    {t("learnMore", { ns: "common" })}
                                </Text>
                                <Icon as={MdOpenInNew} size={24} color="blue.100" />
                            </Flex>

                            <StandardButton display={{ md: "none" }} {...button}>
                                {button.children}
                            </StandardButton>
                        </Flex>
                    </Box>

                    <Flex
                        alignItems="center"
                        gap={2}
                        alignSelf="stretch"
                        flex={1}
                        justifyContent="flex-end"
                        w={`${SETTINGS_ROW_BUTTON_WIDTH}px`}
                        display={{ base: "none", md: "flex" }}
                    >
                        <Flex alignItems="center">
                            <StandardButton {...button}>{button.children}</StandardButton>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    )
}
