import { Link as ChakraLink, Flex, Icon, LinkProps, Text } from "@chakra-ui/react"
import { TextStyles } from "@theme/textStyles"
import { MdOpenInNew } from "react-icons/md"

type Props = {
    href: string
    text: string
    size?: "sm" | "lg"
    [rest: string]: any
    hasIcon?: boolean
    textStyles?: string
    embeddedLink?: boolean
} & LinkProps

export default function Link({ href, text, size = "lg", hasIcon, textStyles, embeddedLink = false, ...rest }: Props) {
    return (
        <ChakraLink
            href={href}
            target="_blank"
            sx={{
                "&:hover": {
                    textDecorationColor: "blue.100 !important",
                    textDecoration: "underline",
                    "& > span": {
                        color: "blue.100",
                    },
                },
                "&:active": {
                    textDecorationColor: "blue.70 !important",
                    textDecoration: "underline",
                    "& > span": {
                        color: "blue.70",
                    },
                },
            }}
            {...rest}
        >
            {embeddedLink ? (
                <Text
                    as="span"
                    textStyle={textStyles || size === "sm" ? "ManropeSemiboldXSmall" : "ManropeSemiboldBodySmall"}
                    color="blue.100"
                >
                    {text}
                </Text>
            ) : (
                <Flex align="center" columnGap="0.25rem">
                    <Text
                        as="span"
                        textStyle={textStyles || size === "sm" ? "ManropeSemiboldXSmall" : "ManropeSemiboldBodySmall"}
                        color="blue.100"
                    >
                        {text}
                    </Text>
                    {hasIcon && <Icon as={MdOpenInNew} color="blue.100" size={size === "sm" ? "12px" : "14px"} />}
                </Flex>
            )}
        </ChakraLink>
    )
}

export const InlineTextLink = ({
    children,
    textStyle,
    onClick,
}: {
    children: string
    textStyle: keyof typeof TextStyles
    onClick: () => void
}) => {
    return (
        <Text
            as="span"
            color="blue.100"
            cursor="pointer"
            _hover={{
                textDecorationColor: "blue.100 !important",
                textDecoration: "underline",
            }}
            _active={{
                textDecorationColor: "blue.70 !important",
                textDecoration: "underline",
            }}
            textStyle={textStyle}
            onClick={onClick}
        >
            {children}
        </Text>
    )
}
