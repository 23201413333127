import { useRef } from "react"

interface QueueItem<T extends string> {
    type: T
    action: () => Promise<any>
}

function useAjaxQueue() {
    const queueRef = useRef<QueueItem<string>[]>([])
    const processingRef = useRef(false)

    const processNextRequest = async () => {
        if (processingRef.current || queueRef.current.length === 0) {
            return
        }
        processingRef.current = true
        const queueItem = queueRef.current.shift()
        if (!queueItem) return

        try {
            await queueItem.action()
        } catch (error) {
            // no op
        } finally {
            processingRef.current = false
            if (queueRef.current.length > 0) {
                processNextRequest()
            }
        }
    }

    const addToQueue = <T extends string>(type: T, action: () => Promise<any>) => {
        if (queueRef.current.length === 0) {
            queueRef.current.push({ type, action })

            if (processingRef.current === false) {
                processNextRequest()
            }
        } else {
            const lastRequest = queueRef.current[queueRef.current.length - 1]
            if (lastRequest.type === type) {
                return
            } else {
                queueRef.current.pop()
            }
        }
    }

    return { addToQueue }
}

export default useAjaxQueue
