import { Box, Flex, Text } from "@chakra-ui/layout"
import { Skeleton } from "@chakra-ui/react"
import { MdInfoOutline } from "react-icons/md"

type ReceiptLineItemProps = {
    leftText?: string
    rightText?: string
    isLoadingRight?: boolean
    isLoadingLeft?: boolean
    bottomBorder?: boolean
    onInfoIconClick?: () => void
    size: "md" | "sm"
    color: "black" | "grey"
}
export const ReceiptLineItem: React.FC<ReceiptLineItemProps> = ({
    leftText,
    rightText,
    isLoadingLeft,
    isLoadingRight,
    bottomBorder,
    size,
    color,
    onInfoIconClick,
}) => {
    const textStyle = size === "sm" ? "ManropeSemiboldBodySmall" : "ManropeSemiboldBody"
    const textColor = color === "black" ? "black" : "grey.light.70"
    return (
        <Flex
            pb={bottomBorder ? 4 : 0}
            align={"center"}
            flexDirection={{ base: "column", xs: "row" }}
            justifyContent="space-between"
            alignItems={{ base: "stretch", xs: "center" }}
            borderBottomWidth={bottomBorder ? 1 : 0}
            width="100%"
            gap={{ base: "12px", xs: "1rem" }}
        >
            {leftText && (
                <Skeleton isLoaded={!isLoadingLeft}>
                    <Flex alignItems="center" width={"100%"}>
                        <Text color={textColor} textStyle={textStyle} textAlign="left">
                            {leftText}
                        </Text>
                        {onInfoIconClick && (
                            <Box
                                as="button"
                                onClick={onInfoIconClick}
                                cursor="pointer"
                                display="inline-flex"
                                alignItems="center"
                            >
                                <MdInfoOutline size={size == "sm" ? 16 : 12} />
                            </Box>
                        )}
                    </Flex>
                </Skeleton>
            )}
            {rightText && (
                <Skeleton isLoaded={!isLoadingRight}>
                    <Text color={textColor} textStyle={textStyle} textAlign="right" width="100%">
                        {rightText}
                    </Text>
                </Skeleton>
            )}
        </Flex>
    )
}
