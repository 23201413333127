import { Box, Text } from "@chakra-ui/react"
import SideDrawer from "@components/sideDrawer"
import StandardDrawerHeader from "@components/sideDrawer/headers"
import StandardButton from "@components/ui/buttons/standard"
import useGenericToast from "@hooks/useGenericToast"
import useRegion from "@hooks/useRegion"
import { removeCommasFromString } from "@util/numericalFormatting"
import { addCommasToNumber, formatDollar } from "@util/stringFormatting"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import Decimal from "decimal.js"
import { isNumber } from "lodash"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Withdraw from "../withdraw"
import { CryptoAmount } from "../withdraw/crypto/cryptoAmount"
import { CryptoConfirmation } from "../withdraw/crypto/cryptoConfirmation"
import { CryptoPreview } from "../withdraw/crypto/cryptoPreview"
import { CryptoSelection } from "../withdraw/crypto/cryptoSelection"
import { CryptoWallet } from "../withdraw/crypto/cryptoWallet"
import WithdrawFiatAmount, { MAX_WITHDRAW, MIN_WITHDRAW } from "../withdraw/fiatAmount"
import WithdrawInteracCompleted from "../withdraw/interac/completed"
import WithdrawInteracPreview from "../withdraw/interac/preview"
import { WithdrawTfa } from "../withdraw/tfa"
import WithdrawWireAddBank from "../withdraw/wire/addBank"
import WithdrawAddBankAdditional from "../withdraw/wire/addBankExtra"
import { WithdrawWireCompleted } from "../withdraw/wire/confirmation"
import { WithdrawWirePreview } from "../withdraw/wire/preview"
import WithdrawWireSelectBank from "../withdraw/wire/selectBank"
import { WithdrawWireDisclaimers } from "../withdraw/wire/wireDisclaimers"
import { DrawerWithdrawContext } from "./DrawerWithdrawContext"

export enum WithdrawType {
    INTERAC = "INTERAC",
    WIRE = "WIRE",
    CRYPTO = "CRYPTO",
}

export namespace WithdrawTabs {
    export enum INTERAC {
        ROOT = 0,
        INTERAC_AMOUNT = 1,
        INTERAC_PREVIEW = 2,
        INTERAC_TFA_CONFIRMATION = 3,
        INTERAC_CONFIRMATION = 4,
    }

    export enum WIRE {
        ROOT = 0,
        WIRE_DISCLAIMERS = 1,
        WIRE_SELECT_BANK = 2,
        WIRE_ADD_BANK = 3,
        WIRE_ADD_BANK_ADDITIONAL = 4,
        WIRE_AMOUNT = 5,
        WIRE_PREVIEW = 6,
        WIRE_TFA_CONFIRMATION = 7,
        WIRE_CONFIRMATION = 8,
    }

    export enum CRYPTO {
        ROOT = 0,
        CRYPTO_SELECTION = 1,
        CRYPTO_AMOUNT = 2,
        CRYPTO_WALLET = 3,
        CRYPTO_PREVIEW = 4,
        CRYPTO_TFA_CONFIRMATION = 5,
        CRYPTO_CONFIRMATION = 6,
    }
}

const maxViews = {
    [WithdrawType.INTERAC]: Math.max(
        ...(Object.values(WithdrawTabs.INTERAC).filter((value) => typeof value === "number") as number[])
    ),
    [WithdrawType.WIRE]: Math.max(
        ...(Object.values(WithdrawTabs.WIRE).filter((value) => typeof value === "number") as number[])
    ),
    [WithdrawType.CRYPTO]: Math.max(
        ...(Object.values(WithdrawTabs.CRYPTO).filter((value) => typeof value === "number") as number[])
    ),
}

const ComponentMap: Record<string, JSX.Element> = {
    [0]: <Withdraw />,
    [`${WithdrawType.CRYPTO}_${WithdrawTabs.CRYPTO.ROOT}`]: <Withdraw />,
    [`${WithdrawType.CRYPTO}_${WithdrawTabs.CRYPTO.CRYPTO_SELECTION}`]: <CryptoSelection />,
    [`${WithdrawType.CRYPTO}_${WithdrawTabs.CRYPTO.CRYPTO_AMOUNT}`]: <CryptoAmount />,
    [`${WithdrawType.CRYPTO}_${WithdrawTabs.CRYPTO.CRYPTO_WALLET}`]: <CryptoWallet />,
    [`${WithdrawType.CRYPTO}_${WithdrawTabs.CRYPTO.CRYPTO_PREVIEW}`]: <CryptoPreview />,
    [`${WithdrawType.CRYPTO}_${WithdrawTabs.CRYPTO.CRYPTO_TFA_CONFIRMATION}`]: <WithdrawTfa />,
    [`${WithdrawType.CRYPTO}_${WithdrawTabs.CRYPTO.CRYPTO_CONFIRMATION}`]: <CryptoConfirmation />,

    [`${WithdrawType.WIRE}_${WithdrawTabs.WIRE.ROOT}`]: <Withdraw />,
    [`${WithdrawType.WIRE}_${WithdrawTabs.WIRE.WIRE_DISCLAIMERS}`]: <WithdrawWireDisclaimers />,
    [`${WithdrawType.WIRE}_${WithdrawTabs.WIRE.WIRE_SELECT_BANK}`]: <WithdrawWireSelectBank />,
    [`${WithdrawType.WIRE}_${WithdrawTabs.WIRE.WIRE_ADD_BANK}`]: <WithdrawWireAddBank />,
    [`${WithdrawType.WIRE}_${WithdrawTabs.WIRE.WIRE_ADD_BANK_ADDITIONAL}`]: <WithdrawAddBankAdditional />,
    [`${WithdrawType.WIRE}_${WithdrawTabs.WIRE.WIRE_AMOUNT}`]: <WithdrawFiatAmount />,
    [`${WithdrawType.WIRE}_${WithdrawTabs.WIRE.WIRE_PREVIEW}`]: <WithdrawWirePreview />,
    [`${WithdrawType.WIRE}_${WithdrawTabs.WIRE.WIRE_TFA_CONFIRMATION}`]: <WithdrawTfa />,
    [`${WithdrawType.WIRE}_${WithdrawTabs.WIRE.WIRE_CONFIRMATION}`]: <WithdrawWireCompleted />,

    [`${WithdrawType.INTERAC}_${WithdrawTabs.INTERAC.ROOT}`]: <Withdraw />,
    [`${WithdrawType.INTERAC}_${WithdrawTabs.INTERAC.INTERAC_AMOUNT}`]: <WithdrawFiatAmount />,
    [`${WithdrawType.INTERAC}_${WithdrawTabs.INTERAC.INTERAC_PREVIEW}`]: <WithdrawInteracPreview />,
    [`${WithdrawType.INTERAC}_${WithdrawTabs.INTERAC.INTERAC_TFA_CONFIRMATION}`]: <WithdrawTfa />,
    [`${WithdrawType.INTERAC}_${WithdrawTabs.INTERAC.INTERAC_CONFIRMATION}`]: <WithdrawInteracCompleted />,
}

export interface DrawerWithdrawProps {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    withdrawType?: WithdrawType
}

const WithdrawDrawer: React.FC<DrawerWithdrawProps> = ({
    isOpen,
    toggleSideDrawerOpen,
    withdrawType,
}: DrawerWithdrawProps) => {
    const { t } = useTranslation(["transfer", "common"])
    const {
        currentWithdrawType,
        setCurrentWithdrawType,
        selectedWalletId,
        createWallet,
        selectedCoin,
        errorMessage,
        setErrorMessage,
        resetErrors,
        withdrawAmount,
        isButtonLoading,
        tfaCode,
        selectedBank,
        triggerAddBankForm,
        getAddBankFormValues,
        withdrawCrypto,
        withdrawWire,
        previewConfirm,
        isBelowMinWithdrawal,
        isAboveBalance,
        cadBalance,
        minWithdrawal,
        showSkipCheckAddr,
        skipCheckAddr,
        resetState,
        usWireDisclaimers,
        caWireDisclaimers,
        triggerAddBankFormExtra,
        getAddBankFormValuesExtra,
        limits,
        netFiatWithdrawAmount,
        setBankFromForm,
        view,
        setView,
        withdrawableBalance,
        isLoadingBalances,
        isLoadingBoundaries,
        executeInteracWithdraw,
        executeCryptoWithdraw,
        executeWireWithdraw,
    } = DrawerWithdrawContext.useContainer()
    const { isCAUser } = useRegion()

    useEffect(() => {
        if (withdrawType) {
            setView(1)
            resetState()
        }
        setCurrentWithdrawType(withdrawType)
    }, [withdrawType])

    const toggleDrawerOpen = () => {
        if (currentWithdrawType !== undefined && view === maxViews[currentWithdrawType]) {
            setView(0)
            resetState()
        }
        toggleSideDrawerOpen()
    }

    const handleCryptoNav = async () => {
        if (view === maxViews[WithdrawType.CRYPTO]) {
            setView(0)
            toggleDrawerOpen()
            resetState()
            return
        }

        if (view === WithdrawTabs.CRYPTO.CRYPTO_SELECTION && !selectedCoin) {
            setErrorMessage({ button: t("withdraw.errors.selectCryptoCurrency") })
            return
        }

        if (view === WithdrawTabs.CRYPTO.CRYPTO_AMOUNT) {
            if (!withdrawAmount) {
                setErrorMessage({ textField: t("withdraw.errors.inputAmount") })
                return
            }
            if (isBelowMinWithdrawal) {
                setErrorMessage({ textField: t("withdraw.errors.minAmount", { amount: minWithdrawal }) })
                return
            }
            if (isAboveBalance) {
                setErrorMessage({ textField: t("withdraw.errors.insufficientFunds") })
                return
            }
        }

        if (view === WithdrawTabs.CRYPTO.CRYPTO_WALLET) {
            if (selectedWalletId === undefined) {
                try {
                    await createWallet()
                } catch (error: any) {
                    if (error?.data?.message !== undefined) {
                        setErrorMessage({ button: error.data.message })
                        return
                    }
                    setErrorMessage({ button: t("common:error.message") })
                    return
                }
            }
            if (showSkipCheckAddr && !skipCheckAddr) {
                setErrorMessage({ button: t("withdraw.errors.allBoxes") })
                return
            }
        }

        if (view === WithdrawTabs.CRYPTO.CRYPTO_PREVIEW) {
            if (!previewConfirm) {
                setErrorMessage({ button: t("withdraw.errors.allBoxes") })
                return
            }
        }

        if (view === WithdrawTabs.CRYPTO.CRYPTO_TFA_CONFIRMATION) {
            try {
                executeCryptoWithdraw(tfaCode)
            } catch (error: any) {
                return
            }
        }

        setView((prev) => prev + 1)
    }

    const handleInteracNav = async () => {
        if (view === maxViews[WithdrawType.INTERAC]) {
            setView(0)
            toggleDrawerOpen()
            resetState()
            return
        }

        if (view === WithdrawTabs.INTERAC.INTERAC_AMOUNT) {
            if (!withdrawAmount) {
                setErrorMessage({ textField: t("withdraw.errors.inputAmount") })
                return
            }
            const withdrawAmountDecimal = new Decimal(removeCommasFromString(withdrawAmount))
            if (withdrawAmountDecimal.lessThan(new Decimal(MIN_WITHDRAW))) {
                setErrorMessage({ textField: t("withdraw.errors.minAmount", { amount: MIN_WITHDRAW }) })
                return
            }
            if (withdrawAmountDecimal.greaterThan(new Decimal(MAX_WITHDRAW))) {
                setErrorMessage({
                    textField: t("withdraw.errors.maxAmount", { amount: addCommasToNumber(MAX_WITHDRAW) }),
                })
                return
            }
            if (new Decimal(removeCommasFromString(withdrawAmount)).greaterThan(new Decimal(cadBalance))) {
                setErrorMessage({ textField: t("withdraw.errors.insufficientFunds") })
                return
            }
        }

        if (view === WithdrawTabs.INTERAC.INTERAC_TFA_CONFIRMATION) {
            try {
                await executeInteracWithdraw(tfaCode)
            } catch (error) {
                return
            }
        }

        setView((prev) => prev + 1)
    }

    const handleWireNav = async (skipAdditionalBank?: boolean) => {
        if (view === maxViews[WithdrawType.WIRE]) {
            toggleDrawerOpen()
            resetState()
            return
        }

        if (view === WithdrawTabs.WIRE.WIRE_DISCLAIMERS) {
            const disclaimers = isCAUser ? caWireDisclaimers : usWireDisclaimers
            const allDisclaimersAccepted = Object.values(disclaimers).every((disclaimer) => disclaimer)
            if (!allDisclaimersAccepted) {
                setErrorMessage({ button: t("withdraw.errors.allBoxes") })
                return
            }
        }

        if (view === WithdrawTabs.WIRE.WIRE_SELECT_BANK) {
            if (!selectedBank) {
                setErrorMessage({ button: t("withdraw.errors.selectBank") })
                return
            }
            setView(WithdrawTabs.WIRE.WIRE_AMOUNT)
            return
        }

        if (view === WithdrawTabs.WIRE.WIRE_ADD_BANK) {
            const res = await triggerAddBankForm()
            if (res) {
                const values = getAddBankFormValues()
                if (values.swift) {
                    values.swift = values.swift.toUpperCase()
                }
                if (!isCAUser && values.type.value === "international") {
                    setView(WithdrawTabs.WIRE.WIRE_ADD_BANK_ADDITIONAL)
                } else {
                    setBankFromForm(values)
                    setView(WithdrawTabs.WIRE.WIRE_AMOUNT)
                }
            }
            // if res is false, react-hook-form will handle the error messages
            return
        }

        if (view === WithdrawTabs.WIRE.WIRE_ADD_BANK_ADDITIONAL) {
            if (skipAdditionalBank) {
                const bankValues = getAddBankFormValues()
                setBankFromForm(bankValues)
                setView(WithdrawTabs.WIRE.WIRE_AMOUNT)
                return
            }
            const extraBankForm = await triggerAddBankFormExtra()
            if (extraBankForm) {
                const extraValues = getAddBankFormValuesExtra()
                const bankValues = getAddBankFormValues()
                setBankFromForm({ ...bankValues, ...extraValues })
                setView(WithdrawTabs.WIRE.WIRE_AMOUNT)
            }
            // if res is false, react-hook-form will handle the error messages
            return
        }

        const withdrawNum = Number(removeCommasFromString(withdrawAmount ?? ""))
        if (view === WithdrawTabs.WIRE.WIRE_AMOUNT) {
            if (isLoadingBalances || isLoadingBoundaries) {
                return
            }
            const minWithdraw = Number(limits?.wire.withdraw.minimum ?? "0")
            const maxWithdraw = Number(limits?.wire.withdraw.maximum ?? "0")
            const fee = Number(limits?.wire.withdraw.fee ?? 0)
            if (maxWithdraw > 0 && withdrawNum > maxWithdraw) {
                setErrorMessage({
                    textField: t("withdraw.errors.maxAmount", { amount: formatDollar(maxWithdraw) }),
                })
                return
            }
            if (withdrawNum > withdrawableBalance) {
                setErrorMessage({
                    textField: t("withdraw.errors.maxAmount", { amount: formatDollar(withdrawableBalance) }),
                })
                return
            }
            if (withdrawNum < minWithdraw) {
                setErrorMessage({ textField: t("withdraw.errors.minAmount", { amount: formatDollar(minWithdraw) }) })
                return
            }
            if (netFiatWithdrawAmount < 0) {
                setErrorMessage({
                    textField: t("withdraw.errors.minAmount", { amount: formatDollar(fee) }),
                })
                return
            }
        }

        if (view === WithdrawTabs.WIRE.WIRE_PREVIEW) {
            if (!previewConfirm) {
                setErrorMessage({ button: t("withdraw.errors.allBoxes") })
                return
            }
        }
        if (view === WithdrawTabs.WIRE.WIRE_TFA_CONFIRMATION) {
            try {
                executeWireWithdraw(tfaCode)
            } catch (e) {
                return
            }
        }

        setView((prev) => prev + 1)
    }

    const handleButtonClick = async () => {
        resetErrors()

        if (!currentWithdrawType) {
            setErrorMessage({ button: t("withdraw.errors.withdrawMethod") })
            return
        }

        switch (currentWithdrawType) {
            case WithdrawType.CRYPTO:
                handleCryptoNav()
                break
            case WithdrawType.INTERAC:
                handleInteracNav()
                break
            case WithdrawType.WIRE:
                handleWireNav()
                break
        }
    }

    const RenderView = () => {
        if (!currentWithdrawType) {
            return ComponentMap[0]
        }

        const key = `${currentWithdrawType}_${view}`
        return ComponentMap[key]
    }

    const ButtonText = useMemo(() => {
        if (!currentWithdrawType) {
            return t("common:continue")
        }

        if (view === maxViews[currentWithdrawType as WithdrawType]) {
            return t("common:done")
        }

        return t("common:continue")
    }, [view])

    const handleBackNavigation = () => {
        if (currentWithdrawType !== undefined && view === maxViews[currentWithdrawType]) {
            setView(0)
            toggleDrawerOpen()
            resetState()
            return
        }
        // skip the the 'add new bank' forms even if they were previoulsy completed
        if (view === WithdrawTabs.WIRE.WIRE_AMOUNT) {
            setView(WithdrawTabs.WIRE.WIRE_SELECT_BANK)
            resetErrors()
            return
        }
        setView((prev) => prev - 1)
        resetErrors()
    }

    const showSkipButton = useMemo(() => {
        return view === WithdrawTabs.WIRE.WIRE_ADD_BANK_ADDITIONAL && currentWithdrawType === WithdrawType.WIRE
    }, [view])

    const hideBackButton = useMemo(() => {
        const currentMaxViews = maxViews[currentWithdrawType as WithdrawType]
        return view === 0 || (isNumber(currentMaxViews) && view === currentMaxViews)
    }, [view])

    return (
        <SideDrawer
            isOpen={isOpen}
            toggleSideDrawerOpen={toggleSideDrawerOpen}
            size="md"
            header={
                <StandardDrawerHeader
                    onBack={hideBackButton ? undefined : handleBackNavigation}
                    onClose={toggleDrawerOpen}
                />
            }
            footerButton={{
                variant: ENUM_BUTTON_VARIANTS.PRIMARY_SOLID,
                onClick: handleButtonClick,
                children: ButtonText,
                isLoading: isButtonLoading,
                type: "button",
            }}
            extraFooter={
                <Box display={"flex"} justifyContent={"center"}>
                    {errorMessage.button && (
                        <Text color={"red.light.100"} textStyle={"InterRegularBody"} mb={3}>
                            {errorMessage.button}
                        </Text>
                    )}
                    {showSkipButton && (
                        <StandardButton
                            w="full"
                            mb="10px"
                            children={t("common:skip")}
                            variant={ENUM_BUTTON_VARIANTS.SECONDARY_SOLID}
                            onClick={() => handleWireNav(true)}
                        />
                    )}
                </Box>
            }
        >
            {RenderView()}
        </SideDrawer>
    )
}

const WithdrawDrawerHOC: React.FC<DrawerWithdrawProps> = (props) => (
    <DrawerWithdrawContext.Provider initialState={props}>
        <WithdrawDrawer {...props} />
    </DrawerWithdrawContext.Provider>
)

export default WithdrawDrawerHOC
