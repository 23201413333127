import { appendVersion } from "@util/RTKApi"

export const FUND_TAG_TYPES = {
    DEPOSIT_ADDRESS: "depositAddress",
    CA_WIRE_DEPOSIT: "caWireDeposit",
} as const

export const API_PATH = {
    DEPOSIT_ADDRESS: appendVersion("deposit"),
    CA_WIRE_DEPOSIT: appendVersion("fund/wire-details"),
    US_WIRE_DEPOSIT: appendVersion("account/bank/deposit"),
} as const
