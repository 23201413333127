import { Flex, Text, VStack } from "@chakra-ui/react"
import LoadingSideDrawer from "@components/loading"
import RadioGroup, { RadioGenericItem } from "@components/ui/radio"
import { Option } from "@components/ui/types"
import useRegion from "@hooks/useRegion"
import COUNTRIES_NO_OFAC_OPTIONS from "@json/countriesNoOFAC.json"
import REGIONS from "@json/regions.json"
import { useLazyGetCABanksQuery, useLazyGetUSBanksQuery } from "@redux/withdraw/apiSlice"
import { BankDetails } from "@redux/withdraw/types"
import { WithdrawTabs } from "@screens/dashboard/transfer/drawerWithdraw"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { MdOutlineAdd } from "react-icons/md"

type Props = {}

type FormData = {
    bank: Option<string>
}

export default function WithdrawWireSelectBank({}: Props) {
    const { t } = useTranslation("transfer")
    const { control, watch, reset } = useForm<FormData>()
    const { setSelectedBank, setView } = DrawerWithdrawContext.useContainer()
    const { isCAUser, currency } = useRegion()

    const [fetchCABanks, { data: caBanksData, isFetching: isFetchingCaBanks }] = useLazyGetCABanksQuery({})
    const [fetchUSBanks, { data: usBanksData, isFetching: isFetchingUSBanks }] = useLazyGetUSBanksQuery()

    useEffect(() => {
        if (isCAUser) {
            fetchCABanks(currency || "CAD")
        } else {
            fetchUSBanks()
        }
        setSelectedBank(undefined)
    }, [])

    const bankOptions = useMemo(() => {
        if (isCAUser && caBanksData) {
            return Object.entries(caBanksData).map(([key, bank]: [string, BankDetails]) => ({
                label: bank.nickname ?? bank.bank_name,
                value: key,
            }))
        } else if (usBanksData) {
            return usBanksData.accounts.map((bank) => ({
                label: bank.name ?? bank.bank_name,
                value: bank.id,
            }))
        }
        return []
    }, [caBanksData, usBanksData, isCAUser])

    // ensure that selectedBank in Context and the local form state are in sync
    useEffect(() => {
        const val = watch("bank")?.value as string | undefined
        //Map remote data to form data
        if (isCAUser) {
            if (!caBanksData || !val) {
                return
            }
            const bankData = caBanksData?.[Number(val)]
            const country = COUNTRIES_NO_OFAC_OPTIONS.find(
                (c) => c.value === bankData.bank_country || c.label === bankData.bank_country
            )
            const province = REGIONS[country?.value as keyof typeof REGIONS]?.find(
                (v) => v.value.split("-")[1] === bankData.bank_province
            )
            const bankType =
                bankData.swift_code !== undefined && bankData.swift_code !== "" ? "international" : "canadian"
            if (!bankData || !country || !province) {
                return
            }

            setSelectedBank({
                ...bankData,
                institution: bankData.institution_number,
                transit: bankData.transit_number,
                routing: bankData.routing_number,
                account: bankData.account_number,
                bank_country: country,
                type: {
                    value: bankType,
                    label: bankType,
                },
                nickname: bankData.nickname || "",
                bank_province: province,
                bank_id: val,
            })
        } else {
            if (!usBanksData || !val) {
                return
            }
            const bankData = usBanksData.accounts.find((v) => v.id === val)
            if (!bankData) {
                return
            }
            const bankType =
                bankData.intermediary_bank_account_number !== undefined ||
                bankData.correspondent_bank_account_number !== undefined
                    ? "international"
                    : "domestic"
            const extraType =
                bankData.correspondent_bank_account_number !== undefined
                    ? "correspondent"
                    : bankData.intermediary_bank_account_number !== undefined
                      ? "intermediary"
                      : undefined
            setSelectedBank({
                ...bankData,
                type: {
                    value: bankType,
                    label: bankType,
                },
                bank_id: bankData.id,
                swift: bankData.swift_code,
                routing: bankData.routing_number,
                account: bankData.account_number,
                nickname: bankData.name,
                bank_name: bankData.bank_name,
                beneficiary: bankData.beneficiary.name,
                extra_account: bankData.correspondent_bank_account_number || bankData.intermediary_bank_account_number,
                extra_name: bankData.correspondent_bank_name || bankData.intermediary_bank_name,
                extra_swift: bankData.correspondent_bank_code || bankData.intermediary_bank_code,
                extra_type:
                    extraType !== undefined
                        ? {
                              value: extraType,
                              label: extraType,
                          }
                        : undefined,
            })
        }
    }, [watch("bank")])

    const handleAddNewBankClick = () => {
        setView(WithdrawTabs.WIRE.WIRE_ADD_BANK)
        reset()
    }

    return (
        <Flex flex={1} flexDir="column">
            <Flex align="center" h="56px" w="full" my="1.75rem">
                <Text textStyle="ManropeSemiboldXLarge" color="black">
                    {t("withdraw.wire.title")}
                </Text>
            </Flex>
            <Text textStyle="ManropeSemiboldBody" color="black">
                {t("withdraw.wire.subtitle")}
            </Text>
            {isFetchingCaBanks || isFetchingUSBanks ? (
                <LoadingSideDrawer text={t("withdraw.wire.fetchingAccounts")} />
            ) : (
                <VStack mt={6} gap={3}>
                    {bankOptions.length > 0 && (
                        <RadioGroup name="bank" options={bankOptions} isHorizontal={false} control={control} />
                    )}

                    <RadioGenericItem
                        option={{ value: 0, label: t("withdraw.wire.addAccount") }}
                        hideOverflow={false}
                        isChecked={false}
                        onClick={() => handleAddNewBankClick()}
                        icon={MdOutlineAdd}
                    />
                </VStack>
            )}
        </Flex>
    )
}
