import { appendVersion } from "@util/RTKApi"

export const SLICE_NAME = "withdraw"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const WITHDRAW_TAG_TYPES = {
    BOUNDARIES: "boundaries",
    FEES: "fees",
    BANKS_CA: "banks_ca",
    BANKS_US: "banks_us",
} as const

export const API_PATH = {
    WITHDRAW_INTERAC: appendVersion("withdraw/fiat"),
    BOUNDARIES: appendVersion("boundaries"),
    FEES: appendVersion("fees"),
    BANKS_CA: appendVersion("withdraw/dest"),
    BANKS_US: appendVersion("account/bank/withdrawal"),
    VALIDATE_ADDRESS: appendVersion("checkaddr"),
    WITHDRAW_CRYPTO: appendVersion("withdraw"),
    WIRE_WITHDRAW_CA: appendVersion("withdraw/wire"),
    WIRE_WITHDRAW_US: appendVersion("transfers/wire/withdraw"),
} as const
