import { API_PATH } from "./const"
import { FetchLimitsParams, LimitsResponse } from "./types"
import { splitApi } from "@redux/splitApi"

export const limitsApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getLimits: builder.query<LimitsResponse, FetchLimitsParams>({
            query: (params) => ({
                url: `${API_PATH.FETCH_LIMITS}/${params.currency}`,
                method: "GET",
            }),
        }),
    }),
})

export const { useGetLimitsQuery } = limitsApi
