export const BreakPoints = {
    base: "0em", // 0px
    sm: "30em", // ~480px
    smmd: "40em", // ~640px,
    md: "48em", // ~768px
    lg: "62em", // ~992px
    xl: "80em", // ~1280px
    "2xl": "96em", // ~1536px
    "3xl": "120rem", // ~1920px
}
