// Centralized route segments for easier lookup and debugging
export const ROUTE_SEGMENTS = {
    ROOT: "/",
    DASHBOARD: "dashboard",
    LANDING: "landing",
    TWO_FA: "twofa",
    TRADE: "trade",
    TRANSFER: "transfer",
    NETCOINS_PAY: "netcoins-pay",
    ONBOARDING: "onboarding",
    TRANSACTIONS: "transactions",
    OPEN_ORDERS: "open-orders",
    PROFILE: "profile",
    DOCUMENTS: "documents",
    SAVED_ADDRESSES_FIAT: "saved-fiat",
    SAVED_ADDRESSES_CRYPTO: "saved-crypto",
    NOTIFICATIONS: "notifications",
    ACCOUNT_ACTIVITY: "activity",
    AUTHORIZED_DEVICES: "authorized-devices",
    SETTINGS: "settings",
    FAQ: "faq",
    BUY_WITH_CREDIT: "buy-with-credit",
}

export enum RouteParams {
    ASSET = "asset",
    TRANSACTION_ID = "transactionId",
}

// Compose routes using segments:
const DASHBOARD = `/${ROUTE_SEGMENTS.DASHBOARD}`
const LANDING = `/${ROUTE_SEGMENTS.LANDING}`
const TWO_FA = `/${ROUTE_SEGMENTS.TWO_FA}`
const ONBOARDING = `/${ROUTE_SEGMENTS.ONBOARDING}`
const DASHBOARD_TRADE = `${DASHBOARD}/${ROUTE_SEGMENTS.TRADE}`
const DASHBOARD_TRANSFER = `${DASHBOARD}/${ROUTE_SEGMENTS.TRANSFER}`
const DASHBOARD_NETCOINS_PAY = `${DASHBOARD}/${ROUTE_SEGMENTS.NETCOINS_PAY}`
const PROFILE = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.PROFILE}`
const DOCUMENTS = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.DOCUMENTS}`
const SAVED_ADDRESSES_FIAT = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.SAVED_ADDRESSES_FIAT}`
const SAVED_ADDRESSES_CRYPTO = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.SAVED_ADDRESSES_CRYPTO}`
const ACCOUNT_ACTIVITY = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.ACCOUNT_ACTIVITY}`
const AUTHORIZED_DEVICES = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.AUTHORIZED_DEVICES}`
const BUY_WITH_CREDIT = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.BUY_WITH_CREDIT}`
const NOTIFICATIONS = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.NOTIFICATIONS}`
const DASHBOARD_SETTINGS = `${DASHBOARD}/${ROUTE_SEGMENTS.SETTINGS}`
const DASHBOARD_FAQ = `${DASHBOARD}/${ROUTE_SEGMENTS.FAQ}`
const MARKET_DETAILS = `${DASHBOARD}/${ROUTE_SEGMENTS.TRADE}/:${RouteParams.ASSET}`
const ASSET_TRANSACTIONS = `${MARKET_DETAILS}/${ROUTE_SEGMENTS.TRANSACTIONS}`
const ASSET_OPEN_ORDERS = `${MARKET_DETAILS}/${ROUTE_SEGMENTS.OPEN_ORDERS}`

// Export routes for use in components
export const ROUTES = {
    DASHBOARD,
    LANDING,
    TWO_FA,
    ONBOARDING,
    DASHBOARD_TRADE,
    DASHBOARD_TRANSFER,
    DASHBOARD_NETCOINS_PAY,
    DASHBOARD_SETTINGS,
    MARKET_DETAILS,
    ASSET_TRANSACTIONS,
    ASSET_OPEN_ORDERS,
    PROFILE,
    NOTIFICATIONS,
    DASHBOARD_FAQ,
    DOCUMENTS,
    SAVED_ADDRESSES_CRYPTO,
    SAVED_ADDRESSES_FIAT,
    ACCOUNT_ACTIVITY,
    AUTHORIZED_DEVICES,
    BUY_WITH_CREDIT,
}
