import { useState, useEffect, useRef } from "react"
import { Box, IconButton, HStack, Text, useClipboard, Fade, Skeleton } from "@chakra-ui/react"
import { MdContentCopy } from "react-icons/md"
import theme, { TextStyles } from "@theme/index"

interface CopyComponentProps {
    text: string
    isLoading?: boolean
}

const CopyToast = () => (
    <Box
        position="absolute"
        bottom="100%"
        left="50%"
        transform="translateX(-50%)"
        mb="2.5"
        p="2"
        bg={theme.colors.grey.light[10]}
        borderRadius="md"
        fontSize="sm"
        whiteSpace="nowrap"
        _after={{
            content: '""',
            position: "absolute",
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            border: "6px solid transparent",
            borderTopColor: "gray.100",
        }}
    >
        <Text textStyle={"InterRegularXSmall"}>Copied</Text>
    </Box>
)

const truncateMiddle = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text
    const start = text.slice(0, Math.ceil(maxLength / 2))
    const end = text.slice(-Math.floor(maxLength / 2))
    return `${start}...${end}`
}

const measureTextWidth = (text: string, font: string) => {
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")
    if (context) {
        context.font = font
        return context.measureText(text).width
    }
    return 0
}

const CopyComponent = ({ text = "ABCDEFG", isLoading }: CopyComponentProps) => {
    const { onCopy } = useClipboard(text)
    const [showToast, setShowToast] = useState(false)
    const [truncatedText, setTruncatedText] = useState(text)
    const containerRef = useRef<HTMLDivElement>(null)
    const iconRef = useRef<HTMLButtonElement>(null)

    const handleCopy = () => {
        onCopy()
        setShowToast(true)
        setTimeout(() => setShowToast(false), 2000)
    }

    useEffect(() => {
        const updateTruncatedText = () => {
            if (containerRef.current && iconRef.current) {
                const containerWidth = containerRef.current.clientWidth
                const iconWidth = iconRef.current.offsetWidth // Account for the icon's width
                const availableWidth = containerWidth - iconWidth - 60 // Subtract padding and margins

                // Dynamically calculate the font style and size
                const fontStyle = TextStyles.ManropeMediumBody
                const font = `${fontStyle.fontWeight} ${fontStyle.fontSize} ${fontStyle.fontFamily}`

                // Measure the width of the actual text content
                const textWidth = measureTextWidth(text, font)

                // Account for the ellipsis (approximate width of "...")
                const ellipsisWidth = measureTextWidth("...", font)

                // Adjust available width to leave space for ellipsis
                const adjustedAvailableWidth = availableWidth - ellipsisWidth

                // Calculate max length based on the available width and text width
                const maxLength = Math.floor(adjustedAvailableWidth / (textWidth / text.length))

                // Adjust maxLength to account for the added "..."
                const correctedMaxLength = maxLength - 3 // The ellipsis adds 3 characters

                // Only truncate if the text is too long for the available width
                if (textWidth > availableWidth) {
                    setTruncatedText(truncateMiddle(text, correctedMaxLength))
                } else {
                    setTruncatedText(text) // Display the full text if it fits
                }
            }
        }

        updateTruncatedText()
        window.addEventListener("resize", updateTruncatedText)
        return () => window.removeEventListener("resize", updateTruncatedText)
    }, [text])

    return (
        <HStack
            spacing={2}
            px={4}
            borderWidth="1px"
            borderRadius={6}
            borderColor={"grey.light.10"}
            bg="white"
            justifyContent={"space-between"}
            minH={"48px"}
            ref={containerRef}
            alignItems="center"
            position="relative"
            w="100%"
            maxW="100%"
        >
            {isLoading ? (
                <Skeleton flex={1} height={"5"} />
            ) : (
                <Text textStyle={"ManropeMediumBody"} whiteSpace="nowrap" overflow="hidden" flex="1">
                    {truncatedText}
                </Text>
            )}
            <Box position="relative">
                <IconButton
                    _hover={{
                        background: "none",
                    }}
                    size="sm"
                    icon={<MdContentCopy size={20} />}
                    onClick={handleCopy}
                    aria-label={"copy"}
                    color={"black"}
                    variant="ghost"
                    sx={{
                        padding: 0,
                        margin: 0,
                        minWidth: "auto",
                    }}
                    ref={iconRef}
                />
                <Fade in={showToast}>
                    <CopyToast />
                </Fade>
            </Box>
        </HStack>
    )
}

export default CopyComponent
