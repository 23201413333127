import React from "react"
import { Box, Text } from "@chakra-ui/react"
import TextInput from "@/components/ui/textInput"
import { useForm } from "react-hook-form"
import { SubScreenProps } from ".."
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { useTranslation } from "react-i18next"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import DetailsTypeCard from "@components/ui/badge/detailsType"

type FormData = {
    day: number
    month: number
    year: number
}

export default function WhenIncorporated({ submitSubForm, setUserObj, userObj }: SubScreenProps) {
    const { handleSubmit, control } = useForm<FormData>(formatDefaultValue())
    const { t } = useTranslation(["onboarding", "common"])

    function formatDefaultValue() {
        const dateInc = userObj?.business?.date_incorporated
        if (dateInc) {
            const dateStringUTC = dateInc.toISOString().slice(0, 10)
            return {
                defaultValues: {
                    day: Number(dateStringUTC.substring(8, 10)) || undefined,
                    month: Number(dateStringUTC.substring(5, 7)) || undefined,
                    year: Number(dateStringUTC.substring(0, 4)) || undefined,
                },
            }
        }
        return undefined
    }

    async function onSubmit(values: FormData) {
        const day = values.day < 10 && values.day.toString().substring(0, 1) !== "0" ? `0${values.day}` : values.day
        const month =
            values.month < 10 && values.month.toString().substring(0, 1) !== "0" ? `0${values.month}` : values.month
        const dateIncorporated = `${values.year}-${month}-${day}`

        await submitSubForm("/api/v2/account/business", { dateIncorporated }, () => {
            setUserObj((prev) =>
                prev ? { ...prev, business: { ...prev.business, date_incorporated: new Date(dateIncorporated) } } : prev
            )
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.BusinessRole, REGISTRATION_STEP.BUSINESS_ROLE)
        })
    }

    return (
        <Box w="full">
            <DetailsTypeCard isPersonal={false} mb="0.75rem" />
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("extra.whenIncorporated")}
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box w="full" display="flex" flexDir={{ base: "column", lg: "row" }} gap="1.5rem">
                    <TextInput
                        name="day"
                        label={t("day", { ns: "common" })}
                        placeholder="DD"
                        control={control}
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            min: { value: 1, message: t("whenBorn.dayZero") },
                            max: { value: 31, message: t("whenBorn.day31") },
                            maxLength: { value: 2, message: t("whenBorn.day31") },
                        }}
                        maxLength={2}
                        size="lg"
                    />
                    <TextInput
                        name="month"
                        label={t("month", { ns: "common" })}
                        placeholder="MM"
                        control={control}
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            min: { value: 1, message: t("whenBorn.monthZero") },
                            max: { value: 12, message: t("whenBorn.month12") },
                            maxLength: { value: 2, message: t("whenBorn.month12") },
                            pattern: {
                                value: /^\+?[0-9]+$/,
                                message: t("error.numbersOnly", { ns: "common" }),
                            },
                        }}
                        maxLength={2}
                        size="lg"
                    />
                    <TextInput
                        name="year"
                        label={t("year", { ns: "common" })}
                        placeholder="YYYY"
                        control={control}
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            max: {
                                value: new Date().getFullYear(),
                                message: t("whenBorn.future"),
                            },
                            min: {
                                value: 1000,
                                message: t("whenBorn.1000"),
                            },
                            maxLength: { value: 4, message: t("whenBorn.4digits") },
                            pattern: {
                                value: /^\+?[0-9]+$/,
                                message: t("error.numbersOnly", { ns: "common" }),
                            },
                        }}
                        size="lg"
                    />
                </Box>
                <StandardButton
                    data-testid="continue-button"
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    mt="2.5rem"
                    w="full"
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
