import { Flex, Text } from "@chakra-ui/react"
import Select from "@components/ui/select"
import TextInput from "@components/ui/textInput"
import { useTranslation } from "react-i18next"
import { AddBankProps } from "."
import { REGEX_NUMERICAL } from "@util/regexes"
import BankAddressFields from "../bankAddressFields"

export default function AddCanadianBank({ control, watch, getValues, setValues }: AddBankProps) {
    const { t } = useTranslation(["transfer", "common"])

    const isBankTypeSelected = !!watch("type")?.value
    const isCanadianType = watch("type")?.value === "canadian"
    const isIntlType = watch("type")?.value === "international"

    return (
        <Flex flexDir="column" rowGap={"1.5rem"}>
            <Select
                key="type"
                name="type"
                control={control}
                options={[
                    { value: "canadian", label: t("withdraw.wire.canadian") },
                    { value: "international", label: t("withdraw.wire.international") },
                ]}
                label={t("withdraw.wire.bankType")}
                rules={{ required: t("error.requiredOption", { ns: "common" }) }}
            />
            {isBankTypeSelected && (
                <>
                    <TextInput
                        name="bank_name"
                        control={control}
                        label={t("withdraw.wire.bankName")}
                        placeholder="Ex. BigBank"
                        rules={{ required: t("error.required", { ns: "common" }) }}
                    />
                    <BankAddressFields
                        control={control}
                        watch={watch}
                        getValues={getValues}
                        setValues={setValues}
                        isCanadian={isCanadianType}
                    />
                    <Text textStyle="ManropeSemiboldBody">{t("withdraw.wire.enterDetails")}</Text>
                    {isCanadianType ? (
                        <>
                            <TextInput
                                key="institution"
                                name="institution"
                                control={control}
                                label={t("withdraw.wire.instNum")}
                                placeholder="Ex. 012"
                                maxLength={3}
                                rules={
                                    isCanadianType
                                        ? {
                                              required: t("error.required", { ns: "common" }),
                                              minLength: { value: 3, message: t("common:error.minLength", { len: 3 }) },
                                              maxLength: { value: 3, message: t("common:error.maxLength", { len: 3 }) },
                                              pattern: {
                                                  value: REGEX_NUMERICAL,
                                                  message: t("common:error.numbersOnly"),
                                              },
                                          }
                                        : { required: false }
                                }
                            />
                            <TextInput
                                key="transit"
                                name="transit"
                                control={control}
                                label={t("withdraw.wire.transitNum")}
                                placeholder="Ex. 30800"
                                maxLength={5}
                                rules={
                                    isCanadianType
                                        ? {
                                              required: t("error.required", { ns: "common" }),
                                              pattern: {
                                                  value: REGEX_NUMERICAL,
                                                  message: t("common:error.numbersOnly"),
                                              },
                                              minLength: { value: 5, message: t("common:error.minLength", { len: 5 }) },
                                              maxLength: { value: 5, message: t("common:error.maxLength", { len: 5 }) },
                                          }
                                        : { required: false }
                                }
                            />
                        </>
                    ) : isIntlType ? (
                        <TextInput
                            key="swift"
                            name="swift"
                            control={control}
                            label={t("withdraw.wire.swiftCode")}
                            placeholder="Ex. AAAA-BB-CC-123"
                            rules={
                                isIntlType ? { required: t("error.required", { ns: "common" }) } : { required: false }
                            }
                        />
                    ) : null}
                    <TextInput
                        key="account"
                        name="account"
                        control={control}
                        label={t("withdraw.wire.accountNum")}
                        placeholder="Ex. 122105155"
                        maxLength={12}
                        rules={{
                            required: t("error.required", { ns: "common" }),
                            pattern: { value: REGEX_NUMERICAL, message: t("common:error.numbersOnly") },
                            minLength: { value: 7, message: t("common:error.minLength", { len: 7 }) },
                            maxLength: { value: 12, message: t("common:error.maxLength", { len: 12 }) },
                        }}
                    />
                    <TextInput
                        key="nickname"
                        name="nickname"
                        control={control}
                        label={t("withdraw.wire.nickname")}
                        placeholder="Ex. Chase checking"
                        maxLength={35}
                        rules={{ required: t("error.required", { ns: "common" }) }}
                    />
                </>
            )}
        </Flex>
    )
}
