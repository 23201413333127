import TabsSliding from "@components/ui/tabs/sliding"

type Props = { options: [string, string]; isActive: boolean; toggleActive: () => void }

export const BasicToggle = ({ options, isActive, toggleActive }: Props) => {
    return (
        <TabsSliding
            options={options.map((op) => ({ value: op, label: op }))}
            activeOption={isActive ? options[0] : options[1]}
            setActiveOption={toggleActive}
            size="md"
        />
    )
}
