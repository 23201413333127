import { useAppSelector } from "@/store/hooks"
import { selectAccountDetails } from "@redux/account/selectors"

const useRegion = () => {
    const accountDetails = useAppSelector(selectAccountDetails)

    const country = accountDetails?.country
    const currency = accountDetails?.currency

    const getBaseCountryUri = (uri: string) => {
        if (!country) {
            return uri
        }

        return `${uri}?c=${country}`
    }

    return {
        isCAUser: country === "CA",
        country,
        currency,
        getBaseCountryUri,
    }
}

export default useRegion
