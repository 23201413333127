export const Colors = {
    white: "#FFFFFF",
    alert: {
        error: "#C62828",
        warning: "#FFC255",
        success: "#2CA900",
        info: "blue",
    },
    black: "#000000",
    grey: {
        dark: {
            10: "#F2F2F7",
            20: "#E5E5EA",
            30: "#D1D1D6",
            40: "#C7C7CC",
            50: "#AEAEB2",
            60: "#8E8E93",
            70: "#636366",
            80: "#48484A",
            90: "#3A3A3C",
            100: "#1C1C1E",
        },
        light: {
            2: "#FAFAFB",
            5: "#F4F4F4",
            10: "#E8E8E8",
            20: "#D2D2D2",
            30: "#BBBBBC",
            40: "#A4A4A5",
            50: "#8D8D8E",
            60: "#777778",
            70: "#606061",
            80: "#49494B",
            90: "#333335",
            100: "#1C1C1E",
        },
    },
    darkBlue: {
        10: "#E6E8EF",
        20: "#CCD2E0",
        30: "#B3BBD1",
        40: "#99A4C1",
        50: "#808EB1",
        60: "#6677A2",
        70: "#4D6092",
        80: "#334A83",
        90: "#1A3373",
        100: "#001C64",
    },
    blue: {
        10: "#ECF0FE",
        20: "#D8E0FD",
        30: "#C5D1FC",
        40: "#B1C1FB",
        50: "#9EB2FA",
        60: "#8BA3FA",
        70: "#7793F9",
        80: "#6484F8",
        90: "#5074F7",
        100: "#3D65F6",
    },
    bluePurple: {
        10: "#E6E6EA",
        20: "#CDCDD5",
        30: "#B5B5C0",
        40: "#9C9CAB",
        50: "#838396",
        60: "#6A6A82",
        70: "#51516D",
        80: "#393958",
        90: "#202043",
        100: "#07072E",
        200: "#48485C",
    },
    purple: {
        10: "#EAEAF2",
        20: "#D6D5E6",
        30: "#C1C0D9",
        40: "#ADABCC",
        50: "#9896BF",
        60: "#8382B3",
        70: "#6F6DA6",
        80: "#5A5899",
        90: "#46438D",
        100: "#312E80",
    },
    green: {
        light: {
            10: "#E6F2E6",
            20: "#CCE5CD",
            30: "#B5DBBD",
            40: "#9ACA9B",
            50: "#80BD82",
            60: "#67B06A",
            70: "#4EA351",
            80: "#359538",
            90: "#1B881F",
            100: "#027B06",
        },
        dark: {
            10: "#EAF6E6",
            20: "#D5EECC",
            30: "#C0E5B3",
            40: "#ABDD99",
            50: "#95D480",
            60: "#80CB66",
            70: "#6BC34D",
            80: "#56BA33",
            90: "#41B21A",
            100: "#2CA900",
            200: "#182D1D",
        },
    },
    red: {
        light: {
            10: "#F8E5E5",
            20: "#F1CCCC",
            30: "#EAB3B3",
            40: "#E39999",
            50: "#DC8080",
            60: "#D56666",
            70: "#CE4C4C",
            80: "#C73333",
            90: "#C01A1A",
            100: "#B90000",
        },
        dark: {
            10: "#FCE6E7",
            20: "#F8CDD0",
            30: "#F5B4B8",
            40: "#F19BA0",
            50: "#EE8288",
            60: "#EA6971",
            70: "#E65059",
            80: "#E33741",
            90: "#DF1E2A",
            100: "#DC0512",
            200: "#560005",
            300: "#352823",
        },
        40: "#E8A9A9",
        70: "#9B2C2C",
        100: "#C62828",
    },
    yellow: {
        light: {
            10: "#FEF7E5",
            20: "#FCEFCC",
            30: "#FBE7B3",
            40: "#FADF99",
            50: "#F9D680",
            60: "#F7CE66",
            70: "#F6C64C",
            80: "#F5BE33",
            90: "#F3B61A",
            100: "#F2AE00",
            200: "#9C5F02",
        },
        dark: {
            10: "#FFF7E9",
            20: "#FFEFD4",
            30: "#FFE8BE",
            40: "#FFE0A8",
            50: "#FFD893",
            60: "#FFD07D",
            70: "#FFC967",
            80: "#FFC152",
            90: "#FFB93C",
            100: "#FFB127",
            200: "#724900",
        },
        100: "#FFC255",
        90: "#FFC866",
    },
}
