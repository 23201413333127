import { appendVersion } from "@util/RTKApi"

export const SLICE_NAME = "transactions"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const TRANSACTIONS_TAG_TYPES = {
    TRANSACTIONS: "transactions",
} as const

export const API_PATH = {
    TRANSACTIONS: appendVersion("transactions"),
    ORDERS: appendVersion("orders"),
} as const
