import { Box, Text } from "@chakra-ui/react"
import { SubScreenProps } from "screens/onboarding"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import DetailsTypeCard from "@components/ui/badge/detailsType"
import RadioGroup from "components/ui/radio"
import AmplitudeClient from "sdks/amplitude"
import { REGISTRATION_STEP, REG_SCREENS } from "@screens/onboarding/types"

type Props = {} & SubScreenProps

type FormData = {
    is_money_service: { value: number; label: string }
}

export default function IsMoneyService({ submitSubForm, isLoading, setUserObj, userObj }: Props) {
    const { t } = useTranslation(["onboarding", "common"])
    const { handleSubmit, control } = useForm<FormData>({
        defaultValues:
            userObj?.business?.is_money_service === null
                ? undefined
                : {
                      is_money_service: userObj?.business.is_money_service
                          ? { value: 1, label: t("yes", { ns: "common" }) }
                          : { value: 0, label: t("no", { ns: "common" }) },
                  },
    })

    async function onSubmitForm(values: FormData) {
        const payload = { is_money_service: values.is_money_service.value } as {
            is_money_service: 0 | 1
        }
        await submitSubForm("/api/v2/account/business", payload, () => {
            setUserObj((prev) => (prev ? { ...prev, business: { ...prev.business, ...payload } } : prev))
            AmplitudeClient.logRegistrationEvent(
                REG_SCREENS.IsMoneyService,
                REGISTRATION_STEP.BUSINESS_IS_MONEY_SERVICE
            )
        })
    }

    return (
        <Box w="full">
            <DetailsTypeCard isPersonal={false} />
            <Text textStyle="ManropeMediumXLarge" mb="1.5rem">
                {t("isMoneyService.title")}
            </Text>
            <Text textStyle="InterRegularBodySmall" mb="1rem">
                {t("isMoneyService.desc")}
            </Text>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <RadioGroup
                    isHorizontal
                    control={control}
                    name="is_money_service"
                    options={[
                        { value: 1, label: t("yes", { ns: "common" }) },
                        { value: 0, label: t("no", { ns: "common" }) },
                    ]}
                    rules={{ required: t("error.required", { ns: "common" }) }}
                    mb="3rem"
                />
                <StandardButton
                    data-testid="continue-button"
                    isLoading={isLoading}
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                    w="full"
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
