import { Box, Text, Flex } from "@chakra-ui/react"
import { useMemo } from "react"
import BreadCrumbGroup from "@components/ui/breadcrumbs/breadcrumb/group"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import { AiOutlineInfoCircle } from "react-icons/ai"
import useColorFormatConverter from "@theme/useColorFormatConverter"
import Select from "@components/ui/select"
import { useForm } from "react-hook-form"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { IoShieldCheckmarkOutline } from "react-icons/io5"
import CopyComponent from "@components/ui/copyField"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useTranslation } from "react-i18next"
import DetailsTypeCard from "@components/ui/badge/detailsType"
import useRestrictedNavigation from "@hooks/useRestrictNavigation"
import { ROUTES } from "@routing/routes"
import DashboardFooter from "@components/footer/dashboard"
import Link, { InlineTextLink } from "@components/ui/link"
import useSupport from "@hooks/useSupport"
import { selectAccountDetails } from "@redux/account/selectors"
import { useAppSelector } from "@/store/hooks"

type FormData = {
    input: string
}

export default function Profile(): JSX.Element {
    const colorConverter = useColorFormatConverter()
    const { control } = useForm<FormData>()
    const { t } = useTranslation("settings")
    const { navigate } = useRestrictedNavigation()
    const { supportEmail, USSupport } = useSupport()
    const accountDetails = useAppSelector(selectAccountDetails)

    const isPersonal = useMemo(() => true, [])

    return (
        <Box>
            <BreadCrumbGroup
                breadCrumbs={[
                    {
                        text: t("profile.settings"),
                        hasAfter: true,
                        onClick: () => navigate(ROUTES.DASHBOARD_SETTINGS),
                    },
                    {
                        text: t("profile.titleLower"),
                        hasAfter: false,
                    },
                ]}
                py={2}
            />

            <Text textStyle="ManropeMedium4xLarge">{t("profile.title")}</Text>

            <Box my={8} borderBottomWidth={1} />

            <Flex flexDir="column" alignItems={{ lg: "center" }} gap={8} mb={"1.5rem"}>
                <Flex flexDir="column" gap={4}>
                    <Flex alignItems="stretch" justifyContent="space-between" gap={2}>
                        <Text textStyle="ManropeSemiboldXLarge">Jon Snow</Text>
                        <Flex flexDir="column" justifyContent="flex-end" textAlign="end">
                            <Box>{!isPersonal && <DetailsTypeCard isPersonal={isPersonal} />}</Box>
                            <Text textStyle="InterRegularBody">jonsnow@winterfell.com</Text>
                            {isPersonal && <Text textStyle="InterRegularBody">(647)-897-2345</Text>}
                        </Flex>
                    </Flex>

                    <Box py={1} borderBottomWidth={1} />

                    <Flex alignItems="stretch" gap={2} justifyContent="space-between">
                        <Text textStyle="ManropeSemiboldBody">{t("profile.accountNumber")} :</Text>
                        <Text textStyle="ManropeSemiboldBody">123456789</Text>
                    </Flex>

                    <Box py={1} />

                    <Flex alignItems="stretch" gap={2} justifyContent="space-between" pb={4} borderBottomWidth={1}>
                        <Text textStyle="ManropeSemiboldBody">{t("profile.address")} :</Text>
                        <Flex flexDir="column" justifyContent="flex-end" textAlign="end">
                            <Text textStyle="InterRegularBody">200-2850 Iceridge Blvd NE</Text>
                            <Text textStyle="InterRegularBody">Calgary, AB</Text>
                            <Text textStyle="InterRegularBody">Canada</Text>
                            <Text textStyle="InterRegularBody">T1Y 6G2</Text>
                        </Flex>
                    </Flex>

                    <InfoAlert
                        variant={ENUM_INFO_ALERT_VARIANT.ORANGE}
                        children={
                            <Text>
                                <Text as="span" textStyle="InterRegularBodySmall">
                                    {t("profile.infoText")}&nbsp;
                                </Text>
                                <InlineTextLink
                                    onClick={() =>
                                        window.open(
                                            `mailto:${accountDetails?.country === "CA" ? supportEmail : USSupport}`
                                        )
                                    }
                                    textStyle="InterRegularBodySmall"
                                >
                                    {t("profile.contact")}
                                </InlineTextLink>
                            </Text>
                        }
                        icon={{
                            icon: () => <AiOutlineInfoCircle color={colorConverter("yellow.light.100")} size={24} />,
                        }}
                    />

                    <Box py={0.5} />

                    <Flex flexDir="column" mb={4}>
                        <Select
                            name="timezone"
                            options={[
                                {
                                    label: "Canada",
                                    value: "Canada",
                                },
                                {
                                    label: "China",
                                    value: "China",
                                },
                            ]}
                            label="Timezone"
                            control={control}
                        />
                    </Flex>

                    <Flex
                        flexDir="column"
                        px={8}
                        py={6}
                        borderRadius="6px"
                        borderColor="grey.light.10"
                        borderWidth="1px"
                        gap={6}
                        alignSelf="stretch"
                        mb={4}
                    >
                        <Flex alignItems="flex-start" flexDir={{ base: "column", md: "row" }} gap={3}>
                            <Flex
                                h={{
                                    md: "42px",
                                }}
                                w={{
                                    base: "100%",
                                    md: "42px",
                                }}
                                flexDirection="column"
                                justifyContent="center"
                                alignItems={"center"}
                                bg="green.light.10"
                                borderRadius="md"
                                padding={{
                                    base: "5px 0",
                                    sm: "24px 0",
                                    lg: "unset",
                                }}
                            >
                                <ReactIcon icon={IoShieldCheckmarkOutline} size={24} color={"green.light.100"} />
                            </Flex>

                            <Flex flexDir="column">
                                <Text textStyle="ManropeSemiboldBody">{t("profile.security.title")}</Text>
                                <Text textStyle="InterRegularBodySmall">{t("profile.security.text")}</Text>
                            </Flex>
                        </Flex>

                        <Flex
                            gap={6}
                            alignSelf="stretch"
                            flexDir={{ base: "column", md: "row" }}
                            alignItems="flex-start"
                            w="100%"
                        >
                            <Flex flexDir="column" gap={3} flex={1} w="100%">
                                <Text textStyle="InterRegularBody">{t("profile.security.question")}:</Text>
                                <Box>
                                    <CopyComponent text="parent gentle math" />
                                </Box>
                            </Flex>

                            <Flex flexDir="column" gap={3} flex={1} w="100%">
                                <Text textStyle="InterRegularBody">{t("profile.security.answer")}:</Text>
                                <Box>
                                    <CopyComponent text="parent gentle math" />
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex justifyContent="space-between">
                        <StandardButton
                            children={t("profile.closeAccount")}
                            variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                        />

                        <StandardButton
                            children={t("profile.saveChanges")}
                            disabled
                            variant={ENUM_BUTTON_VARIANTS.PRIMARY_SOLID}
                        />
                    </Flex>
                </Flex>
            </Flex>
            <DashboardFooter />
        </Box>
    )
}
