import { useMemo } from "react"
import { Button, ButtonProps, Icon, forwardRef } from "@chakra-ui/react"
import ICON_BUTTON_VARIANTS, { ENUM_ICON_BUTTON_VARIANTS, ICON_BUTTON_ICON_BORDER_RADIUS } from "./types"
import { ICON_BUTTON_ICON_SIZE, ICON_BUTTON_SIZE } from "./types"
import { IconType } from "react-icons"

type Props = {
    variant: ENUM_ICON_BUTTON_VARIANTS
    onClick?: () => void
    name: string
    size?: keyof typeof ICON_BUTTON_SIZE
    disabled?: boolean
    isLoading?: boolean
    iconOutline: IconType
    icon: IconType
    [rest: string]: any
}

const IconButton = forwardRef<Props, "button">((props, ref) => {
    const { variant, onClick, name, size = "lg", disabled, isLoading, iconOutline, icon, ...rest } = props

    const palette = useMemo(() => ICON_BUTTON_VARIANTS[variant], [variant])

    const { normal, hover, disabled: disabledColor, active, borderWidth } = palette

    return (
        <Button
            ref={ref}
            data-test-id={`icon-button-${name}`}
            onClick={onClick}
            bg={normal.bg}
            isDisabled={disabled || isLoading}
            _disabled={disabledColor}
            _hover={!disabled ? hover : {}}
            _active={!disabled ? active : {}}
            borderRadius={`${ICON_BUTTON_ICON_BORDER_RADIUS[size]}px`}
            borderWidth={`${borderWidth}px`}
            borderColor={normal.borderColor}
            isLoading={isLoading}
            _loading={normal}
            size={size}
            w="fit-content"
            {...rest}
            p={0}
            cursor={!disabled ? "pointer" : "not-allowed"}
            role="group"
        >
            <Icon
                as={iconOutline}
                color={disabled ? disabledColor.color : normal.color}
                display="block"
                h={`${ICON_BUTTON_ICON_SIZE[size]}px`}
                w={`${ICON_BUTTON_ICON_SIZE[size]}px`}
                _groupHover={{ display: disabled ? "block" : "none" }}
                _groupActive={{ display: disabled ? "block" : "none" }}
            />
            <Icon
                as={icon}
                h={`${ICON_BUTTON_ICON_SIZE[size]}px`}
                w={`${ICON_BUTTON_ICON_SIZE[size]}px`}
                display="none"
                _groupHover={{ display: disabled ? "none" : "block", color: hover.color }}
                _groupActive={{ display: disabled ? "none" : "block", color: active.color }}
            />
        </Button>
    )
})

export default IconButton
