import { useAppSelector } from "@/store/hooks"
import { Box, Flex, Text } from "@chakra-ui/react"
import Checkbox from "@components/ui/checkbox"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import { ReceiptLineItem } from "@components/ui/lineItems/receiptLineItem"
import useRegion from "@hooks/useRegion"
import REGIONS from "@json/regions.json"
import { selectAccountDetails } from "@redux/account/selectors"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { removeCommasFromString } from "@util/numericalFormatting"
import { formatDollar } from "@util/stringFormatting"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineReport } from "react-icons/md"

const CABankDetails: React.FC = () => {
    const { t } = useTranslation(["transfer", "common"])
    const { selectedBank } = DrawerWithdrawContext.useContainer()
    const accountDetails = useAppSelector(selectAccountDetails)

    const beneficiaryAddress = useMemo(() => {
        if (!accountDetails?.identity.address) {
            return ""
        }
        const address = accountDetails.identity.address
        return `${address.street_address}\n${address.city}\n${address.region}\n${address.postal_code}`
    }, [accountDetails])

    const fullName = useMemo(() => {
        if (!accountDetails?.identity) {
            return ""
        }
        return `${accountDetails.identity.first_name} ${accountDetails.identity.last_name}`.trim()
    }, [accountDetails])

    const bankAddress = useMemo(() => {
        if (!selectedBank || !selectedBank.bank_country) {
            return ""
        }

        const bankRegion = REGIONS[selectedBank.bank_country.value as keyof typeof REGIONS]?.find(
            (r) => r.value === selectedBank.bank_province?.value
        )

        return `${selectedBank.bank_address}\n${selectedBank.bank_city}\n${bankRegion?.label ?? ""}\n${selectedBank.bank_zip_code}`
    }, [selectedBank])

    return (
        <>
            <ReceiptLineItem
                size="md"
                color="black"
                leftText={t("withdraw.wire.nameOnBankAccount")}
                rightText={fullName}
                bottomBorder
            />

            <Flex mt={4} alignItems="flex-start" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem">
                <Text textStyle="ManropeSemiboldBodySmall">{t("withdraw.wire.beneficiaryAddress")}</Text>
                <Text textAlign={"right"} whiteSpace="pre-line" textStyle="InterRegularBodySmall">
                    {beneficiaryAddress}
                </Text>
            </Flex>

            <Box mt={4} />

            <Flex mt={4} alignItems="flex-start" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem">
                <Text textStyle="ManropeSemiboldBodySmall">{t("withdraw.wire.bankDetails")}</Text>
                <Text textAlign={"right"} whiteSpace="pre-line" textStyle="InterRegularBodySmall">
                    {bankAddress}
                </Text>
            </Flex>

            <Box my={4} borderTop={"1px"} color={"grey.light.10"} />

            <Flex flexDirection={"column"} gap={2}>
                {selectedBank?.type.value === "canadian" ? (
                    <>
                        <ReceiptLineItem
                            size="sm"
                            color="black"
                            leftText={t("withdraw.wire.instNum")}
                            rightText={selectedBank.institution}
                        />
                        <ReceiptLineItem
                            size="sm"
                            color="black"
                            leftText={t("withdraw.wire.transitNum")}
                            rightText={selectedBank.transit}
                        />
                    </>
                ) : (
                    <ReceiptLineItem
                        size="sm"
                        color="black"
                        leftText={t("withdraw.wire.swiftCode")}
                        rightText={selectedBank?.swift}
                    />
                )}
                <ReceiptLineItem
                    size="sm"
                    color="black"
                    leftText={t("withdraw.wire.accountNum")}
                    rightText={selectedBank?.account}
                    bottomBorder
                />
            </Flex>
        </>
    )
}

const USBankDetails: React.FC = () => {
    const { t } = useTranslation(["transfer", "common"])
    const { selectedBank } = DrawerWithdrawContext.useContainer()
    const accountDetails = useAppSelector(selectAccountDetails)

    const fullName = useMemo(() => {
        if (selectedBank?.beneficiary) {
            const [start, ...rest] = selectedBank.beneficiary.split(" ").filter(Boolean)
            return `${start} ${rest.join(" ")}`.trim()
        }
        if (accountDetails?.identity) {
            return `${accountDetails.identity.first_name} ${accountDetails.identity.last_name}`.trim()
        }
        return ""
    }, [selectedBank])

    return (
        <>
            <ReceiptLineItem
                size="md"
                color="black"
                leftText={t("withdraw.wire.nameOnBankAccount")}
                rightText={fullName}
                bottomBorder
            />

            <Flex mt={4} flexDirection={"column"} gap={2}>
                <ReceiptLineItem
                    size="sm"
                    color="black"
                    leftText={t("withdraw.wire.swiftCode")}
                    rightText={selectedBank?.swift}
                />
                <ReceiptLineItem
                    size="sm"
                    color="black"
                    leftText={t("withdraw.wire.routing")}
                    rightText={selectedBank?.routing}
                />
                <ReceiptLineItem
                    size="sm"
                    color="black"
                    leftText={t("withdraw.wire.accountNum")}
                    rightText={selectedBank?.account}
                    bottomBorder
                />
            </Flex>

            {selectedBank?.type.value === "international" && selectedBank.extra_type !== undefined && (
                <Flex mt={4} flexDirection={"column"} gap={2}>
                    <ReceiptLineItem
                        size="sm"
                        color="black"
                        leftText={t("withdraw.wire.subBankType")}
                        rightText={selectedBank.extra_type.label}
                    />
                    <ReceiptLineItem
                        size="sm"
                        color="black"
                        leftText={t("withdraw.wire.subBankName")}
                        rightText={selectedBank.extra_name}
                    />
                    <ReceiptLineItem
                        size="sm"
                        color="black"
                        leftText={t("withdraw.wire.subBankCode")}
                        rightText={selectedBank.extra_swift}
                    />
                    <ReceiptLineItem
                        size="sm"
                        color="black"
                        leftText={t("withdraw.wire.accountNum")}
                        rightText={selectedBank.extra_account}
                        bottomBorder
                    />
                </Flex>
            )}
        </>
    )
}

export const WithdrawWirePreview: React.FC = () => {
    const { t } = useTranslation(["transfer", "common"])
    const { isCAUser, currency } = useRegion()

    const { previewConfirm, setPreviewConfirm, limits, netFiatWithdrawAmount, withdrawAmount } =
        DrawerWithdrawContext.useContainer()

    return (
        <Box overflowY={"auto"} mt={7} flex={1}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("withdraw.wire.title")}</Text>
            <Flex mt={10} flexDirection={"column"} gap={2}>
                <Text textStyle="ManropeSemiboldBody">{t("withdraw.wire.pleaseEnsureCorrect")}</Text>
                <Text textStyle="InterRegularBodySmall">{t("withdraw.wire.wireFinalWarning")}</Text>
            </Flex>
            <Box mt={6} p={4} borderWidth={1} borderColor="grey.light.10" borderRadius={4}>
                {isCAUser ? <CABankDetails /> : <USBankDetails />}
                <Flex mt={6} flexDirection={"column"} gap={1}>
                    <InfoAlert
                        icon={{
                            icon: MdOutlineReport,
                        }}
                        variant={ENUM_INFO_ALERT_VARIANT.ORANGE}
                    >
                        {t("withdraw.wire.currencyWarning", { currency: currency })}
                    </InfoAlert>
                </Flex>
            </Box>
            <Flex flexDirection={"column"} gap={2} mt={6}>
                <ReceiptLineItem
                    size="sm"
                    color="grey"
                    leftText={t("withdraw.wire.amountToWithdraw")}
                    rightText={formatDollar(removeCommasFromString(withdrawAmount ?? ""))}
                />
                <ReceiptLineItem
                    size="sm"
                    color="grey"
                    leftText={t("withdraw.wire.feeAmount")}
                    rightText={formatDollar(limits?.wire.withdraw.fee ?? "0")}
                />
            </Flex>

            <Flex my={6} py={6} borderTopWidth="1px" borderBottomWidth="1px" borderColor="grey.light.10">
                <ReceiptLineItem
                    size="md"
                    color="black"
                    leftText={t("withdraw.wire.youWillReceive")}
                    rightText={formatDollar(netFiatWithdrawAmount)}
                />
            </Flex>

            <Flex flexDirection={"column"} gap={2} mb={6}>
                <ReceiptLineItem
                    size="sm"
                    color="grey"
                    leftText={t("withdraw.wire.withdrawalMethod")}
                    rightText={t("withdraw.wire.withdrawalMethodValue")}
                />
                <ReceiptLineItem
                    size="sm"
                    color="grey"
                    leftText={t("withdraw.wire.processingTime")}
                    rightText={t("withdraw.wire.processingTimeValue")}
                />
            </Flex>
            <Checkbox
                text={t("withdraw.wire.withdrawAcknowledgement")}
                name="previewConfirm"
                isChecked={previewConfirm}
                setValue={(isChecked) => setPreviewConfirm(isChecked)}
            />
        </Box>
    )
}
