import { appendVersion } from "@util/RTKApi"

export const SLICE_NAME = "orders"
export const API_SLICE_NAME = `${SLICE_NAME}-api`

export const ORDERS_TAG_TYPES = {
    ORDERS_ID: "orders_id",
    QUOTE: "quote",
    LIMIT_QUOTE: "limit_quote",
} as const

export const API_PATH = {
    QUOTE: appendVersion("quote"),
    LIMIT_QUOTE: appendVersion("order/limit/preview"),
    CREATE_LIMIT_ORDER: appendVersion("order"),
    CREATE_INSTANT_ORDER: appendVersion("execute"),
} as const

// This is not available through any API, so it's unfortunately hardcoded here
// TODO: Make this configurable via ENV
export const MINIMUM_ORDER_FIAT_AMOUNT = 10
