import { Box, Image, Text, Link } from "@chakra-ui/react"
import useImage from "@hooks/useImage"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { Transaction } from "@redux/transactions/types"
import { FC, useMemo } from "react"
import { formatNumberFixedPrecision } from "@util/numericalFormatting"
import { useTranslation } from "react-i18next"
import { isFiatCurrency } from "@util/currencyHelpers"
import IconWrapper from "@assets/svgs/IconWrapper"
import { formatShortDateTime } from "@util/dateFormating"
import { ENUM_CHIP_VARIANTS } from "@components/ui/badge/types"
import Chip from "@components/ui/badge"
import { isProcessingState } from "../../helper"
import { MdIosShare } from "react-icons/md"
import { imageMap } from "@assets/svgs/coins"

export const WithdrawDetails: FC<{ transaction: Transaction }> = ({ transaction }) => {
    const {
        asset,
        created_at_pst,
        decrease_currency,
        decrease_amount,
        transaction_hash,
        transaction_link,
        wallet_link,
        wallet,
        wallet_label,
        notes,
        status,
    } = transaction

    const fiatCurrency = isFiatCurrency(asset)

    const { data: assetDetailsData } = useGetAssetsDetailsQuery(undefined)

    const selectedAsset = useMemo(() => {
        if (!isFiatCurrency(asset) && assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const { symbol } = selectedAsset || {}

    const image = imageMap[symbol?.toLowerCase() as keyof typeof imageMap]

    const { t } = useTranslation(["common", "transactions"])

    const processing = isProcessingState(status)

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Text textStyle={"ManropeSemiboldBody"}>{t("transactions:transactionDetails.withdrawn")} :</Text>
            <Box borderBottom={"1px solid"} borderColor={"grey.dark.10"} paddingTop={3} />
            {!fiatCurrency && selectedAsset && (
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    py={6}
                    alignItems={"center"}
                    borderBottom={"1px solid"}
                    borderColor={"grey.dark.10"}
                >
                    {image && (
                        <Box h="62px" w="62px" borderRadius="31px" overflow="hidden">
                            <Image src={image} alt={"coin" + symbol?.toLowerCase()} height={"100%"} width={"100%"} />
                        </Box>
                    )}
                    <Box display={"flex"} flexDirection={"column"} flex={1} mx={3}>
                        <Text textStyle={"ManropeMediumXLarge"}>{selectedAsset?.name}</Text>
                        <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.dark.70"}>
                            {symbol}
                        </Text>
                    </Box>
                    {processing && (
                        <Box alignSelf={"center"}>
                            <Chip variant={ENUM_CHIP_VARIANTS.YELLOW} text="Processing" />
                        </Box>
                    )}
                </Box>
            )}
            <Box
                display={"flex"}
                flexDirection={"column"}
                py={6}
                borderBottom={"1px solid"}
                borderColor={"grey.dark.10"}
            >
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                    <Text textStyle={"ManropeSemiboldBody"}>{t("transactions:transactionDetails.amount")}</Text>
                    <Text textAlign={"right"} textStyle={"ManropeSemiboldBody"}>
                        {`${formatNumberFixedPrecision(decrease_amount, Number(selectedAsset?.precision) || 2, true)} ${decrease_currency}`}
                    </Text>
                </Box>
            </Box>
            {!fiatCurrency && selectedAsset && (transaction_hash || wallet_link) && (
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    py={6}
                    borderBottom={"1px solid"}
                    borderColor={"grey.dark.10"}
                >
                    {wallet_link && (
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>
                                {t("transactions:transactionDetails.toWallet")}
                            </Text>
                            <Link
                                href={wallet_link}
                                isExternal
                                _hover={{ textDecoration: "none" }}
                                color={"blue.100"}
                                mt={2.5}
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                            >
                                <Text color={"inherit"} textStyle={"ManropeSemiboldBodySmall"} wordBreak={"break-all"}>
                                    {`${wallet}${wallet_label ? ` (${wallet_label})` : ""}`}
                                </Text>
                                <IconWrapper icon={MdIosShare} size={16} color="blue.100" />
                            </Link>
                        </Box>
                    )}
                    {transaction_link && (
                        <Box display={"flex"} flexDirection={"column"} mt={3}>
                            <Text textStyle={"ManropeSemiboldBodySmall"}>
                                {t("transactions:transactionDetails.txHash")}
                            </Text>
                            <Link
                                href={transaction_link}
                                isExternal
                                _hover={{ textDecoration: "none" }}
                                color={"blue.100"}
                                mt={2.5}
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                            >
                                <Text color={"inherit"} textStyle={"ManropeSemiboldBodySmall"} wordBreak={"break-all"}>
                                    {transaction_hash}
                                </Text>
                                <IconWrapper icon={MdIosShare} size={16} color="blue.100" />
                            </Link>
                        </Box>
                    )}
                </Box>
            )}
            {!fiatCurrency && selectedAsset && notes && (
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    py={6}
                    borderBottom={"1px solid"}
                    borderColor={"grey.dark.10"}
                >
                    <Box display={"flex"} flexDirection={"column"}>
                        <Text textStyle={"ManropeSemiboldBodySmall"}>{t("common:notes")}</Text>
                        <Text mt={2.5} textStyle={"InterRegularBodySmall"}>
                            {notes}
                        </Text>
                    </Box>
                </Box>
            )}
            <Box display={"flex"} flexDirection={"column"} py={6}>
                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} mt={3}>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"}>
                        {t("common:date")}
                    </Text>
                    <Text textStyle={"ManropeSemiboldBodySmall"} color={"grey.light.70"} textAlign={"right"}>
                        {formatShortDateTime(new Date(created_at_pst))}
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
