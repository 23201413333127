import { imageMap } from "@assets/svgs/coins"
import { Box, Flex, Image, Text } from "@chakra-ui/react"
import Checkbox from "@components/ui/checkbox"
import { ReceiptLineItem } from "@components/ui/lineItems/receiptLineItem"
import useImage from "@hooks/useImage"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { useTranslation } from "react-i18next"

export const CryptoPreview: React.FC = () => {
    const { t } = useTranslation(["transfer", "common"])

    const {
        selectedAssetDetails,
        withdrawAmount,
        cryptoFees,
        getNetWithdrawCryptoAmount,
        previewConfirm,
        setPreviewConfirm,
        selectedWalletDetails,
        walletForm,
    } = DrawerWithdrawContext.useContainer()
    const image = imageMap[selectedAssetDetails?.symbol.toLowerCase() as keyof typeof imageMap]

    const note = walletForm.watch("note")
    return (
        <Box overflowY={"auto"}>
            <Box mt={7} flex={1} mb={6}>
                <Text textStyle={"ManropeSemiboldXLarge"}>{t("withdraw.crypto.title")}</Text>
                <Box mt={6} />
                <Text textStyle={"ManropeSemiboldBody"}>{t("withdraw.crypto.reviewWithdraw")}</Text>
                <Flex alignItems="center" justifyContent={"space-between"} mb="0.75rem" columnGap="0.5rem">
                    <Text textStyle="ManropeSemiboldBodySmall">{t("withdraw.crypto.yourWithdrawalRequest")}</Text>
                    {image && <Image src={image} alt={selectedAssetDetails?.symbol} w="3rem" h="3rem" />}
                </Flex>
                <Flex
                    flexDirection={"column"}
                    my={6}
                    py={6}
                    borderTopWidth="1px"
                    borderBottomWidth={"1px"}
                    borderColor="grey.light.10"
                    gap={3}
                >
                    <ReceiptLineItem
                        size="sm"
                        color="grey"
                        leftText={t("withdraw.crypto.amount")}
                        rightText={`${withdrawAmount} ${selectedAssetDetails?.symbol}`}
                    />

                    <ReceiptLineItem
                        size="sm"
                        color="grey"
                        leftText={t("withdraw.crypto.withdrawalFee")}
                        rightText={`${cryptoFees} ${selectedAssetDetails?.symbol}`}
                    />
                </Flex>

                <ReceiptLineItem
                    size="md"
                    color="black"
                    leftText={t("withdraw.crypto.youWillReceive")}
                    rightText={`${getNetWithdrawCryptoAmount(withdrawAmount || "")} ${selectedAssetDetails?.symbol}`}
                />

                <Flex mt={6} flexDirection={"column"} gap={1}>
                    <ReceiptLineItem
                        size="md"
                        color="black"
                        leftText={t("withdraw.crypto.withdrawTo")}
                        rightText={selectedWalletDetails?.label}
                    />
                    <Flex flex={1} flexDirection={"row"} justifyContent={"flex-end"}>
                        <Text textStyle={"InterSemiboldXSmall"}> {selectedWalletDetails?.address}</Text>
                    </Flex>
                </Flex>

                {note && (
                    <Flex
                        flexDirection={"column"}
                        borderRadius="4px"
                        w="full"
                        p="1rem"
                        borderColor="grey.light.10"
                        borderWidth={1}
                        mt={6}
                        gap={2}
                    >
                        <Text textStyle="ManropeSemiboldBody">{t("withdraw.crypto.notes")}</Text>
                        <Text textStyle="InterRegularBodySmall">{note}</Text>
                    </Flex>
                )}
            </Box>
            <Checkbox
                text={t("withdraw.crypto.withdrawAcknowledgement")}
                name="previewConfirm"
                isChecked={previewConfirm}
                setValue={(isChecked) => setPreviewConfirm(isChecked)}
            />
        </Box>
    )
}
