import { Box, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import ReactIcon from "@assets/svgs/ReactIconWrapper"
import { MdCheck, MdOutlineVerifiedUser } from "react-icons/md"
import Link from "@components/ui/link"

export default function TwoFASetupSuccess() {
    const { t } = useTranslation(["app", "common"])

    return (
        <Box mt={7}>
            <Flex alignItems={"center"}>
                <Text textStyle={"ManropeSemiboldXLarge"} flex={1}>
                    {t("twoFASetup.setupSuccessful", { ns: "twoFA" })}
                </Text>
                <Box
                    borderRadius={100}
                    bg={"green.dark.100"}
                    w={"48px"}
                    h={"48px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <ReactIcon icon={MdCheck} size={20} color="white" />
                </Box>
            </Flex>
            <Text mt={6} textStyle={"ManropeSemiboldBody"}>
                {t("twoFASetup.successMessage", { ns: "twoFA" })}
            </Text>
        </Box>
    )
}
