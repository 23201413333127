import { Spinner, Center } from "@chakra-ui/react"
import { Navigate } from "react-router-dom"

export const DataGuard = ({
    isLoading,
    hasAccess,
    fallbackRoute = "/",
    children,
}: {
    isLoading: boolean
    hasAccess: boolean
    error?: string | null
    fallbackRoute?: string
    children: React.ReactElement
}) => {
    if (isLoading) {
        return (
            <Center height="100vh">
                <Spinner size="xl" />
            </Center>
        )
    }

    if (!hasAccess) {
        return <Navigate to={fallbackRoute} replace />
    }

    return children
}
