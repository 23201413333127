import { Box, Text } from "@chakra-ui/react"
import useRegion from "@hooks/useRegion"
import { useTranslation } from "react-i18next"
import { WithdrawWireCADisclaimers } from "./ca"
import { WithdrawWireUSDisclaimers } from "./us"

export const WithdrawWireDisclaimers: React.FC = () => {
    const { t } = useTranslation(["transfer"])

    const { isCAUser } = useRegion()

    return (
        <Box mt={7} flex={1} overflowY={"auto"}>
            <Text textStyle={"ManropeSemiboldXLarge"}>{t("withdraw.wire.title")}</Text>
            <Box mt={6} />
            <Text textStyle="ManropeSemiboldBody" color="black">
                {t("withdraw.wire.disclaimersTitle")}
            </Text>

            {isCAUser ? <WithdrawWireCADisclaimers /> : <WithdrawWireUSDisclaimers />}
        </Box>
    )
}
