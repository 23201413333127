import authReducer from "@redux/auth/slice"
import { combineReducers } from "@reduxjs/toolkit"
import { splitApi } from "@redux/splitApi"

const appReducer = combineReducers({
    [splitApi.reducerPath]: splitApi.reducer,
    auth: authReducer,
})

export type RootState = ReturnType<typeof appReducer>

export default appReducer
