import { Box, VStack } from "@chakra-ui/layout"
import Checkbox from "@components/ui/checkbox"
import InfoAlert from "@components/ui/infoAlert"
import { ENUM_INFO_ALERT_VARIANT } from "@components/ui/infoAlert/types"
import Link from "@components/ui/link"
import { DrawerWithdrawContext } from "@screens/dashboard/transfer/drawerWithdraw/DrawerWithdrawContext"
import { useTranslation } from "react-i18next"
import { MdOutlineNotifications } from "react-icons/md"

export const WithdrawWireCADisclaimers: React.FC = () => {
    const { t, i18n } = useTranslation(["transfer"])

    const { caWireDisclaimers, setCAWireDisclaimers, resetErrors } = DrawerWithdrawContext.useContainer()

    const handleCheckboxChange = (name: keyof typeof caWireDisclaimers, isChecked: boolean) => {
        resetErrors()
        setCAWireDisclaimers((prev) => ({ ...prev, [name]: isChecked }))
    }
    return (
        <Box mt={6}>
            <VStack spacing={6} alignContent={"flex-start"}>
                {Object.keys(caWireDisclaimers).map((key) => {
                    const typedKey = key as keyof typeof caWireDisclaimers
                    return (
                        <Checkbox
                            key={key}
                            text={t(`withdraw.wire.disclaimers.ca.${typedKey}.title`)}
                            name={key}
                            isChecked={!!caWireDisclaimers[typedKey]}
                            setValue={(isChecked) => handleCheckboxChange(typedKey, isChecked)}
                            link={
                                i18n.exists(`withdraw.wire.disclaimers.ca.${typedKey}.link`, { ns: "transfer" })
                                    ? {
                                          label: t(`withdraw.wire.disclaimers.ca.${typedKey}.link.label`),
                                          href: t(`withdraw.wire.disclaimers.ca.${typedKey}.link.url`),
                                          hasIcon: true,
                                      }
                                    : undefined
                            }
                        />
                    )
                })}
            </VStack>
            <Box p={4} borderWidth={1} borderRadius={"md"} borderColor={"grey.light.10"} mt={6}>
                <InfoAlert icon={{ icon: MdOutlineNotifications }} variant={ENUM_INFO_ALERT_VARIANT.ORANGE}>
                    {t("withdraw.wire.disclaimers.ca.info.title")}
                </InfoAlert>
                <Box mt="0.75rem" ml="54px">
                    <Link
                        text={t("withdraw.wire.disclaimers.ca.info.link.label")}
                        href={t("withdraw.wire.disclaimers.ca.info.link.url")}
                        hasIcon
                    />
                </Box>
            </Box>
        </Box>
    )
}
