import { useRef } from "react"
import { EvaluationStateModal } from "../evaluationState"
import { useUniversalModal } from "../modalContext"
import { ENUM_UNIVERSAL_MODAL_TYPES } from "../types"
import { UnderReviewModal } from "../underReview"
import { ViewOnlyModal } from "../viewOnly"

export const ModalComponents: React.FC = () => {
    const { closeModal, modalState } = useUniversalModal()
    const cancelRef = useRef<HTMLButtonElement>(null)

    return (
        <>
            {modalState === ENUM_UNIVERSAL_MODAL_TYPES.EVALUATION_STATE && (
                <EvaluationStateModal
                    isOpen={modalState === ENUM_UNIVERSAL_MODAL_TYPES.EVALUATION_STATE}
                    onClose={closeModal}
                    cancelRef={cancelRef}
                />
            )}
            {modalState === ENUM_UNIVERSAL_MODAL_TYPES.UNDER_REVIEW && (
                <UnderReviewModal
                    isOpen={modalState === ENUM_UNIVERSAL_MODAL_TYPES.UNDER_REVIEW}
                    onClose={closeModal}
                    cancelRef={cancelRef}
                />
            )}
            {modalState === ENUM_UNIVERSAL_MODAL_TYPES.VIEW_ONLY && (
                <ViewOnlyModal
                    isOpen={modalState === ENUM_UNIVERSAL_MODAL_TYPES.VIEW_ONLY}
                    onClose={closeModal}
                    cancelRef={cancelRef}
                />
            )}
        </>
    )
}
