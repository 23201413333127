import { API_PATH_FAVOURITES, ASSET_TAG_TYPES } from "./const"
import { AssetFavourite } from "./types"
import { splitApi } from "@redux/splitApi"

export const assetApi = splitApi.injectEndpoints({
    endpoints: (builder) => ({
        getAssetFavourites: builder.query<{ data: AssetFavourite[] }, undefined>({
            query: () => API_PATH_FAVOURITES.ASSETS_FAVOURITES,
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.map(({ asset }) => ({
                              type: ASSET_TAG_TYPES.ASSET_FAVOURITES,
                              id: asset.symbol,
                          })),
                          { type: ASSET_TAG_TYPES.ASSET_FAVOURITES, id: "LIST" },
                      ]
                    : [{ type: ASSET_TAG_TYPES.ASSET_FAVOURITES, id: "LIST" }],
        }),
        addAssetFavourite: builder.mutation<{ data: AssetFavourite }, { asset_symbol: string }>({
            query: (body) => ({
                url: API_PATH_FAVOURITES.ASSETS_FAVOURITES,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: ASSET_TAG_TYPES.ASSET_FAVOURITES, id: "LIST" }],
        }),
        deleteAssetFavourite: builder.mutation<{ data: AssetFavourite }, string>({
            query: (symbol) => ({
                url: API_PATH_FAVOURITES.ASSETS_FAVOURITES + `/${symbol}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: ASSET_TAG_TYPES.ASSET_FAVOURITES, id: "LIST" }],
        }),
    }),
})

export const {
    useGetAssetFavouritesQuery,
    useLazyGetAssetFavouritesQuery,
    useAddAssetFavouriteMutation,
    useDeleteAssetFavouriteMutation,
} = assetApi
