import { Dispatch, SetStateAction, memo, useMemo } from "react"
import { Box, Td, Text, Tr, Image, useBreakpointValue, Icon } from "@chakra-ui/react"
import useImage from "hooks/useImage"
import { AssetDetails } from "@redux/assetsDetails/types"
import { addCommasToNumber, removeTrailingZeros, shortenLargeNumber } from "@util/stringFormatting"
import { useTranslation } from "react-i18next"
import { MdChevronRight } from "react-icons/md"
import useColorFormatConverter from "@theme/useColorFormatConverter"
import { AiFillStar, AiOutlineStar } from "react-icons/ai"
import { useAddAssetFavouriteMutation, useDeleteAssetFavouriteMutation } from "@redux/asset/apiSlice"
import { COIN_SORT } from "@screens/dashboard/trade/types"
import useAjaxQueue from "@hooks/useAjaxQueue"
import { imageMap } from "@assets/svgs/coins"

type Props = {
    coin: AssetDetails
    handleViewMore: (_: string) => void
    favouritesDataRef: React.MutableRefObject<Set<string>>
    setFavouritesData: Dispatch<SetStateAction<Set<string>>>
    isFavourite: boolean
    isLast: boolean
    coinSort: COIN_SORT
    sortData: (_: COIN_SORT, __: Set<string>) => Set<string>
}

const CoinTradingRow = ({
    coin: { name, symbol, statistics, price, "24h_percent": hr24Percent },
    setFavouritesData,
    favouritesDataRef,
    handleViewMore,
    isFavourite,
    isLast,
    coinSort,
    sortData,
}: Props) => {
    const colorConverter = useColorFormatConverter()
    const { t } = useTranslation("common")
    const { addToQueue } = useAjaxQueue()
    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const image = imageMap[symbol.toLowerCase() as keyof typeof imageMap]
    const [addAssetFavourite] = useAddAssetFavouriteMutation()
    const [deleteAssetFavourite] = useDeleteAssetFavouriteMutation()

    const change24Color = useMemo(() => {
        return hr24Percent > 0 ? "green.light.90" : hr24Percent < 0 ? "red.100" : "grey.dark.70"
    }, [hr24Percent])

    const borderBottom = useMemo(() => {
        return isLast ? "none !important" : "1px solid #E5E5EA"
    }, [isLast])

    const handleFavouritePress = () => {
        if (isFavourite) {
            favouritesDataRef.current.delete(`${name}::${symbol}`)
            setFavouritesData((prev) => {
                const next = new Set(prev)
                next.delete(`${name}::${symbol}`)
                return next
            })
            addToQueue(`delete`, () => {
                return deleteAssetFavourite(symbol).unwrap()
            })
        } else {
            favouritesDataRef.current = sortData(coinSort, favouritesDataRef.current.add(`${name}::${symbol}`))
            setFavouritesData((prev) => {
                const newSet = new Set([...prev, `${name}::${symbol}`])
                const sortedData = sortData(coinSort, newSet)
                return sortedData
            })
            addToQueue(`add`, () => {
                return addAssetFavourite({ asset_symbol: symbol }).unwrap()
            })
        }
    }

    return (
        <Tr
            w="full"
            py="0.5rem"
            cursor="pointer"
            textStyle="ManropeSemiboldBodySmall"
            _hover={{
                bg: "grey.dark.10",
            }}
        >
            <Td borderBottom={borderBottom} p={{ base: "0.5rem", sm: "1rem" }}>
                <Box display="flex" alignItems="center">
                    <Box
                        onClick={handleFavouritePress}
                        display="inline-block"
                        mr={{ base: "0.25rem", lg: "0.75rem" }}
                        verticalAlign={"middle"}
                    >
                        <Box role="group">
                            {isFavourite ? (
                                <Icon as={AiFillStar} fill="yellow.dark.100" h={"20px"} w={"20px"} />
                            ) : (
                                <Icon as={AiOutlineStar} color="grey.light.40" h={"20px"} w={"20px"} />
                            )}
                        </Box>
                        <Box
                            _groupHover={{
                                display: "inline-block",
                            }}
                            display="none"
                        >
                            {isFavourite ? (
                                <Icon as={AiOutlineStar} color="yellow.dark.100" h={"20px"} w={"20px"} />
                            ) : (
                                <Icon as={AiFillStar} color="yellow.dark.100" h={"20px"} w={"20px"} />
                            )}
                        </Box>
                    </Box>
                    <Box
                        onClick={() => handleViewMore(symbol)}
                        display="inline-block"
                        mr="0.75rem"
                        verticalAlign={"middle"}
                    >
                        {image && (
                            <Box
                                height={{ base: "28px", lg: "42px" }}
                                borderRadius={{ base: "14px", lg: "21px" }}
                                width={{ base: "28px", lg: "42px" }}
                                overflow="hidden"
                            >
                                <Image src={image} alt={"coin" + symbol.toLowerCase()} h="full" w="full" />
                            </Box>
                        )}
                    </Box>

                    <Box onClick={() => handleViewMore(symbol)} flex="1" minWidth="0">
                        {isDesktop ? (
                            <>
                                <Text display="block" width="100%" whiteSpace="normal" wordBreak="normal">
                                    {name}
                                </Text>
                                <Text display="block" textStyle="ManropeMediumXSmall" color="grey.light.50">
                                    {symbol}
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text display="block" width="100%" whiteSpace="normal" wordBreak="normal">
                                    {name}
                                </Text>
                                <Text display="block" textStyle="ManropeMediumXSmall" color="grey.light.50">
                                    {symbol}
                                </Text>
                            </>
                        )}
                    </Box>
                </Box>
            </Td>
            <Td onClick={() => handleViewMore(symbol)} borderBottom={borderBottom}>
                ${addCommasToNumber(removeTrailingZeros(price))}
            </Td>
            <Td
                onClick={() => handleViewMore(symbol)}
                borderBottom={borderBottom}
                display={{ base: "none", smmd: "table-cell" }}
                color={change24Color}
            >
                {hr24Percent > 0 ? "+" : ""}
                {hr24Percent}%
            </Td>
            <Td
                onClick={() => handleViewMore(symbol)}
                borderBottom={borderBottom}
                display={{ base: "none", md: "table-cell" }}
            >
                ${shortenLargeNumber(statistics.market_cap_cad)}
            </Td>
            <Td
                borderBottom={borderBottom}
                onClick={() => handleViewMore(symbol)}
                color="blue.100"
                display={{ base: "none", md: "table-cell" }}
                textAlign={"right"}
            >
                <Text
                    whiteSpace="pre-wrap"
                    display="inline-block"
                    verticalAlign={"middle"}
                    mr="0.5rem"
                    color={"currentcolor"}
                >
                    {t("viewMore")}
                </Text>
                <Box display="inline-block" verticalAlign={"middle"} alignItems="center">
                    <MdChevronRight size={18} color={colorConverter("blue.100")} />
                </Box>
            </Td>
        </Tr>
    )
}

export default memo(CoinTradingRow, (prev, next) => {
    return prev.coin === next.coin && prev.isFavourite === next.isFavourite && prev.isLast === next.isLast
})
